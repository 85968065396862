import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    fontSize: 8,
    padding: 5,
  },
  titlePageContainer: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    gap: 3,
    top: "40%",
  },
  titlePageHeading: {
    fontSize: 25,
  },
  titlePageSubHeading: {
    fontSize: 18,
    color: "#666666",
    fontWeight: "thin",
  },
  row: {
    flexDirection: "row",
    border: "1px solid #ddd",
    display: "flex",
    margin: 0,
    padding: 0,
  },
  column: {
    width: "13.5%",
    flexDirection: "column",
    borderLeft: "1px solid #ddd",
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    fontSize: 10,
    // padding: 5
  },
  header: {
    textAlign: "center",
    padding: 0,
    margin: 0,
    borderRight: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
  },
  rowChild: {
    borderBottom: "1px solid #ddd",
    display: "block" as any,
    padding: "3px 1px",
  },
  event: {
    //   cursor: 'pointer',
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#c9ebff",
    boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.25)",
    marginBottom: 30,
    padding: "1px 1px",
    overflow: "hidden",
    height: 50,
    maxWidth: "100%",
    margin: "5px 4px",
  },
  eventIcon: {
    border: "1px solid #d7d7d7",
    marginRight: 1,
    backgroundColor: "#fff",
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    position: "relative",
    flexShrink: 0,
  },
  eventIconSvg: {
    width: 20,
    height: "auto",
    verticalAlign: "super",
  },
  eventStatusIcon: {
    position: "absolute",
    bottom: -6,
    right: -3,
  },
  eventContent: {
    textAlign: "left",
    marginLeft: 2,
  },
  eventTitle: {
    color: "#000",
    fontSize: 9,
    fontWeight: 900,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
  eventDescription: {
    color: "#000",
    fontSize: 7,
    fontWeight: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  eventInfo: {
    color: "#4f4d4d",
  },
  completed: {
    borderColor: "#43bd18",
  },
  delayed: {
    borderColor: "#d92323",
  },
  pending: {
    borderColor: "#3e74dd",
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 15,
  },
  legengContainer: {
    marginBottom: 3,
    marginLeft: 80,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    fontSize: 10,
  },
  legendDateHeading: {
    textAlign: "left",
    fontSize: 12,
    marginBottom: 2,
    fontWeight: 600,
  },
  dayCell: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 50,
    borderWidth: 1,
    borderColor: "#ccc",
  },
  dayText: {
    marginRight: 5,
  },
});
