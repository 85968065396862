import { memo } from "react";
import { Col, Row, Space, Tooltip } from "antd";
import TextToLink from "../text-to-link";
import ListGroupIcon from "components/icons/ListGroupIcon";
import InternalUserIcon from "components/icons/InternalUserIcon";
import {
  displayDateTime,
  getFullUserName,
  isExternalUser,
  truncateText,
} from "utils/helpers";
import { INotificationsDropDownCardProps } from "./Notification.interface";
import { NotificationTypesEnum } from "services/notifications/notifications.interface";
import "./Notification.scss";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
import AlectifyButton from "../button";
import { IMasterProject } from "redux/components/master-project";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import {
  setActiveMasterProject,
  setActiveSubProject,
} from "redux/components/common/sources";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { ISubProject } from "redux/components/sub-project";
import { isEmpty, truncate } from "lodash";
import AlectifyText from "static/texts.json";
import AssetDetail from "../asset-detail";
import PackageDetailAssets from "../package-detail-assets";
import PmDetail from "../pm-detail";
import { PM_TYPES } from "redux/components/pm-external";
import { confirmNotificationsRead } from "redux/components/notifications/sources";
import { actions } from "redux/components/notifications";
import { PmExternalCircleIcon } from "components/icons";

const NotificationsDropDownCard: React.FC<INotificationsDropDownCardProps> = (
  props: INotificationsDropDownCardProps,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: IRootState) => state.auth);
  const pm = props?.notification?.preventiveMaintenance;
  const subProject = pm?.subProject;
  const masterProject = pm?.project;

  const onMasterProjectClick = (masterProject: IMasterProject) => {
    if (!isExternalUser(user)) {
      dispatch(actions.toggleNotificationDropdown(false));
      dispatch(setActiveMasterProject(masterProject));
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
    }
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
    });
  };

  const onSubProjectClick = (
    masterProject: IMasterProject,
    subProject: ISubProject,
  ) => {
    if (!isExternalUser(user)) {
      dispatch(actions.toggleNotificationDropdown(false));
      dispatch(setActiveSubProject(subProject));
      dispatch(setActiveMasterProject(masterProject));
      navigate(
        `${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}${ROUTES.SUB_PROJECT}/${subProject.id}`,
      );
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
      });
    }
  };

  const openProjectDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 420,
      closeIcon: true,
      title: masterProject?.name,
      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
        }),
      children: <SwitchSubProjectDrawer masterProjectId={masterProject.id} />,
      customFooter: (
        <Space>
          <AlectifyButton
            text={AlectifyText.VISIT_ASSET_CATEGORY}
            type="default"
            className="light-blue-button"
            onClick={(e) => {
              e.stopPropagation();
              onSubProjectClick(masterProject, subProject);
            }}
          />

          <AlectifyButton
            text={AlectifyText.VISIT_SITE}
            className="ant-btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              onMasterProjectClick(masterProject);
            }}
          />
        </Space>
      ),
    });
  };

  const goToPmDetail = () => {
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProject.id}/${subProject.id}/${pm.id}?pmType=${pm.pmType}`;
    dispatch(setActiveSubProject(subProject));
    dispatch(setActiveMasterProject(masterProject));
    dispatch(actions.toggleNotificationDropdown(false));
    navigate(url);
  };

  return (
    <>
      <Row
        className="notifications-container"
        onClick={(ev) => {
          ev.stopPropagation();
          if (!props.isRead) {
            dispatch(confirmNotificationsRead([props.id]));
          }
        }}
      >
        <Col flex="none" className="notifications-user-logo">
          {!props?.isRead ? (
            <div className="notifications-unread-dot notifications-unread-dot" />
          ) : null}
          {props?.notification?.notificationType ===
          NotificationTypesEnum.PM_EXTERNAL ? (
            <PmExternalCircleIcon fill="#FF5656" />
          ) : props?.notification?.notificationType ===
            NotificationTypesEnum.PM_INTERNAL ? (
            <InternalUserIcon className="notification-icons-dropdown" />
          ) : (
            <ListGroupIcon className="notification-icons-dropdown" />
          )}
        </Col>
        <Col flex="auto" className="notification-detail-container">
          <div className="notification-user-detail">
            {props?.user ? (
              <Tooltip
                title={
                  `${
                    (props?.notification.createdBy &&
                      getFullUserName(props?.notification.createdBy)) ||
                    "-"
                  } ${props.notification.message}` || "-"
                }
              >
                <p>
                  {
                    <span>
                      {(props?.notification.createdBy &&
                        getFullUserName(props?.notification.createdBy)) ||
                        "-"}
                    </span>
                  }{" "}
                  {truncate(props.notification.message, {
                    length: 40,
                    omission: "...",
                  })}
                </p>
              </Tooltip>
            ) : null}
            <span className="notification-date-time-text">
              {displayDateTime(props?.createdAt, true)}
            </span>
          </div>
          <div className="notification-title-container">
            <span>
              <div className="notifications-sub-detail">
                <Tooltip title={pm?.workTitle || "-"}>
                  {`Work Title:`}
                  <TextToLink
                    text={pm?.workTitle || "-"}
                    className="notification-text-color notification-text-color-page text-to-link-options"
                    onClick={(e) => {
                      e.stopPropagation();
                      props?.onDrawerOpen && props.onDrawerOpen();
                      DrawerServiceInstance.open(AlectifyDrawer, {
                        width: 480,
                        title: truncateText(pm?.workTitle, 64),
                        name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                        closeIcon: true,
                        onClose: (e: any) => {
                          e.stopPropagation();
                          props.onDrawerClose && props.onDrawerClose();
                          DrawerServiceInstance.close(AlectifyDrawer, {
                            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                          });
                        },
                        children: (
                          <PmDetail
                            pmId={pm.id}
                            pmExternalRecord={
                              pm.pmType !== PM_TYPES.PM_INTERNAL
                                ? (pm as any)
                                : undefined
                            }
                            pmInternalRecord={
                              pm.pmType === PM_TYPES.PM_INTERNAL
                                ? pm
                                : undefined
                            }
                            pmType={pm.pmType as any}
                          />
                        ),
                      });
                    }}
                  />
                </Tooltip>
              </div>
              {props.isPageNotification && (
                <div className="notifications-sub-detail">
                  {`Work Id:`}
                  <span className="notification-page-workId-text">
                    {pm?.workId || "-"}
                  </span>
                </div>
              )}
              {!pm.isGeneric ? (
                <div className="notifications-sub-detail">
                  <Tooltip title={pm?.asset?.name || pm?.area?.name || "-"}>
                    {pm?.asset?.name ? "Asset: " : "Asset Package: "}
                    <TextToLink
                      text={truncate(pm?.asset?.name || pm?.area?.name || "-", {
                        length: 12,
                        omission: "...",
                      })}
                      className="notification-text-color notification-text-color-page text-to-link-options"
                      onClick={(e) => {
                        e.stopPropagation();
                        props?.onDrawerOpen && props.onDrawerOpen();
                        DrawerServiceInstance.open(AlectifyDrawer, {
                          width: pm?.asset?.name ? 420 : 900,
                          title: truncateText(
                            pm?.asset?.name || pm?.area?.name || "-",
                            64,
                          ),
                          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                          closeIcon: true,
                          onClose: (e: any) => {
                            e.stopPropagation();
                            props.onDrawerClose && props.onDrawerClose();
                            DrawerServiceInstance.close(AlectifyDrawer, {
                              name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                            });
                          },
                          children: !isEmpty(pm.asset) ? (
                            <AssetDetail
                              navigate={navigate}
                              subProjectId={subProject.id}
                              assetId={pm.asset?.id}
                              isDetailButton
                            />
                          ) : (
                            <PackageDetailAssets
                              navigate={navigate}
                              subProjectId={subProject?.id || ""}
                              assetPackageId={pm?.area?.id || ""}
                              isDetailsbutton
                            />
                          ),
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div className="notifications-sub-detail">{`Generic`}</div>
              )}
              {props.isPageNotification && (
                <>
                  <div className="notifications-sub-detail">
                    <div className="notification-pink-dot" />
                    <TextToLink
                      text={pm?.project?.name || "-"}
                      onClick={openProjectDrawer}
                      className="notification-text-color notification-text-color-page"
                    />
                  </div>
                  <div className="notifications-sub-detail">
                    {`Sub Project:`}
                    <TextToLink
                      text={subProject?.name || "-"}
                      onClick={openProjectDrawer}
                      className="notification-text-color notification-text-color-page"
                    />
                  </div>
                </>
              )}
              <div className="notification-detail-link">
                <Tooltip title="Go to detail">
                  <AlectifyButton
                    className="goto-btn"
                    text={AlectifyText.GO_TO_DETAIL}
                    type="primary"
                    onClick={goToPmDetail}
                  />
                </Tooltip>
              </div>
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default memo(NotificationsDropDownCard);
