import { Col, Row, Spin, Typography } from "antd";
import { IDataRoundProjectListing } from "./DataRoundProject.interface";
import { useEffect, useState } from "react";
import { IDataRoundProject } from "services/data-round/data-round.interface";
import { fetchDataRoundProjects } from "services/data-round/data-round.service";
import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";
import DataRoundProjectCard from "./DataRoundProjectCard";
import AlectifyButton from "components/shared/button";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import AlectifyText from "static/texts.json";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import DrProjectCreation from "../create-edit/DrProjectCreation";
import { useForm } from "antd/lib/form/Form";
import "./DataRoundListing.scss";

const DataRoundProjectListing: React.FC<IDataRoundProjectListing> = (
  props: IDataRoundProjectListing,
) => {
  const [projects, setProjects] = useState<IDataRoundProject[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [FormInstance] = useForm();

  const getDataRoundProject = async () => {
    setLoader(true);
    try {
      const response = await fetchDataRoundProjects(props.masterProjectId);
      if (response.success) {
        setProjects(response.data);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const openCreationDrawer = (
    projectId: string,
    title: string,
    isEdit: boolean = false,
  ) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1200,
      title: title,
      name: DRAWER_CONSTANTS.CREATE_NEW_DR_PROJECT,
      footer: null,
      showFooter: false,
      onClose: () => {
        FormInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.CREATE_NEW_DR_PROJECT,
        });
        getDataRoundProject();
      },
      closeIcon: <CloseOutlined />,
      closable: true,
      destroyOnClose: true,
      children: (
        <DrProjectCreation
          FormInstance={FormInstance}
          masterProjectId={props.masterProjectId}
          projectId={projectId}
          isEdit={isEdit}
          getDataRoundProject={getDataRoundProject}
        />
      ),
    });
  };
  useEffect(() => {
    if (props.masterProjectId) {
      getDataRoundProject();
    }
  }, [props.masterProjectId]);

  return (
    <Spin spinning={loader}>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Typography.Title level={4} className="m-0">
            Rounds {`(${projects?.length || 0})`}
          </Typography.Title>
        </Col>
        <Col span={12} className="text-align-right">
          <AlectifyButton
            text={AlectifyText.CREATE_NEW_ROUND}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 1200,
                title: AlectifyText.CREATE_NEW_ROUND,
                name: DRAWER_CONSTANTS.CREATE_NEW_DR_PROJECT,
                footer: null,
                showFooter: false,
                onClose: () => {
                  FormInstance.resetFields();
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.CREATE_NEW_DR_PROJECT,
                  });
                  getDataRoundProject();
                },
                closeIcon: <CloseOutlined />,
                closable: true,
                destroyOnClose: true,
                children: (
                  <DrProjectCreation
                    FormInstance={FormInstance}
                    masterProjectId={props.masterProjectId}
                    getDataRoundProject={getDataRoundProject}
                  />
                ),
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={20} className="data-round-listing mt-10">
        {(projects &&
          projects.map((project: IDataRoundProject) => (
            <Col span={6}>
              <DataRoundProjectCard
                project={project}
                showEditButton
                openCreationDrawer={openCreationDrawer}
              />
            </Col>
          ))) || <AlectifyEmptyState />}
      </Row>
    </Spin>
  );
};

export default DataRoundProjectListing;
