import { get } from "lodash";
import { memo, useState } from "react";
import { Col, Popover, Row, Space, Typography } from "antd";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import { CalendarEventStatusEnum, CalendarEventTypesEnum } from "enums";
import {
  CheckMarkSingleIcon,
  CrossRedIcon,
  EventCompletedIcon,
  EventCompletedPastDueDateIcon,
  EventDelayedIcon,
  EventPendingIcon,
} from "components/icons";
import TasksIcon from "components/icons/TasksIcon";
import PmExternalIcon from "components/icons/PmExternalIcon";
import PmInternalIcon from "components/icons/PmInternalIcon";
import EventSkippedIcon from "components/icons/EventSkippedIcon";
import AlectifyText from "static/texts.json";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import { AssetPackageTag, AssetTag, GenericTag } from "components/shared/tags";
import "./Calendar.scss";
import DelayedTag from "components/shared/tags/DelayedTag";

export const EVENT_ICONS = {
  [CalendarEventTypesEnum.TASKS]: <TasksIcon />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIcon />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIcon />,
};

export const EVENT_STATUS_ICONS = {
  [CalendarEventStatusEnum.DELAYED]: <EventDelayedIcon />,
  [CalendarEventStatusEnum.PENDING]: <EventPendingIcon />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <EventCompletedIcon />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <EventCompletedPastDueDateIcon />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <EventSkippedIcon />,
};

export const EVENT_CLASSES: any = {
  [CalendarEventStatusEnum.DELAYED]: "delayed",
  [CalendarEventStatusEnum.PENDING]: "pending",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "completed",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "completed",
  [CalendarEventStatusEnum.SKIPPED]: "skipped",
};

export const EVENT_Text: any = {
  [CalendarEventStatusEnum.DELAYED]: "Over Due",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "Completed(Due Date Passed)",
};

const CalendarEvent: React.FC<ICalendarEvent> = (props: ICalendarEvent) => {
  const masterProject = get(props, "master_project[0]");
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const goToPmDetailPage = async (props: any) => {
    const masterProjectId = get(props, "master_project[0].id");
    const subProjectId = get(props, "sub_project[0].id");
    const pmId = props.id;
    const pmType = props.type;

    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${pmType}`;
    navigate(url);
  };
  const popoverContent = (
    <Row
      className={`popover-container-calendar ${
        props.type === "PM_EXTERNAL" || props.type === "PM_INTERNAL"
          ? "pop-over-content-pm"
          : "pop-over-content-task"
      }`}
    >
      <div className="close-icon-popover" onClick={() => setOpenPopover(false)}>
        <CrossRedIcon fill={"black"} />
      </div>
      <Col span={8} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="heading">{AlectifyText.SITE}:</span>
        </Typography.Text>
      </Col>

      <Col span={16} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="value">{masterProject?.name || "-"}</span>
        </Typography.Text>
      </Col>

      <Col span={8} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="heading">{AlectifyText.WORKID}:</span>
        </Typography.Text>
      </Col>

      <Col span={16} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="value">{props.work_id || "-"}</span>
        </Typography.Text>
      </Col>

      <Col span={8} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="heading">{AlectifyText.WORK_TITLE}: </span>
        </Typography.Text>
      </Col>

      <Col span={16} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="value">{props.title || "-"}</span>
        </Typography.Text>
      </Col>
      <Col span={8} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="heading">{AlectifyText.TYPE}: </span>
        </Typography.Text>
      </Col>

      <Col span={16} className="popover-col">
        <Space>
          <span className="calendar-type-chips">
            {props.type === "PM_EXTERNAL" || props.type === "PM_INTERNAL"
              ? "Preventive Maintenance"
              : "Task"}
          </span>

          {props.asset[0]?.name ? (
            <AssetTag />
          ) : props.asset_package[0]?.name ? (
            <AssetPackageTag />
          ) : (
            <GenericTag />
          )}
        </Space>
      </Col>
      {props.asset_package[0]?.name || props.asset[0]?.name ? (
        <>
          <Col span={8} className="popover-col">
            <Typography.Text className="calender-card-tooltip-font-color">
              <span className="heading">
                {get(props, "asset[0].name")
                  ? `${AlectifyText.ASSET_NAME}:`
                  : `${AlectifyText.PARENT_ASSET_NAME}:`}
              </span>
            </Typography.Text>
          </Col>
          <Col span={16} className="popover-col">
            <Typography.Text className="calender-card-tooltip-font-color">
              <span className="value">
                {get(props, "asset[0].name")
                  ? get(props, "asset[0].name") || "-"
                  : get(props, "asset_package[0].name") || "-"}
              </span>
            </Typography.Text>
          </Col>
        </>
      ) : (
        <></>
      )}

      <Col span={8} className="popover-col">
        <Typography.Text className="calender-card-tooltip-font-color">
          <span className="heading">{AlectifyText.STATUS}: </span>
        </Typography.Text>
      </Col>

      <Col span={16} className="popover-col">
        <Space>
          {PM_STATUS_COMPONENTS[
            props.status as keyof typeof PM_STATUS_COMPONENTS
          ] || "-"}
          {props.event_status === CalendarEventStatusEnum.DELAYED ||
          props.event_status === CalendarEventStatusEnum.DELAYED_COMPLETED ? (
            <DelayedTag />
          ) : (
            <></>
          )}
        </Space>
      </Col>
    </Row>
  );

  return (
    <div className="event-container">
      <Popover
        content={popoverContent}
        open={openPopover}
        onOpenChange={setOpenPopover}
        trigger="hover"
      >
        <div
          className={`alectify-calendar-event ${
            EVENT_CLASSES[props.event_status as CalendarEventStatusEnum]
          }`}
          style={{ backgroundColor: masterProject?.color || "transparent" }}
          onClick={() => {
            goToPmDetailPage(props);
          }}
        >
          <div
            className={`event-content ${
              props?.event_status === CalendarEventStatusEnum.DELAYED ||
              props?.event_status ===
                CalendarEventStatusEnum.DELAYED_COMPLETED ||
              props?.event_status === CalendarEventStatusEnum.SKIPPED
                ? "event-left-red-borders"
                : "event-left-normal-borders"
            }`}
          >
            {props?.event_status ===
              CalendarEventStatusEnum.DELAYED_COMPLETED ||
            props?.event_status ===
              CalendarEventStatusEnum.ON_TIME_COMPLETED ? (
              <div className="calendar-check-mark">
                <CheckMarkSingleIcon />
              </div>
            ) : (
              <></>
            )}
            <div className="event-title">
              <span>{props?.title || "NA"}</span>
            </div>

            <div className="event-name text-ellipsis">
              <span>
                {get(props, "asset[0].name") ||
                  get(props, "asset_package[0].name") ||
                  "-"}
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default memo(CalendarEvent);
