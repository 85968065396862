import React, { memo } from "react";
import { Tooltip } from "antd";
import TextToLink from "components/shared/text-to-link";
import PreviewPdf from "components/shared/preview-pdf";
import ViewImage from "components/shared/view-image/ViewImage";
import { getExtension, imageExtensions } from "utils/helpers";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyText from "static/texts.json";
import { PreviewFileProps } from "./PreviewFile.interface";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import AlectifyVideoPlayer from "../video-player";
import {
  downloadSingleFile,
  downloadSingleFileFromURL,
} from "services/documents/documents.service";

const PreviewFile: React.FC<PreviewFileProps> = ({
  fileName,
  fileUrl,
  isActivevalue,
  isSigned,
}) => {
  const fileType = getExtension(fileName);
  const filePath = fileUrl;
  const isActive = isActivevalue;

  function isVideoFileType(type: string): boolean {
    const mediaExtensions = [
      "mp4",
      "webm",
      "ogg",
      "avi",
      "mov",
      "flv",
      "wmv",
      "mkv",
      "m4v",
      "3gp",
      "mp3",
      "wav",
      "aac",
      "flac",
      "ogg",
    ];
    return mediaExtensions.includes(type);
  }

  const downloadSignedFile = async () => {
    try {
      await downloadSingleFileFromURL(filePath);
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadUnSignedFile = async () => {
    await downloadSingleFile(filePath, fileName);
  };

  const downloadFilefromUrl = () => {
    if (isSigned) {
      downloadSignedFile();
    } else {
      downloadUnSignedFile();
    }
  };

  const openModal = (modalName: string, title: string, content: any) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: modalName,
      title,
      children: content,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, { name: modalName });
      },
      footer: null,
      width: modalName === MODAL_NAMES.PREVIEW_PDF_MODAL ? 860 : undefined,
      style:
        modalName === MODAL_NAMES.PREVIEW_PDF_MODAL ? { top: 20 } : undefined,
      className:
        modalName === MODAL_NAMES.PREVIEW_PDF_MODAL
          ? "alectify-pdf-viewer-container"
          : undefined,
    });
  };

  if (!isActive) {
    return <>{fileName}</>;
  }

  const renderContent = () => {
    if (isVideoFileType(fileType || "")) {
      return (
        <Tooltip placement="topLeft" title={fileName}>
          <TextToLink
            className="text-to-link-options"
            text={fileName}
            onClick={() =>
              openModal(
                MODAL_NAMES.PREVIEW_PDF_MODAL,
                `${fileName} - Preview`,
                <AlectifyVideoPlayer
                  filePath={filePath}
                  fileName={fileName}
                  isSigned={isSigned ? true : false}
                />,
              )
            }
            underline
          />
        </Tooltip>
      );
    }
    if (fileType === "pdf") {
      return (
        <Tooltip placement="topLeft" title={fileName}>
          <TextToLink
            className="text-to-link-options"
            text={fileName}
            onClick={() =>
              openModal(
                MODAL_NAMES.PREVIEW_PDF_MODAL,
                `${fileName} - Preview`,
                <PreviewPdf documentUrl={filePath} />,
              )
            }
            underline
          />
        </Tooltip>
      );
    }

    if (imageExtensions.includes(fileType || "")) {
      return (
        <Tooltip placement="topLeft" title={fileName}>
          <TextToLink
            className="text-to-link-options"
            text={fileName}
            onClick={() =>
              openModal(
                MODAL_NAMES.VIEW_IMAGE_MODAL,
                AlectifyText.VIEW_IMAGE,
                <ViewImage
                  imageUrl={filePath}
                  isUnSigned={isSigned ? false : true}
                />,
              )
            }
            underline
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip placement="topLeft" title={fileName}>
        <TextToLink
          className="text-to-link-options"
          text={fileName}
          onClick={downloadFilefromUrl}
          underline
        />
      </Tooltip>
    );
  };

  return renderContent();
};

export default memo(PreviewFile);
