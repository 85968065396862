import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IAlectifyEditorProps } from "./AlectifyEditor.interface";
import "./AlectifyEditor.scss";

const AlectifyEditor: React.FC<IAlectifyEditorProps> = (
  props: IAlectifyEditorProps,
) => {
  const [editorData, setEditorData] = useState<string>(
    props.defaultValue || "",
  );

  useEffect(() => {
    if (props.defaultValue) {
      setEditorData(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          props.onDataChange(data);
          setEditorData(data);
        }}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            // "link",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "alignment:left",
            "alignment:center",
            "alignment:right",
            "alignment:justify",
            "|",
            "blockQuote",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
          alignment: {
            options: ["left", "center", "right", "justify"],
          },
          removePlugins: ["MediaEmbed", "link"],
        }}
      />
    </div>
  );
};

export default AlectifyEditor;
