import { AxiosResponse } from "axios";
import { MULTIPART_HEADERS, pmHttp } from "utils/client";
import { IParams } from "redux/interfaces";
import {
  GET_CLOSED_WORK_ORDERS,
  GET_CLOSED_WORK_ORDERS_BY_ASSIGNEES,
  GET_PM_BY_DUE_DATE,
  GET_PM_COUNTS,
  GET_PM_DOCUMENTS,
  GET_PM_INTERNAL_URL,
  GET_PREVENTIVE_MAINTENANCE_DETAIL,
  GET_WORK_ORDER_SUMMARY,
  UPLOAD_PM_DOCUMENTS,
} from "./pm-internal.endpoints";
import {
  IPmInternalByDueDateResponse,
  IPmInternalDetailResponse,
  IPmInternalResponse,
} from "redux/components/pm-internal/pm-internal.interface";
// import { pmAttachmentBuildExpressToNormalResponse } from "./pm-internal.normalizer";
import {
  IClosedWorkOrdersByAssigneesResponse,
  IClosedWorkOrdersResponse,
  IPmAttachmentsExpressResponse,
  IPmAttachmentsPostResponse,
  IPmCountsResponse,
  IWorkOrderSummaryResponse,
} from "./pm-internal.interface";

/**
 * Fetches PM Internal for a given master project, sub project ID, pmtype.
 *
 * @param masterProjectId - The ID of the master project to fetch pm internal
 * @param subProjectId - The ID of the sub project to fetch pm internal for.
 * @param pmType - The PM type.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project pm internal data.
 * @throws If an error occurs during the request.
 */
export const fetchPmInternalPms = async (
  masterProjectId: string,
  subProjectId: string,
  pmType: string,
  params?: IParams, // Make params optional
): Promise<IPmInternalResponse> => {
  try {
    const url = GET_PM_INTERNAL_URL.replace(
      "{masterProjectId}",
      masterProjectId,
    )
      .replace("{subProjectId}", subProjectId)
      .replace("{pmType}", pmType);
    const response: AxiosResponse<IPmInternalResponse> = await pmHttp.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Uploads previous maintenance documents for a given PM (Preventive Maintenance) ID.
 * @param pmId - The ID of the Preventive Maintenance.
 * @param body - The body data containing the documents to be uploaded.
 * @returns Promise<IPmAttachmentsExpressResponse> - A promise that resolves to the response data after uploading the documents.
 */

export const uploadPrevMaintenanceDocuments = async (
  pmId: string,
  body: FormData,
): Promise<IPmAttachmentsPostResponse> => {
  try {
    const url = UPLOAD_PM_DOCUMENTS.replace("{pmId}", pmId);

    const response = await pmHttp.post(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches previous maintenance documents for a given PM (Preventive Maintenance) ID.
 * @param pmId - The ID of the Preventive Maintenance.
 * @returns Promise<any> - A promise that resolves to normalized previous maintenance documents.
 * The function retrieves documents from the provided pmId, processes the response to normalize it,
 * and returns the normalized data.
 */
export const fetchPrevMaintenanceDocuments = async (
  pmId: string,
  params?: IParams,
) => {
  try {
    const url = GET_PM_DOCUMENTS.replace("{pmId}", pmId);
    const response: AxiosResponse<IPmAttachmentsExpressResponse> =
      await pmHttp.get(url, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches the previous maintenance counts for a specified master project and subproject.
 *
 * @param {string} masterProjectId - The ID of the master project.
 * @param {string} subProjectId - The ID of the subproject.
 * @param {IParams} [params] - Optional parameters to customize the request.
 * @returns {Promise<IPmCountsResponse>} - A promise that resolves with the previous maintenance counts data.
 * @throws {Error} - If an error occurs during the request.
 */
export const fetchPrevMaintenanceCounts = async (
  masterProjectId: string,
  subProjectId: string,
  params?: any, // Make params optional
): Promise<IPmCountsResponse> => {
  try {
    const url = GET_PM_COUNTS.replace(
      "{masterProjectId}",
      masterProjectId,
    ).replace("{subProjectId}", subProjectId);

    const response: AxiosResponse<IPmCountsResponse> = await pmHttp.get(
      url,
      params,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchWorkOrdersSummary = async (
  masterProjectId: string,
  subProjectId: string,
  params?: any, // Make params optional
): Promise<IWorkOrderSummaryResponse> => {
  try {
    const url = GET_WORK_ORDER_SUMMARY.replace(
      "{masterProjectId}",
      masterProjectId,
    ).replace("{subProjectId}", subProjectId);

    const response: AxiosResponse<IWorkOrderSummaryResponse> = await pmHttp.get(
      url,
      params,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchClosedWorkOrdersByAssignees = async (
  params?: any, // Make params optional
): Promise<IClosedWorkOrdersByAssigneesResponse> => {
  try {
    const url = GET_CLOSED_WORK_ORDERS_BY_ASSIGNEES;

    const response: AxiosResponse<IClosedWorkOrdersByAssigneesResponse> =
      await pmHttp.get(url, params);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchClosedWorkOrders = async (
  params?: any, // Make params optional
): Promise<IClosedWorkOrdersResponse> => {
  try {
    const url = GET_CLOSED_WORK_ORDERS;

    const response: AxiosResponse<IClosedWorkOrdersResponse> = await pmHttp.get(
      url,
      params,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches the details of a preventive maintenance given its ID.
 *
 * @param pmId - The ID of the preventive maintenance.
 * @returns A Promise resolving to the details of the preventive maintenance.
 * @throws An error if the request fails.
 */
export const fetchPrevMaintenanceDetail = async (
  pmId: string,
): Promise<IPmInternalResponse> => {
  try {
    const url = GET_PREVENTIVE_MAINTENANCE_DETAIL.replace("{pmId}", pmId);
    const response: AxiosResponse<IPmInternalResponse> = await pmHttp.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches the details of a internal PM.
 *
 * @param pmId - The ID of the internal preventive maintenance.
 * @returns A Promise resolving to the details of the preventive maintenance.
 * @throws An error if the request fails.
 */
export const fetchInternalPMDetails = async (
  pmId: string,
): Promise<IPmInternalDetailResponse> => {
  try {
    const url = GET_PREVENTIVE_MAINTENANCE_DETAIL.replace("{pmId}", pmId);
    const response: AxiosResponse<IPmInternalDetailResponse> =
      await pmHttp.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch Tasks by due date
 * @param { string } startDate - start date.
 * @param { string } endDate - end date.
 * @returns {response} - Promise returns with data returned from the updated task.
 * @throws If an error occurs during the request.
 */
export const fetchPrevMaintenanceByDueDate = async (
  startDate: string,
  endDate: string,
): Promise<IPmInternalByDueDateResponse> => {
  try {
    const response: AxiosResponse<any> = await pmHttp.get(
      GET_PM_BY_DUE_DATE.replace("{startDate}", startDate).replace(
        "{endDate}",
        endDate,
      ),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Delets PM documents for a given PM (Preventive Maintenance) ID.
 * @param id - The ID of the document.
 * @returns Promise<any> - A promise that resolves to normalized previous maintenance documents.
 * The function retrieves documents from the provided pmId, processes the response to normalize it,
 * and returns the normalized data.
 */
export const deletPrevMaintenanceDocuments = async (
  id: string,
  params?: IParams,
) => {
  try {
    const url = GET_PM_DOCUMENTS.replace("{pmId}", id);
    const response: AxiosResponse<IPmAttachmentsExpressResponse> =
      await pmHttp.delete(url, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Updates and restores PM documents.
 *
 * @param {string} id - The ID of the PM document.
 * @param {any} [body] - The data to update or restore the PM document with.
 * @returns {Promise<IPmAttachmentsExpressResponse>} A Promise that resolves with the updated or restored PM document data.
 * @throws {Error} Throws an error if the update or restoration process fails.
 */
export const updateAndRestorePmDocuments = async (id: string, body?: any) => {
  try {
    const url = GET_PM_DOCUMENTS.replace("{pmId}", id);
    const response: AxiosResponse<IPmAttachmentsExpressResponse> =
      await pmHttp.put(url, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
