import { useForm } from "antd/es/form/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "antd";
import { ROUTES } from "routes/Routes.constants";
import { ISubProject } from "redux/components/sub-project";
import { IMasterProject } from "redux/components/master-project";
import { ICalendarEventState, ICalendarFilters } from "./Calendar.interface";
import AlectifyText from "static/texts.json";
import WeeklyCalendar from "./WeeklyCalendar";
import CalendarFilters from "./CalendarFilters";
import MonthlyCalendar from "./MonthlyCalendar";
import dayjs from "dayjs";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import {
  ICalendarDatesState,
  ICalendarState,
} from "./MonthlyCalendar.interface";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { IPmCalendarEventResponse } from "services/calendar/calendar.interface";
import { SERVER_DATE_FORMAT } from "constants/index";
import { formatDate } from "utils/helpers";
import { setActiveMasterProject } from "redux/components/common/sources";
import "./Calendar.scss";

const Calendar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [FormInstance] = useForm();
  const { showCalendarMyWorkOrders, activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );
  const [calendarState, setCalendarState] = useState<ICalendarState>({
    isSelectActive: false,
    selectedDate: new Date(),
  });

  const [filters, setFilters] = useState<ICalendarFilters>({
    masterProject: {
      id: "all",
      name: "All projects",
      color: "#ffffff",
    } as any,
    subProject: null,
    startDate: null,
    endDate: null,
  });

  const [calendarEvents, setCalendarEvents] = useState<ICalendarEventState>({
    data: [],
    fetching: false,
  });

  const [calendarDates, setCalendarDates] = useState<ICalendarDatesState>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const onSelectMasterProject = (
    value: string,
    masterProject: IMasterProject,
  ) => {
    FormInstance.setFieldValue("subProject", "all");
    setFilters({
      ...filters,
      masterProject,
      subProject: null,
    });
  };

  const onSelectSubProject = (value: string, subProject: ISubProject) => {
    setFilters({
      ...filters,
      subProject,
    });
  };

  const onSelectWeek = (startDate: string, endDate: string) => {
    setFilters({
      ...filters,
      startDate,
      endDate,
    });
  };

  const getCalendarEventMonthly = async () => {
    try {
      if (calendarDates.month && calendarDates.year) {
        setCalendarEvents({ fetching: true });
        let pmEvents: IPmCalendarEventResponse;
        const startDate = formatDate(
          dayjs(`${calendarDates.year}-${calendarDates.month}`)
            .startOf("month")
            .day(0),
          SERVER_DATE_FORMAT,
        );

        const endDate = formatDate(
          dayjs(`${calendarDates.year}-${calendarDates.month + 1}`)
            .subtract(1, "day")
            .endOf("week")
            .day(6),
          SERVER_DATE_FORMAT,
        );

        if (
          showCalendarMyWorkOrders ||
          showCalendarMyWorkOrders === undefined
        ) {
          const params = {
            isRecordExist: true,
          };

          [pmEvents] = await Promise.all([
            fetchPmCalendarEvents(
              activeMasterProject?.id ?? "all",
              filters.subProject?.id ?? "all",
              startDate,
              endDate,
              params,
            ),
          ]);

          setCalendarEvents({
            fetching: false,
            data: [...pmEvents.data],
          });
        } else {
          const params = {
            isRecordExist: true,
            global: "true",
          };

          pmEvents = await fetchPmCalendarEvents(
            activeMasterProject?.id ?? "all",
            filters.subProject?.id ?? "all",
            startDate,
            endDate,
            params,
          );
          setCalendarEvents({
            fetching: false,
            data: [...pmEvents.data],
          });
        }
      }
    } catch (error) {
      setCalendarEvents({ fetching: false });
    }
  };

  useEffect(() => {
    const current = dayjs().format("YYYY-MM-DD");
    const start = dayjs().startOf("week").format("YYYY-MM-DD");
    const end = dayjs().endOf("week").format("YYYY-MM-DD");
    const params = new URLSearchParams(location.search);
    if (!params.has("start") || !params.has("end") || !params.has("current")) {
      params.append("start", start);
      params.append("end", end);
      params.append("current", current);
      navigate(`?${params.toString()}`, { replace: true });
    }
    // FormInstance.setFieldsValue({ masterProject: "all", subProject: "all" });
  }, [navigate, location.search]);

  useEffect(() => {
    setFilters({
      ...filters,
      subProject: null,
    });
    getCalendarEventMonthly();
  }, [
    filters.masterProject,
    filters.subProject,
    calendarDates.month,
    calendarDates.year,
    showCalendarMyWorkOrders,
    activeMasterProject?.id,
  ]);
  useEffect(() => {
    if (location.pathname.includes("/company-wide/calendar")) {
      dispatch(setActiveMasterProject(null));
    }
  }, []);

  const getMonthAndYearFromMonthlyCalendar = useMemo(() => {
    return (selectedMonth: number, selectedYear: number) => {
      setCalendarDates({
        month: selectedMonth,
        year: selectedYear,
      });
    };
  }, []);

  return (
    <div className="calendar-container">
      <Breadcrumb
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          {
            title: `${AlectifyText.CALENDAR}`,
          },
        ]}
      />
      <div className="calendar-content">
        <div className="monthly-calendar-col">
          <div className="monthly-main">
            <MonthlyCalendar
              onChange={onSelectWeek}
              calendarEvents={calendarEvents}
              getMonthAndYearFromMonthlyCalendar={
                getMonthAndYearFromMonthlyCalendar
              }
              setCalendarDates={setCalendarDates}
              isSelectActive={calendarState.isSelectActive}
              setCalendarState={setCalendarState}
            />
          </div>
          <div className="site-title">{AlectifyText.SITES}</div>
          <div className="calendar-filters-container">
            <CalendarFilters
              filters={filters}
              FormInstance={FormInstance}
              onChangeMasterProject={() => {}}
              onSelectMasterProject={onSelectMasterProject}
              onSelectSubProject={onSelectSubProject}
            />
          </div>
        </div>
        <div className="weekly-calendar-col">
          <WeeklyCalendar
            {...filters}
            setCalendarState={setCalendarState}
            calendarDates={calendarDates}
            selectedDate={calendarState?.selectedDate}
          />
        </div>
      </div>
    </div>
  );
};
export default Calendar;
