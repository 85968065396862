// libs
import { Row, Spin, Form, Col, message, Image, Upload } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { debounce, isEmpty } from "lodash";
//internal
import { IParams } from "redux/interfaces";
import { FEATURE_FLAGS, IOrganization, IUser } from "redux/components/Auth";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import AddUserForm from "../add-user-form";
import AlectifyButton from "../button";
import { fetchOrganizations } from "services/organization/organization.service";
import {
  PM_CREATE_EDIT_FORM_FIELD_TYPES,
  PM_EDITING_TYPES,
} from "./PMCreateEditForm.constants";
import {
  createMasterPmApprovers,
  createMasterPmAssignees,
  createPmApprovers,
  createPmAssignees,
  createPrevMaintenances,
  fetchExternalPMDetails,
  fetchPMDetailsMaster,
  removeMasterPmApprovers,
  removeMasterPmAssignees,
  removePmApprovers,
  removePmAssignees,
  updatePrevMaintenances,
} from "services/pm-external/pm-external.service";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { PMFormFilds, renderFields } from "./PMFormFields.configs";
import {
  FrequencyTypeEnum,
  IPMCreateEditFormProps,
  IPMFormFieldsInitialState,
  IPMFormFilds,
} from "./PMCreateEditForm.interace";
import { IPmAssgineeCreationPayload } from "services/pm-external/pm-external.interface";
import AlectifyText from "static/texts.json";
import {
  createPayload,
  initializeFormFields,
  initializeFormFieldsOnCreate,
} from "./PMCreateEditForm.helpers";
import { PM_TYPES } from "redux/components/pm-external";
import AssetsTable from "../assets-table";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import ProcedureImport from "../procedure-import";
import { IProcedure } from "services/procedures/procedures.interface";
import { getSubProjectDetails } from "redux/components/sub-project/sources";
import { isFeatureEnabled, removeNullUndefined } from "utils/helpers";
import "components/shared/task-create-edit/TaskCreateEdit.scss";
import { fetchSubProjectDetail } from "services/sub-project/sub-project.service";
import { IRootState } from "redux/rootReducer";
import { TaskPriorityEnum } from "enums";
import { CameraOutlined } from "@ant-design/icons";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import { UploadProps } from "antd/lib";
import { getBase64 } from "../user-profile-form/UserProfileForm.helper";
import { createNewUser, fetchAllUsers } from "services/users/users.service";
import { fetchTeamsList } from "services/teams/teams.service";
import { ITeams } from "services/teams/teams.interface";

const PMCreateEditForm: React.FC<IPMCreateEditFormProps> = (
  props: IPMCreateEditFormProps,
) => {
  const [state, setState] = useState<IPMFormFieldsInitialState>({
    loading: false,
    assets: null,
    fetchingAssets: false,
    assetPackage: null,
    assetLevel: null,
    assetDetail: null,
    detail: "",
    assetPackageTeamMembers: null,
    selectedAsset: null,
    selectedAssetPackage: null,
    organizations: null,
    selectedSupplier: null,
    isRecurring: false,
    dayType: "date",
    selectedMasterProject: null,
    selectedSubProject: null,
    selectedProcedureLibrary: null,
    procedureLibraryId: null,
    frequencyType: null,
    taskCategory: "",
    pmDetails: null,
    previewImage: "",
    fileList: [],
    selectedTeamMembers: null,
    projectTeamMembers: null,
    projectTeams: null,
    selectedTeamId: null,
    selectedMembertype: "teams",
    drawerConfig: null,
  });

  const [loader, setLoader] = useState<boolean>(false);
  const existingUserRef = useRef<IUser | undefined>();
  const organizationRef = useRef<IOrganization | undefined>();
  const ckEditorRef = useRef<string>("");
  const taskCategoryRef = useRef<string>("");

  const common = useSelector((state: IRootState) => state.common);

  const [userForm] = useForm();
  const dispatch = useDispatch();
  const createEditFormRef = useRef<HTMLDivElement>(null);

  const handleUserTypeChange = (value: string) => {
    setState((prev) => {
      return {
        ...prev,
        selectedMembertype: value,
        selectedTeamMembers: null,
      };
    });
    props.FormInstance.setFieldValue("assignees", undefined);
    props.FormInstance.setFieldValue("approvers", undefined);
  };

  const onAssetLevelChange = (value: string) => {
    setState((prev) => {
      return {
        ...prev,
        assetLevel: value,
        selectedAsset: null,
        selectedAssetPackage: null,
        assetDetail: null,
        assetPackage: null,
        assetPackageTeamMembers: null,
        procedureLibraryId: props.procedureLibraryId || null,
      };
    });
  };

  const getSubProjectDettail = async (subProjectId: string, basicInfo: any) => {
    try {
      const response = await fetchSubProjectDetail(subProjectId, "users");
      setState((prev) => {
        return {
          ...prev,
          selectedSubProject: { ...basicInfo, accounts: response.data },
          procedureLibraryId: props.procedureLibraryId || null,
        };
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  const getAssetDetails = async (_: any, record: any) => {
    if (record.tag_id || record.id) {
      setState((prev) => {
        return {
          ...prev,
          selectedAsset: {
            ...record,
            id: record.tag_id || record.id,
            name: record.tag_name || record.name,
          },
          assetDetail: {
            ...record,
            id: record.tag_id || record.id,
            name: record.tag_name || record.name,
          },
          assetLevel: "asset",
        };
      });
      if (!props.editing) {
        props.FormInstance.setFieldValue("assignees", undefined);
      }
    } else {
      setState((prev) => {
        return {
          ...prev,
          assetLevel: "area",
          selectedAssetPackage: {
            ...record,
            id: record.package_id,
            name: record.package_name,
          },
        };
      });
    }
  };
  const getAssetPackageDetails = async (_: any, record: any) => {
    if (record?.id) {
      setState((prev) => {
        return {
          ...prev,
          assetLevel: "area",
          selectedAssetPackage: record,
          loading: false,
        };
      });
    }
  };

  const onAddUser = async () => {
    setLoader(true);
    try {
      const values = {
        organization: organizationRef.current?.id,
        id: existingUserRef.current?.id,
        organization_name: organizationRef.current?.name,
        ...props?.FormInstance.getFieldsValue(),
        user_type: existingUserRef.current?.user_type,
        new: isEmpty(existingUserRef.current),
      };
      await createNewUser(
        props?.masterProjectId || state.selectedMasterProject?.id || "",
        values,
      );
      setLoader(false);
      userForm.resetFields();
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.ADD_TEAM_MEMBER_MODAL,
      });
      getMasterProjectUsers(
        props?.masterProjectId || state.selectedMasterProject?.id || "",
      );
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const onOpenUserModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 425,
      onCancel: () => {
        userForm.resetFields();
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ADD_TEAM_MEMBER_MODAL,
        });
      },
      children: (
        <Spin spinning={loader}>
          <AddUserForm
            form={userForm}
            existingUserRef={existingUserRef}
            organizationRef={organizationRef}
          />
        </Spin>
      ),
      closeIcon: false,
      title: AlectifyText.ADD_TEAM_MEMBER,
      footer: (
        <Row justify={"end"} align={"middle"}>
          <Col span={6}>
            <AlectifyButton
              text="Cancel"
              type="primary"
              htmlType="submit"
              className="alectify-default-button"
              onClick={() => {
                userForm.resetFields();
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.ADD_TEAM_MEMBER_MODAL,
                });
              }}
            />
          </Col>
          <Col span={6}>
            <AlectifyButton text="Submit" type="primary" onClick={onAddUser} />
          </Col>
        </Row>
      ),
      name: MODAL_NAMES.ADD_TEAM_MEMBER_MODAL,
      destroyOnClose: true,
    });
    userForm.resetFields();
  };

  const onDetailsChange = (value: string) => {
    props.FormInstance.setFieldValue("detail", value);
  };

  const onChangeRecurring = (checked: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        isRecurring: checked,
      };
    });
  };

  const onChangePMCategory = (value: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        taskCategory: value,
      };
    });
    taskCategoryRef.current = value;
    props.FormInstance.setFieldsValue({
      [PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY]: value,
    });
  };

  const onDayDateChange = (value: string) => {
    /*   if (value === "day") {
      props.FormInstance.setFieldValue(
        PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
        PM_DAYS_NAME[0].value,
      );
      props.FormInstance.setFieldValue(
        PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK,
        PM_WEEKS[0].value,
      );
    } */
    setState((prev) => {
      return {
        ...prev,
        dayType: value,
      };
    });
  };

  const getOrganizations = debounce(async (value) => {
    try {
      const params: IParams = {
        search: value,
      };
      const response = await fetchOrganizations(params);
      if (!isEmpty(response.data)) {
        setState((prev) => {
          return {
            ...prev,
            organizations: response.data.map((org: IOrganization) => ({
              ...org,
              key: org.id,
              label: org.name,
              value: org.name,
            })),
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, 500);

  const onSupplierSelect = (record: any) => {
    setState((prev) => {
      return {
        ...prev,
        selectedSupplier: record,
      };
    });
  };

  const onFrequencyTypeChange = (value: string, type?: string) => {
    if (value) {
      setState((prev) => {
        return {
          ...prev,
          frequencyType: value as any,
        };
      });
      if (value === FrequencyTypeEnum.MONTHLY) {
        setState((prev) => {
          return {
            ...prev,
            dayType: !isEmpty(type) ? String(type) : "date",
          };
        });
        /*    props.FormInstance.setFields([
          {
            name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY_DATE,
            value: "date",
          },
          {
            name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE,
            value: 1,
          },
        ]); */
      } else if (value === FrequencyTypeEnum.WEEKLY) {
        setState((prev) => {
          return {
            ...prev,
            dayType: !isEmpty(type) ? String(type) : "day",
          };
        });
        // props.FormInstance.setFieldValue(
        //   PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
        //   PM_DAYS_NAME[0].value,
        // );
      }
    }
  };

  const onSubmit = async (values: any) => {
    await props.FormInstance.validateFields();
    if (state.loading) return;
    setState({
      ...state,
      loading: true,
    });
    try {
      let payload = createPayload(values, state, props, state.pmDetails);
      payload = removeNullUndefined(payload);
      let formData = new FormData();
      if (!isEmpty(state.fileList[0])) {
        formData.append("image", state.fileList[0]?.originFileObj as Blob);
      }
      for (let key in payload) {
        if (key === "approvers") {
          payload[key].forEach((ap: any, index: any) => {
            formData.append(`approvers[${index}]`, ap);
          });
        } else if (key === "assignees") {
          payload[key].forEach((as: any, index: any) => {
            formData.append(`assignees[${index}]`, as);
          });
        } else if (
          key === "teamMembers" &&
          !payload?.teamId &&
          !props.editing
        ) {
          payload[key].forEach((as: any, index: any) => {
            formData.append(`teamMembers[${index}]`, as);
          });
        } else if (key === "teamMembers" && payload?.teamId) {
          formData.delete("teamMembers");
        } else if (key === "teamMembers" && props.copying) {
          payload[key].forEach((as: any, index: any) => {
            formData.append(`teamMembers[${index}]`, as);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }

      if (props.editing && !props.copying) {
        formData.delete("teamMembers");
        await updatePrevMaintenances(props.pmId || "", formData);
        props.callbackEffects && props.callbackEffects();
        props.FormInstance.resetFields();
      } else {
        await createPrevMaintenances(
          props.isTask
            ? PM_TYPES.TASK
            : props.isPmExternal
            ? PM_TYPES.PM_EXTERNAL
            : PM_TYPES.PM_INTERNAL,
          formData,
        );
        props.callbackEffects && props.callbackEffects();
      }
      setState({
        ...state,
        loading: false,
      });
      props.FormInstance.resetFields();
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
      });

      if (props?.masterProjectId !== "all" && props?.masterProjectId) {
        dispatch(
          getSubProjectDetails(props?.masterProjectId, props?.subProjectId),
        );
      }
    } catch (ex) {
      setState({
        ...state,
        loading: false,
      });
      console.log(ex);
    }
  };

  const getPMDetails = async (id: string) => {
    setState((prev) => {
      return { ...prev, loading: true };
    });
    try {
      const response =
        props.editType === PM_EDITING_TYPES.THIS_EVENT
          ? (await fetchExternalPMDetails(id)).data
          : (await fetchPMDetailsMaster(id)).data?.masterPreventiveMaintenance;

      if (!isEmpty(response)) {
        getMasterProjectUsers(response.project.id);
        getSubProjectDettail(response.subProject.id, response?.subProject);
        const responseTeam = Array.isArray(response.team?.projectTeamMembers)
          ? response?.team?.projectTeamMembers.flatMap(
              (member: any) => member.user,
            )
          : [];
        const responseUsers =
          response?.teamMembers?.map((member: any) => member.user) ?? [];
        setState((prev) => {
          return {
            ...prev,
            loading: false,
            pmDetails: response,
            assetLevel: response.isGeneric ? "generic" : "asset",
            isRecurring: response?.isRecurring,
            selectedProcedureLibrary: (props.editType ===
            PM_EDITING_TYPES.ALL_EVENTS
              ? response?.procedure
              : response?.procedure || null) as any,
            procedureLibraryId: ((props.copying
              ? response?.procedureLibraryId
              : null) || null) as any,
            dayType: response?.dayType,
            previewImage: (!props.copying && response.imageUrl) || "",
            selectedMasterProject: response?.project,
            selectedSubProject: response.subProject,
            selectedTeamMembers: isEmpty(response?.teamMembers)
              ? (responseTeam as IUser[] | ITeams[])
              : (responseUsers as IUser[] | ITeams[]),
            selectedMembertype: isEmpty(response?.teamMembers)
              ? "teams"
              : "users",
          };
        });
        initializeFormFields(
          props,
          response,
          common.activeMasterProject || null,
          ckEditorRef,
          getAssetDetails,
          getAssetPackageDetails,
          onChangeRecurring,
          onChangePMCategory,
          getSubProjectDettail,
          onFrequencyTypeChange,
        );
      }
    } catch (ex) {
      setState({
        ...state,
        loading: false,
      });
      console.log(ex);
    }
  };
  useEffect(() => {
    if (props.pmId) {
      getPMDetails(props.pmId);
    } else {
      initializeFormFieldsOnCreate(props, common.activeMasterProject || null);
      setState((prev) => {
        return {
          ...prev,
          selectedMasterProject: common.activeMasterProject,
        };
      });
    }
  }, [props.pmId]);

  const scrollToBottom = () => {
    const formFields = createEditFormRef.current;

    if (formFields) {
      const formItems = formFields.querySelectorAll(".ant-form-item");
      const lastFormItem = formItems[formItems.length - 1];

      if (lastFormItem) {
        lastFormItem.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  };
  const scrollToTop = () => {
    const formFields = createEditFormRef.current;

    if (formFields) {
      const formItems = formFields.querySelectorAll(".ant-form-item");
      const firstFormItem = formItems[0]; // Get the first form item

      if (firstFormItem) {
        firstFormItem.scrollIntoView({
          behavior: "smooth",
          block: "start", // Align the top of the element to the top of the scrollable area
          inline: "nearest",
        });
      }
    }
  };
  useEffect(() => {
    if (
      state.isRecurring &&
      !state.assetDetail &&
      props.editType !== PM_EDITING_TYPES.THIS_EVENT
    ) {
      scrollToBottom();
    }
  }, [state.isRecurring, state.dayType]);

  const createAssignee = async (user: IUser, type = "assignees") => {
    setState((prev) => {
      return { ...prev, loading: true };
    });
    try {
      const payload: IPmAssgineeCreationPayload = {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        userId: user.id,
        imageUrl: user.image_url || "",
      };
      if (props.editType === PM_EDITING_TYPES.ALL_EVENTS) {
        if (type === "assignees") {
          await createMasterPmAssignees(
            state.pmDetails?.id || props.pmId,
            payload,
          );
        } else {
          await createMasterPmApprovers(
            state.pmDetails?.id || props.pmId,
            payload,
          );
        }
      } else {
        if (type === "assignees") {
          await createPmAssignees(state.pmDetails?.id || props.pmId, payload);
        } else {
          await createPmApprovers(state.pmDetails?.id || props.pmId, payload);
        }
      }
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.success(
        `${type === "assignees" ? "Assignee" : "Approvers"} has been added`,
      );
    } catch (ex) {
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.error("couldn't add assignee");
      console.log(ex);
    }
  };

  const removeAssignee = async (userId: string, type = "assignees") => {
    setState((prev) => {
      return { ...prev, loading: true };
    });

    try {
      if (props.editType === PM_EDITING_TYPES.ALL_EVENTS) {
        // sends master pm id for future tasks
        if (type === "assignees") {
          await removeMasterPmAssignees(state.pmDetails?.id || "", userId);
        } else {
          await removeMasterPmApprovers(state.pmDetails?.id || "", userId);
        }
      } else {
        // parent pm id for current task
        if (type === "assignees") {
          await removePmAssignees(props.pmId || "", userId);
        } else {
          await removePmApprovers(props.pmId || "", userId);
        }
      }
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.success(
        `${type === "assignees" ? "Assignee" : "Approver"} has been removed.`,
      );
    } catch (ex) {
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.error("couldn't remove assignee");
      console.log(ex);
    }
  };

  const equipmentModalHandler = () =>
    ModalServiceInstance.open(AlectifyModal, {
      width: 1200,
      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      title: "Select Asset",
      children: (
        <AssetsTable
          selectRowOption
          subProjectId={state.selectedSubProject?.id}
          showAction
          onRowSelect={(record: any) => {
            props.FormInstance.setFieldsValue({ assets: record });
            getAssetDetails(null, record);
            ModalServiceInstance.close(AlectifyModal, {
              name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
            });
          }}
          showSearch
        />
      ),
      footer: null,
      onCancel: () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
        }),
    });

  const onMasterProjectChange = () => {
    props.FormInstance.setFieldValue("subProject", "");
    setState((prev) => {
      return {
        ...prev,
        selectedSubProject: null,
        selectedTeamMembers: null,
        projectTeamMembers: null,
        projectTeams: null,
        procedureLibraryId: null,
        selectedProcedureLibrary: null,
      };
    });
  };

  const onMasterProjectSelect = async (
    value: string,
    masterProject: IMasterProject,
  ) => {
    props.FormInstance.resetFields([
      PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSIGNEES,
      PM_CREATE_EDIT_FORM_FIELD_TYPES.APPROVERS,
      PM_CREATE_EDIT_FORM_FIELD_TYPES.TEAM_MEMBERS_DROPDOWN,
    ]);
    setState((prev) => {
      return {
        ...prev,
        selectedMasterProject: masterProject,
        selectedAsset: null,
        selectedAssetPackage: null,
        assetDetail: null,
        assetPackage: null,
        assetPackageTeamMembers: null,
        selectedSubProject: null,
        procedureLibraryId: null,
        selectedProcedureLibrary: null,
        selectedTeamMembers: null,
      };
    });
    // get master project users
    getMasterProjectUsers(masterProject?.id);
    getTeamsList(masterProject?.id);
    props.FormInstance.setFieldValue("subProject", null);
  };

  const onSubProjectSelect = (value: string, subProject: ISubProject) => {
    setState((prev) => {
      props.FormInstance.resetFields([
        PM_CREATE_EDIT_FORM_FIELD_TYPES.APPROVERS,
        PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSIGNEES,
      ]);
      return {
        ...prev,
        selectedSubProject: subProject,
        selectedAsset: null,
        selectedAssetPackage: null,
        assetDetail: null,
        assetPackage: null,
        assetPackageTeamMembers: null,
      };
    });
  };

  const onSelectProcedure = (procedure: IProcedure | null) => {
    setState((prev) => {
      return {
        ...prev,
        selectedProcedureLibrary: procedure,
        procedureLibraryId: isEmpty(procedure)
          ? null
          : state.procedureLibraryId,
      };
    });
    scrollToTop();
  };

  const handleImageUpload: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setState((prev) => {
      return {
        ...prev,
        fileList: newFileList,
      };
    });
    if (newFileList.length > 0) {
      handleImagePreview(newFileList[0].originFileObj);
    }
  };

  const handleImagePreview = async (file: any) => {
    if (file) {
      file.preview = await getBase64(file);
      setState((prev) => {
        return {
          ...prev,
          previewImage: file.preview,
        };
      });
    }
  };

  const onSelectTeamMember = (value: string) => {
    if (value) {
      const user: any =
        state?.selectedMembertype === "teams"
          ? state.projectTeams?.find((d: ITeams) => d.id === value)
          : state.projectTeamMembers?.find((d: IUser) => d.id === value);
      const selectedUsersList = state.selectedTeamMembers || [];
      const isUserAlreadySelected = selectedUsersList.some(
        (selectedUser: IUser | ITeams) => selectedUser?.id === value,
      );
      if (
        user &&
        !isUserAlreadySelected &&
        state?.selectedMembertype === "users"
      ) {
        setState((prev: any) => ({
          ...prev,
          selectedTeamMembers: [...selectedUsersList, user],
        }));
      } else {
        setState((prev: any) => ({
          ...prev,
          selectedTeamMembers: user.team_members || [],
          selectedTeamId: user.id,
        }));
        props.FormInstance.setFieldValue("assignees", undefined);
        props.FormInstance.setFieldValue("approvers", undefined);
      }
    }
  };
  const onDeselectTeamMember = (value: string) => {
    setState((prev) => {
      if (
        state?.selectedMembertype === "users" &&
        Array.isArray(state?.selectedTeamMembers)
      ) {
        return {
          ...prev,
          selectedTeamMembers: (state.selectedTeamMembers as IUser[]).filter(
            (d: IUser) => d.id !== value,
          ),
        };
      } else if (
        state?.selectedMembertype === "teams" &&
        Array.isArray(state?.selectedTeamMembers)
      ) {
        return {
          ...prev,
          selectedTeamMembers: (state.selectedTeamMembers as ITeams[]).filter(
            (d: ITeams) => d.id !== value,
          ),
        };
      }
      return prev;
    });
  };

  const getMasterProjectUsers = async (id: string) => {
    try {
      const response = await fetchAllUsers(id || "");
      if (response.success) {
        setState((prev) => {
          return {
            ...prev,
            projectTeamMembers: response?.data,
          };
        });
      }
    } catch (ex) {}
  };

  const getTeamsList = async (id: string) => {
    const params = {
      is_active: "true",
    };
    try {
      const response = await fetchTeamsList(id || "", params as any);
      if (response.success) {
        setState((prev) => {
          return {
            ...prev,
            projectTeams: response.data,
          };
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  useEffect(() => {
    if (
      props?.masterProjectId ||
      common.activeMasterProject ||
      props?.copying
    ) {
      if (props?.masterProjectId || common.activeMasterProject?.id) {
        getMasterProjectUsers(
          props?.masterProjectId || common.activeMasterProject?.id || "",
        );
        getTeamsList(
          props?.masterProjectId || common.activeMasterProject?.id || "",
        );
      }
    }
  }, []);

  const pm = state.pmDetails;

  /* 
    Snippet below is for update the drawer props (width specifically) based on procedure library selection.
  */
  useEffect(() => {
    if (isEmpty(state.selectedProcedureLibrary)) {
      setState((prev) => {
        return {
          ...prev,
          drawerConfig: props.config
            ? { ...props.config }
            : { ...prev.drawerConfig },
        };
      });
      DrawerServiceInstance.updateDrawerProps(
        DRAWER_CONSTANTS.CREATE_PM_DRAWER,
        props.config
          ? {
              ...props.config,
              width: 630,
            }
          : {
              ...state.drawerConfig,
              width: 630,
            },
      );
    } else {
      DrawerServiceInstance.updateDrawerProps(
        DRAWER_CONSTANTS.CREATE_PM_DRAWER,
        props.config
          ? {
              ...props.config,
              width: 1436,
            }
          : {
              ...state.drawerConfig,
              width: 1436,
            },
      );
      setState((prev) => {
        return {
          ...prev,
          drawerConfig: props.config
            ? { ...props.config }
            : { ...prev.drawerConfig },
        };
      });
    }
  }, [state.selectedProcedureLibrary, props.config]);

  return (
    <Spin spinning={state.loading}>
      <Row
        ref={createEditFormRef}
        gutter={40}
        justify={"start"}
        align={"top"}
        className="alectify-pm-create-edit-form"
      >
        {/* would be 10 for procedure */}
        <Col
          span={
            isFeatureEnabled(FEATURE_FLAGS.IMPORT_PROCEDURE)
              ? isEmpty(state.selectedProcedureLibrary)
                ? 24
                : 10
              : 24
          }
        >
          <Form
            name="taskCreateEdit"
            layout="vertical"
            className="alectify-task-creation-container"
            onFinish={onSubmit}
            form={props.FormInstance}
            initialValues={{
              priority: TaskPriorityEnum.NORMAL,
            }}
          >
            <Row justify="start" align="middle" gutter={24}>
              <Col span={24} className="text-align-center">
                <div className="profile-image-container">
                  <div className="image-wrapper">
                    <Image
                      preview={false}
                      src={state.previewImage || NO_IMAGE_AVATAR}
                      className="contact-profile-image"
                    />
                    <Upload
                      maxCount={1}
                      accept="image/*"
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={handleImageUpload}
                    >
                      <CameraOutlined className="camera-icon" />
                    </Upload>
                  </div>
                </div>
              </Col>
              {PMFormFilds({
                assetLoader: state.fetchingAssets,
                assets: state.assets,
                selectedAsset: state.selectedAsset,
                assetPackage: state.assetPackage,
                selectedAssetPackage: state.selectedAssetPackage,
                assetDetail: state.assetDetail,
                assetPackageDetail: state.assetPackageTeamMembers,
                detail: ckEditorRef.current,
                assetLevel: state.assetLevel,
                getOrganizations,
                organizations: state.organizations,
                isRecurring: state.isRecurring,
                taskCategory: state?.taskCategory || taskCategoryRef.current,
                dayType: state.dayType,
                editing: props.editing,
                copying: props.copying,
                isPmExternal: props.isPmExternal,
                pmData: pm || null,
                isGeneric: pm?.isGeneric || false,
                selectedMasterProject: state.selectedMasterProject,
                selectedSubProject: state.selectedSubProject,
                showProjectFields: Boolean(props.isMyWorkOrder),
                myWorkOrder: props.myWorkOrder,
                masterProjectId: props.masterProjectId || "",
                subProjectId: state.selectedSubProject?.id || "",
                editType: props.editType,
                isTask: props.isTask,
                frequencyType: state.frequencyType as string,
                users: state.projectTeamMembers || [],
                selectedTeamMembers: state.selectedTeamMembers || [],
                onSelectMembersType: state.selectedMembertype,
                formDate: props.FormInstance,
                selectedMemberType: state.selectedMembertype,
                onDayDateChange,
                onSelectAsset: getAssetDetails,
                onSelectAssetPackage: getAssetPackageDetails,
                onOpenUserModal,
                onSupplierSelect,
                onChangeRecurring,
                onChangePMCategory,
                onAssetLevelChange: onAssetLevelChange,
                onDetailsChange: onDetailsChange,
                createAssignee,
                removeAssignee,
                equipmentModalHandler,
                onMasterProjectSelect,
                onSubProjectSelect,
                onMasterProjectChange,
                onFrequencyTypeChange,
                onSelectTeamMember,
                onDeselectTeamMember,
                handleUserTypeChange,
              }).map((field: IPMFormFilds, index: number) => {
                if (field.show) {
                  return (
                    <Col span={field.colSpan} key={index.toString()}>
                      {renderFields(field)}
                    </Col>
                  );
                }
                return <></>;
              })}
            </Row>
          </Form>
        </Col>
        {isFeatureEnabled(FEATURE_FLAGS.IMPORT_PROCEDURE) &&
          (props.isTask ||
            !isEmpty(state.taskCategory || taskCategoryRef.current)) && (
            <Col
              span={isEmpty(state.selectedProcedureLibrary) ? 24 : 14}
              className="alectify-pm-create-edit-procedure-import"
            >
              <ProcedureImport
                onSelectProcedure={onSelectProcedure}
                procedure={state.selectedProcedureLibrary || null}
                projectId={state.selectedMasterProject?.id || ""}
              />
            </Col>
          )}
      </Row>
    </Spin>
  );
};
export default memo(PMCreateEditForm);
