import DayJs from "dayjs";
import { Badge, Card, Col, Row, Space, Spin, Typography } from "antd";
import { CountInColCard } from "components/shared/custom-cards/count-card";
import AlectifyBarChart from "components/shared/chart";
import AlectifyText from "static/texts.json";
import { useEffect, useState } from "react";
import {
  fetchClosedWorkOrders,
  fetchClosedWorkOrdersByAssignees,
  fetchPrevMaintenanceCounts,
  fetchWorkOrdersSummary,
} from "services/pm-internal/pm-internal.service";
import {
  // IDashboardOverviewResponse,
  IDashboardPlatformSummary,
  IDashboardResponse,
  IPmState,
} from "services/dashboard/dashboard.interface";
import {
  fetchDashboardOverDueWorkOrdersDetails,
  // fetchDashboardOverviewDetails,
  fetchPlatformSummary,
} from "services/dashboard/dashboard.service";
import {
  openWorkOrdersStatisticsOptions,
  barOverDueWorkChartOptions,
  // openWorkOrdersStatisticsSeries,
  overDueWorkChartSeries,
  overDueWorkOrdersOptions,
  overDueWorkOrdersSeries,
  openWorkOrdersStatisticsSeriesExternal,
  // pieChartOptions,
  openWorkOrdersStatisticsOptionsV2,
  openWorkOrdersStatisticsSeriesV2,
  closedWorkOrderOptions,
  recurringChartOptions,
  openWorkOrderOptions,
  getSparePartsUtilizationChartOptions,
  getSparePartsUtilizationChartSeries,
} from "./sources";
import "./Dashboard.styles.scss";
import {
  enumToTile,
  formatDate,
  getFullUserName,
  isExternalUser,
  renderAmount,
  // sumValues,
} from "utils/helpers";
import { SERVER_DATE_FORMAT } from "constants/index";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { isEmpty } from "lodash";
import DashboardWorkOrders from "components/shared/dashboard-work-orders";
import { MaintenanceCategoriesEnum, PM_STATUS } from "enums";
import {
  DueDateDelayFiltersEnum,
  PM_TYPES,
} from "redux/components/pm-external";
import ReactApexChart from "react-apexcharts";
// import { getPmExternal } from "redux/components/pm-external/sources";
import {
  fetchSparePartDashboardMonthlyCost,
  fetchSparePartDashboardStats,
} from "services/spare-parts/spare-parts.service";
import {
  ISparePartDashboardMonthlyCost,
  ISparePartDashboardStats,
} from "services/spare-parts/spare-parts.interface";
import moment from "moment";
// import { AppEnvEnum, config } from "utils/configs";
import {
  IClosedWorkOrderData,
  IClosedWorkOrdersByAssignees,
  IWorkOrderSummaryResponse,
} from "services/pm-internal/pm-internal.interface";
import AlectifyTable from "components/shared/table";
import SparePartsTable from "components/shared/spare-parts-table";

const DashboardStatistics: React.FC = () => {
  const [pmState, setPmState] = useState<IPmState>({
    taskCounts: null,
    taskCountsWithDateFilter: null,
    pmCounts: null,
    pmCountsWithDateFilter: null,
    loading: false,
  });
  const [platformSummary, setPlatformSummary] =
    useState<IDashboardPlatformSummary>();
  const [workOrdersSummary, setWorkOrdersSummary] =
    useState<IWorkOrderSummaryResponse>();
  const [closedWorkOrdersByAssignees, setClosedWorkOrdersByAssginees] =
    useState<IClosedWorkOrdersByAssignees[]>();
  const [closedWorkOrders, setClosedWorkOrders] =
    useState<IClosedWorkOrderData>();
  // const [overviewData, setOverviewData] =
  //   useState<IDashboardOverviewResponse>();
  const [sparePartsDashboardStats, setSparePartsDashboardStats] =
    useState<ISparePartDashboardStats>();
  const [sparePartsDashboardMonthlyCost, setSparePartsDashboardMonthlyCost] =
    useState<ISparePartDashboardMonthlyCost[]>();

  const [overdueWorkOrderDetails, setOverdueWorkOrderDetails] =
    useState<IDashboardResponse>();

  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const { auth } = useSelector((auth: IRootState) => auth);

  /*   const getOverviewDetails = async (ids?: string[]) => {
    const params = {
      master_project_ids: !isEmpty(activeMasterProject)
        ? activeMasterProject.id
        : undefined,
    };
    try {
      const response: IDashboardOverviewResponse =
        await fetchDashboardOverviewDetails(params);
      // setOverviewData(response);
    } catch (error) {
      console.log(error);
    }
  }; */

  const getPlatformSummary = async () => {
    try {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const response = await fetchPlatformSummary(params);
      setPlatformSummary(response.data);
    } catch (error) {}
  };

  const getWorkOrdersSummary = async () => {
    try {
      const response = await fetchWorkOrdersSummary(
        activeMasterProject?.id || "all",
        "all",
      );
      setWorkOrdersSummary(response);
    } catch (error) {}
  };

  const getClosedWorkOrdersByAssignees = async () => {
    try {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const response = await fetchClosedWorkOrdersByAssignees(params);
      setClosedWorkOrdersByAssginees(response.data);
    } catch (error) {}
  };

  const getClosedWorkOrders = async () => {
    try {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const response = await fetchClosedWorkOrders(params);
      setClosedWorkOrders(response.data);
    } catch (error) {}
  };

  const getPmCountDetails = async (filters?: any[]) => {
    try {
      setPmState((prevState: IPmState) => ({
        ...prevState,
        loading: true,
      }));
      const currentDate = DayJs();
      const startOfMonth = currentDate.startOf("month");
      const formattedStartDate = formatDate(startOfMonth, SERVER_DATE_FORMAT);
      const lastDayOfMonth = currentDate.endOf("month");
      const formattedEndDate = lastDayOfMonth.format("YYYY-MM-DD");

      const params = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        projectIds: [activeMasterProject?.id] || [],
      };

      const paramsForWithoutDateFilter = {
        projectIds: [activeMasterProject?.id] || [],
        assignees: isExternalUser(auth?.user) ? [auth?.user?.id] : undefined,
        createdById: isExternalUser(auth?.user) ? auth?.user?.id : undefined,
        approvers: isExternalUser(auth?.user) ? [auth?.user?.id] : undefined,
      };

      const [responseWithDateFilter, responseWithoutDateFilter] =
        await Promise.all([
          fetchPrevMaintenanceCounts("all", "all", params),
          fetchPrevMaintenanceCounts("all", "all", paramsForWithoutDateFilter),
        ]);
      setPmState((prevState) => ({
        ...prevState,
        taskCounts: responseWithoutDateFilter.data,
        taskCountsWithDateFilter: responseWithDateFilter.data,
        pmCounts: responseWithoutDateFilter.data,
        pmCountsWithDateFilter: responseWithDateFilter.data,
        loading: false,
      }));
    } catch (error) {
      setPmState((prevState: IPmState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };

  const getDashboardOverDueWorkOrdersDetails = async (ids?: string[]) => {
    const params = {
      projectIds: [activeMasterProject?.id] || [],
    };
    try {
      const response: IDashboardResponse =
        await fetchDashboardOverDueWorkOrdersDetails(params);
      setOverdueWorkOrderDetails(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getSparePartsStats = async () => {
    try {
      // if (config.REACT_APP_ENV !== AppEnvEnum.PRODUCTION) {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const [dashboardStats, monthlyCounts] = await Promise.all([
        fetchSparePartDashboardStats(params),
        fetchSparePartDashboardMonthlyCost(params),
      ]);
      setSparePartsDashboardStats(dashboardStats.data);
      setSparePartsDashboardMonthlyCost(
        monthlyCounts.data?.splice(0, moment().month() + 1),
      );
      // }
    } catch (error) {
      console.log(error);
    }
  };

  /*  const SubmitMasterProjectFilter = (projectIds: string[]) => {
    getPmCountDetails(projectIds);
    getDashboardOverDueWorkOrdersDetails(projectIds);
    getOverviewDetails(projectIds);

    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DASHHBOARD_MASTERPROJECT_FILTER,
    });
  }; */

  // const { pmExternal, tasks } = useSelector((state: IRootState) => state);

  const openSparePartsModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1400,
      name: MODAL_NAMES.DASHBOARD_SPARE_PARTS_MODAL,
      title: "Spare Pats as of Today",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.DASHBOARD_SPARE_PARTS_MODAL,
        });
      },
      footer: null,
      children: <SparePartsTable showAction={false} />,
    });
  };

  useEffect(() => {
    Promise.all([
      getPlatformSummary(),
      getWorkOrdersSummary(),
      getClosedWorkOrdersByAssignees(),
      getClosedWorkOrders(),
      getPmCountDetails(),
      getDashboardOverDueWorkOrdersDetails(),
      // getOverviewDetails(),
      getSparePartsStats(),
    ]);
  }, [activeMasterProject?.id]);

  const openWorkOrdersModal = (
    defaultParams: any = {},
    status?: PM_STATUS,
    pmType?: string,
  ) => {
    const statusToParams =
      status?.toUpperCase() === "SCHEDULED" ? PM_STATUS.PENDING : status;
    ModalServiceInstance.open(AlectifyModal, {
      title:
        status === PM_STATUS.COMPLETED
          ? "Closed Work Orders"
          : AlectifyText.OPEN_WORK_ORDERS_STATISTICS,
      name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
      width: 1400,
      footer: null,
      className: "alectify-pdf-viewer-container",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
        });
      },
      children: (
        <DashboardWorkOrders
          defaultParams={{
            status: status
              ? [statusToParams]
              : [PM_STATUS.PENDING, PM_STATUS.WAITING_FOR_REVIEW],
            ...defaultParams,
            pmType: pmType === "Task" ? "TASK" : undefined,
            taskCategory:
              (pmType === "PM"
                ? MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
                : pmType === "CM" &&
                  MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE) ||
              undefined,
          }}
          filters={{
            key: "pmType",
            defaultValue:
              pmType === "Task"
                ? "TASK"
                : pmType === "PM"
                ? MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
                : (pmType === "CM" &&
                    MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE) ||
                  undefined,
            options: [
              {
                label: `All`,
                value: "all",
              },
              {
                label: AlectifyText.TASK,
                value: PM_TYPES.TASK,
              },
              {
                label: enumToTile(
                  MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE,
                ),
                value: MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE,
              },
              {
                label: enumToTile(
                  MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE,
                ),
                value: MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE,
              },
            ],
          }}
        />
      ),
    });
  };

  const gridStyle: React.CSSProperties = {
    width: "50%",
  };

  return (
    <div className="dashboard-container">
      {!isExternalUser(auth.user) ? (
        <Spin spinning={pmState.loading}>
          {/* <ProjectFilter onSubmit={SubmitMasterProjectFilter} /> */}
          <h1>{AlectifyText.PLATFORM_SUMMARY}</h1>
          <Row gutter={16} className="mt-20 mb-20">
            <Col span={24}>
              <div className="cards-body">
                <Row gutter={[16, 16]} className="m-10">
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.SITE}
                      count={platformSummary?.totalSites || 0}
                    />
                  </Col>
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.ASSETS}
                      count={platformSummary?.totalAssets || 0}
                    />
                  </Col>
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.PROCEDURES}
                      count={platformSummary?.totalProcedures || 0}
                    />
                  </Col>
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.DOCUMENTS}
                      count={platformSummary?.totalDocuments || 0}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <h1>Open Work Orders</h1>
          <Row gutter={16} className="mt-20 mb-40">
            <Col span={8}>
              <div className="cards-body mt-20">
                <h2
                  className="stat-headings-clickable"
                  onClick={() => openWorkOrdersModal()}
                >
                  {/* {AlectifyText.OPEN_WORK_ORDERS_STATISTICS} */}
                  By Status
                </h2>

                <AlectifyBarChart
                  type="bar"
                  height={440}
                  options={openWorkOrdersStatisticsOptionsV2(
                    (e: string, category?: string) =>
                      openWorkOrdersModal(null, e as any, category),
                  )}
                  series={openWorkOrdersStatisticsSeriesV2(workOrdersSummary)}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="cards-body mt-20 ">
                <h2 className="stat-headings">Recurring Vs Non-Recurring</h2>
                <ReactApexChart
                  options={recurringChartOptions("PM") as any}
                  height={400}
                  series={[
                    {
                      name: "PM",
                      data: [
                        workOrdersSummary?.data?.pm?.recurring || 0,
                        workOrdersSummary?.data?.pm?.nonRecurring || 0,
                      ],
                    },
                    {
                      name: "CM",
                      data: [
                        workOrdersSummary?.data?.cm?.recurring || 0,
                        workOrdersSummary?.data?.cm?.nonRecurring || 0,
                      ],
                    },
                    {
                      name: "Task",
                      data: [
                        workOrdersSummary?.data?.task?.recurring || 0,
                        workOrdersSummary?.data?.task?.nonRecurring || 0,
                      ],
                    },
                  ]}
                  type="bar"
                  width={"100%"}
                />
                {/* <Row gutter={15}>
                  <Col span={12}>
                    <ReactApexChart
                      options={recurringChartOptions("PM") as any}
                      series={[
                        workOrdersSummary?.data?.pm?.recurring || 0,
                        workOrdersSummary?.data?.pm?.nonRecurring || 0,
                      ]}
                      type="bar"
                      width={"100%"}
                    />
                  </Col>
                  <Col span={12}>
                    <ReactApexChart
                      options={recurringChartOptions("CM") as any}
                      series={[
                        workOrdersSummary?.data?.cm?.recurring || 0,
                        workOrdersSummary?.data?.cm?.nonRecurring || 0,
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </Col>
                  <Col span={12}>
                    <ReactApexChart
                      options={recurringChartOptions("Task") as any}
                      series={[
                        workOrdersSummary?.data?.task?.recurring || 0,
                        workOrdersSummary?.data?.task?.nonRecurring || 0,
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </Col>
                </Row>
                <Space></Space> */}
              </div>
            </Col>
            <Col span={8}>
              <div
                className="cards-body mt-20"
                onClick={() => {
                  ModalServiceInstance.open(AlectifyModal, {
                    // title: AlectifyText.SCHEDULED_WORK_ORDERS_STATUS,
                    title: "Pending (Scheduled & In Progress)",
                    name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
                    width: 1400,
                    footer: null,
                    className: "alectify-pdf-viewer-container",
                    onCancel: () => {
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
                      });
                    },
                    children: (
                      <DashboardWorkOrders
                        defaultParams={{
                          status: [
                            PM_STATUS.PENDING,
                            // PM_STATUS.WAITING_FOR_REVIEW,
                          ],
                          dueDate: DueDateDelayFiltersEnum.ON_TIME,
                        }}
                        filters={{
                          key: "dueDate",
                          options: [
                            {
                              label: `On Time`,
                              value: DueDateDelayFiltersEnum.ON_TIME,
                            },
                            {
                              label: `< 7 Days`,
                              value: DueDateDelayFiltersEnum.SEVEN_DAYS_AGO,
                            },
                            {
                              label: `7 to 14 Days`,
                              value:
                                DueDateDelayFiltersEnum.SEVEN_TO_14_DAYS_AGO,
                            },
                            {
                              label: `14+ Days`,
                              value:
                                DueDateDelayFiltersEnum.MORE_THAN_14_DAYS_AGO,
                            },
                          ],
                        }}
                      />
                    ),
                  });
                }}
              >
                <h2 className="stat-headings-clickable">
                  {/* {AlectifyText.SCHEDULED_WORK_ORDERS_STATUS} */}
                  On Time vs Delayed
                </h2>

                {overdueWorkOrderDetails?.data?.openItems &&
                overdueWorkOrderDetails.data.openItems.length > 0 ? (
                  <AlectifyBarChart
                    options={barOverDueWorkChartOptions}
                    series={overDueWorkChartSeries(
                      overdueWorkOrderDetails.data,
                    )}
                    type="bar"
                    height={400}
                  />
                ) : (
                  <AlectifyBarChart
                    options={barOverDueWorkChartOptions}
                    series={[{ name: "No Data", data: [] }]}
                    type="bar"
                    height={370}
                  />
                )}
              </div>
            </Col>
          </Row>
          <h1>Work Order Statistics</h1>
          <Row gutter={16}>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="cards-body">
                    <h2 className="stat-headings">
                      {AlectifyText.OPEN_WORK_ORDERS}
                    </h2>
                    <ReactApexChart
                      options={openWorkOrderOptions("PM") as any}
                      series={[
                        (workOrdersSummary?.data?.pm?.inProgress || 0) +
                          (workOrdersSummary?.data?.pm?.pending || 0) +
                          (workOrdersSummary?.data?.pm?.waitingForReview || 0),
                        (workOrdersSummary?.data?.cm?.inProgress || 0) +
                          (workOrdersSummary?.data?.cm?.pending || 0) +
                          (workOrdersSummary?.data?.cm?.waitingForReview || 0),
                        (workOrdersSummary?.data?.task?.inProgress || 0) +
                          (workOrdersSummary?.data?.task?.pending || 0) +
                          (workOrdersSummary?.data?.task?.waitingForReview ||
                            0),
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="cards-body">
                    <h2 className="stat-headings">
                      {/* {AlectifyText.SKI} */}
                      Skipped Work Orders
                    </h2>
                    <ReactApexChart
                      options={openWorkOrderOptions("PM") as any}
                      series={[
                        workOrdersSummary?.data?.pm?.skipped || 0,
                        workOrdersSummary?.data?.cm?.skipped || 0,
                        workOrdersSummary?.data?.task?.skipped || 0,
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className="cards-body">
                <Row justify={"space-between"} align={"middle"}>
                  <Col span={12}>
                    <h2 className="stat-headings">
                      {AlectifyText.CLOSED_WORK_ORDERS}
                    </h2>
                  </Col>
                  <Col span={12} className="text-align-right">
                    <Space>
                      <Badge text="CM" color="#34B53A" />
                      <Badge text="PM" color="#4339f2" />
                      <Badge text="Task" color="#ffb200" />
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <div className="donut-chart-name">This Week</div>
                    <ReactApexChart
                      options={closedWorkOrderOptions("PM") as any}
                      series={[
                        closedWorkOrders?.pm?.completedThisWeek || 0,
                        closedWorkOrders?.cm?.completedThisWeek || 0,
                        closedWorkOrders?.task?.completedThisWeek || 0,
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </Col>
                  <Col span={8}>
                    <div className="donut-chart-name">This Month</div>
                    <ReactApexChart
                      options={closedWorkOrderOptions("CM") as any}
                      series={[
                        closedWorkOrders?.pm?.completedThisMonth || 0,
                        closedWorkOrders?.cm?.completedThisMonth || 0,
                        closedWorkOrders?.task?.completedThisMonth || 0,
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </Col>
                  <Col span={8}>
                    <div className="donut-chart-name">Since Inception</div>
                    <ReactApexChart
                      options={closedWorkOrderOptions("Task") as any}
                      series={[
                        closedWorkOrders?.pm?.completedSinceInception || 0,
                        closedWorkOrders?.cm?.completedSinceInception || 0,
                        closedWorkOrders?.task?.completedSinceInception || 0,
                      ]}
                      type="donut"
                      width={"100%"}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* <Row gutter={[16, 16]} className="mt-20">
            <Col span={8}>
              <div className="cards-body" onClick={() => openWorkOrdersModal()}>
                <h2 className="stat-headings text-to-link-options">
                  {AlectifyText.WORK_ORDERS_SUMMARY}
                </h2>
                <AlectifyTable
                  className="dashboard-data-table"
                  columns={[
                    { title: "", dataIndex: "value1" },
                    { title: "Open", dataIndex: "open" },
                    { title: "Closed", dataIndex: "closed" },
                    { title: "Skipped", dataIndex: "skipped" },
                  ]}
                  dataSource={[
                    {
                      value1: "PM",
                      open:
                        (workOrdersSummary?.data.pm.pending || 0) +
                        (workOrdersSummary?.data.pm.inProgress || 0) +
                        (workOrdersSummary?.data.pm.waitingForReview || 0),
                      closed: workOrdersSummary?.data.pm.completed || 0,
                      skipped: workOrdersSummary?.data.pm.skipped || 0,
                    },
                    {
                      value1: "CM",
                      open:
                        (workOrdersSummary?.data.cm.pending || 0) +
                        (workOrdersSummary?.data.cm.inProgress || 0) +
                        (workOrdersSummary?.data.cm.waitingForReview || 0),
                      closed: workOrdersSummary?.data.cm.completed || 0,
                      skipped: workOrdersSummary?.data.cm.skipped || 0,
                    },
                    {
                      value1: "Task",
                      open:
                        (workOrdersSummary?.data.task.pending || 0) +
                        (workOrdersSummary?.data.task.inProgress || 0) +
                        (workOrdersSummary?.data.task.waitingForReview || 0),
                      closed: workOrdersSummary?.data.task.completed || 0,
                      skipped: workOrdersSummary?.data.task.skipped || 0,
                    },
                    {
                      value1: "Total",
                      open:
                        (workOrdersSummary?.data.pm.pending || 0) +
                        (workOrdersSummary?.data.pm.inProgress || 0) +
                        (workOrdersSummary?.data.pm.waitingForReview || 0) +
                        (workOrdersSummary?.data.task.pending || 0) +
                        (workOrdersSummary?.data.task.inProgress || 0) +
                        (workOrdersSummary?.data.task.waitingForReview || 0) +
                        (workOrdersSummary?.data.cm.pending || 0) +
                        (workOrdersSummary?.data.cm.inProgress || 0) +
                        (workOrdersSummary?.data.cm.waitingForReview || 0),
                      closed:
                        (workOrdersSummary?.data.pm.completed || 0) +
                        (workOrdersSummary?.data.cm.completed || 0) +
                        (workOrdersSummary?.data.task.completed || 0),
                      skipped:
                        (workOrdersSummary?.data.pm.skipped || 0) +
                        (workOrdersSummary?.data.cm.skipped || 0) +
                        (workOrdersSummary?.data.task.skipped || 0),
                    },
                  ]}
                  showPagination={false}
                  loading={false}
                  onDataFetch={() => {}}
                  total={0}
                />
              </div>
            </Col>
            <Col span={4}>
              <div className="cards-body">
                <h2 className="stat-headings">Open Work Orders</h2>
                <Typography.Paragraph>
                  PM:{" "}
                  {(workOrdersSummary?.data.pm.pending || 0) +
                    (workOrdersSummary?.data.pm.inProgress || 0) +
                    (workOrdersSummary?.data.pm.waitingForReview || 0)}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  CM:{" "}
                  {(workOrdersSummary?.data.cm.pending || 0) +
                    (workOrdersSummary?.data.cm.inProgress || 0) +
                    (workOrdersSummary?.data.cm.waitingForReview || 0)}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Task:{" "}
                  {(workOrdersSummary?.data.task.pending || 0) +
                    (workOrdersSummary?.data.task.inProgress || 0) +
                    (workOrdersSummary?.data.task.waitingForReview || 0)}
                </Typography.Paragraph>
              </div>
            </Col>
            <Col span={12}>
              <div className="cards-body">
                <h2 className="stat-headings">
                  Closed Work Orders (This Week, This Month, Since Inception)
                </h2>
                <Space>
                  <ReactApexChart
                    options={closedWorkOrderOptions("PM") as any}
                    series={[
                      closedWorkOrders?.pm.completedThisWeek || 0,
                      closedWorkOrders?.pm.completedThisMonth || 0,
                      closedWorkOrders?.pm.completedSinceInception || 0,
                    ]}
                    type="donut"
                    width={280}
                  />
                  <ReactApexChart
                    options={closedWorkOrderOptions("CM") as any}
                    series={[
                      closedWorkOrders?.cm.completedThisWeek || 0,
                      closedWorkOrders?.cm.completedThisMonth || 0,
                      closedWorkOrders?.cm.completedSinceInception || 0,
                    ]}
                    type="donut"
                    width={280}
                  />
                  <ReactApexChart
                    options={closedWorkOrderOptions("Task") as any}
                    series={[
                      closedWorkOrders?.task.completedThisWeek || 0,
                      closedWorkOrders?.task.completedThisMonth || 0,
                      closedWorkOrders?.task.completedSinceInception || 0,
                    ]}
                    type="donut"
                    width={280}
                  />
                </Space>
              </div>
            </Col>
          </Row> */}
          <h1>Work Orders By Assignee</h1>
          <Row className="mb-40 mt-40" gutter={16}>
            <Col span={12}>
              <div
                className="cards-body"
                onClick={() => {
                  ModalServiceInstance.open(AlectifyModal, {
                    title:
                      AlectifyText.SCHEDULED_WORK_ORDERS_STATUS_BY_ASSIGNEE,
                    name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
                    width: 1400,
                    footer: null,
                    className: "alectify-pdf-viewer-container",
                    onCancel: () => {
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
                      });
                    },
                    children: (
                      <DashboardWorkOrders
                        type="BY_ASSIGNEES"
                        defaultParams={{
                          status: [PM_STATUS.PENDING],
                          assignees: [
                            overdueWorkOrderDetails?.data.openItems?.[0]?.id,
                          ],
                        }}
                        filters={{
                          key: "assignees",
                          options: overdueWorkOrderDetails?.data.openItems?.map(
                            (user) => ({
                              label: getFullUserName(user),
                              value: user.id,
                            }),
                          ),
                        }}
                      />
                    ),
                  });
                }}
              >
                <h2 className="stat-headings-clickable">
                  {/* {AlectifyText.SCHEDULED_WORK_ORDERS_STATUS_BY_ASSIGNEE} */}
                  Open Work Orders On Time vs Delayed (Top 10)
                </h2>
                <div>
                  <AlectifyBarChart
                    options={overDueWorkOrdersOptions(
                      overdueWorkOrderDetails?.data,
                    )}
                    series={overDueWorkOrdersSeries(
                      overdueWorkOrderDetails?.data.openItems,
                    )}
                    type="bar"
                    height={330}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                className="cards-body"
                onClick={() => openWorkOrdersModal({}, PM_STATUS.COMPLETED)}
              >
                <h2 className="stat-headings-clickable">
                  {/* {AlectifyText.WORK_ORDERS_SUMMARY} */}
                  Closed Work Orders
                </h2>
                <AlectifyTable
                  bordered={false}
                  columns={[
                    {
                      title: "User",
                      dataIndex: "completedBy",
                      render: (_, record) =>
                        getFullUserName(record.completedBy),
                    },
                    { title: "This Week", dataIndex: "completedThisWeek" },
                    { title: "This Month", dataIndex: "completedThisMonth" },
                    {
                      title: "Since Inception",
                      dataIndex: "completedSinceInception",
                    },
                  ]}
                  dataSource={closedWorkOrdersByAssignees || []}
                  loading={false}
                  onDataFetch={() => {}}
                  total={closedWorkOrdersByAssignees?.length || 0}
                  showPagination={false}
                />
              </div>
            </Col>
          </Row>
          {/* {config.REACT_APP_ENV !== AppEnvEnum.PRODUCTION && ( */}
          <h1>Spare Parts</h1>
          <Row gutter={30} className="mb-30">
            <Col span={10}>
              <div className="cards-body" onClick={() => openSparePartsModal()}>
                <h2 className="stat-headings-clickable">
                  Spare Parts as of Today
                </h2>
                <Card hoverable={false} className="spare-parts-stats-card">
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title># Low Inventory</Typography.Title>
                    <Typography.Text className="spare-parts-stats-card-value">
                      {sparePartsDashboardStats?.lowInventoryCount || 0}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title># Out of Stock</Typography.Title>
                    <Typography.Text>
                      {sparePartsDashboardStats?.outOfStockCount || 0}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      Available Spare Parts Counts
                    </Typography.Title>
                    <Typography.Text>
                      {sparePartsDashboardStats?.totalCount || 0}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      # Available Spart Parts Value
                    </Typography.Title>
                    <Typography.Text>
                      {renderAmount(
                        sparePartsDashboardStats?.currentYearCost || 0,
                      )}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      Total Receive $$ (in 2024)
                    </Typography.Title>
                    <Typography.Text>
                      {renderAmount(
                        sparePartsDashboardMonthlyCost?.reduce(
                          (accum, item) => accum + (item.restockTotal || 0),
                          0,
                        ),
                      )}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      # Total Draw $$ (in 2024)
                    </Typography.Title>
                    <Typography.Text>
                      {renderAmount(
                        sparePartsDashboardMonthlyCost?.reduce(
                          (accum, item) => accum + (item.drawTotal || 0),
                          0,
                        ),
                      )}
                    </Typography.Text>
                  </Card.Grid>
                </Card>
              </div>
            </Col>
            <Col span={14}>
              <div className="cards-body">
                <h2 className="stat-headings-v2">
                  Spare Parts Utilization (in 2024)
                </h2>
                {!isEmpty(sparePartsDashboardMonthlyCost) && (
                  <ReactApexChart
                    options={
                      getSparePartsUtilizationChartOptions(
                        sparePartsDashboardMonthlyCost as any,
                      ) as any
                    }
                    series={getSparePartsUtilizationChartSeries(
                      sparePartsDashboardMonthlyCost as any,
                    )}
                    type="line"
                    height={350}
                  />
                )}
                {/* <ReactApexChart
                  options={{
                    // markers: {
                    //   size: [6, 6],
                    //   shape: "circle",
                    // },
                    chart: {
                      height: 350,
                      type: "line",
                    },
                    stroke: {
                      width: [2, 2],
                    },
                    dataLabels: {
                      textAnchor: "middle",
                      enabled: true,
                      formatter: (val) => `${renderAmount(val)}`,
                    },
                    labels: sparePartsDashboardMonthlyCost?.map((i) => i.month),
                    yaxis: {
                      title: {
                        text: "CAD",
                      },
                      labels: {
                        formatter: (val) => `${renderAmount(val)}`,
                      },
                    },
                    legend: {
                      position: "top",
                    },
                    colors: ["#00DD23", "#3964FF"],
                  }}
                  series={[
                    {
                      name: "Spare Parts Added (Received)",
                      type: "column",
                      data: [
                        ...(sparePartsDashboardMonthlyCost || []).map(
                          (i) => i.restockTotal,
                        ),
                      ],
                    },
                    {
                      name: "Spare Parts Widthdrawn (Draw)",
                      type: "column",
                      data: [
                        ...(sparePartsDashboardMonthlyCost || []).map(
                          (i) => i.drawTotal,
                        ),
                      ],
                    },
                    // {
                    //   name: "Available Spare Parts",
                    //   type: "line",
                    //   data: [0, 0, 0, 0],
                    // },
                  ]}
                  type="line"
                  height={350}
                /> */}
              </div>
            </Col>
          </Row>
          {/* )} */}
        </Spin>
      ) : (
        <Spin spinning={pmState.loading}>
          <Row gutter={16} className="mt-20">
            <Col span={12}>
              <div className="cards-body-external">
                <h2 className="stat-headings">
                  {AlectifyText.OPEN_WORK_ORDERS}
                </h2>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CountInColCard
                      title={AlectifyText.PM_EXTERNAL}
                      count={
                        (pmState?.pmCounts?.pmExternal?.pending || 0) +
                        (pmState?.pmCounts?.pmExternal?.waitingForReview || 0)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CountInColCard
                      title={AlectifyText.TASKS}
                      count={
                        (pmState?.pmCounts?.task?.pending || 0) +
                        (pmState?.pmCounts?.task?.waitingForReview || 0)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={12}>
              <div className="cards-body-external">
                <h2 className="stat-headings">
                  {AlectifyText.OPEN_WORK_ORDERS_STATISTICS}
                </h2>

                <AlectifyBarChart
                  type="bar"
                  height={330}
                  options={openWorkOrdersStatisticsOptions()}
                  series={openWorkOrdersStatisticsSeriesExternal(pmState)}
                />
              </div>
            </Col>
          </Row>
        </Spin>
      )}
      {/* <h1>Assets</h1>
      <div className="cards-body-external">
        <h2 className="stat-headings">Most Problematic Asset</h2>
      </div> */}
    </div>
  );
};

export default DashboardStatistics;
