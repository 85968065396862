import { Checkbox, Col, Form, Radio, Row, Spin, message } from "antd";
import { IAddCheckpointManualFormProps } from "./AddCheckpointManualForm.interface";
import { MESSAGES } from "constants/messages";
import AlectifyText from "static/texts.json";
import { useEffect, useState } from "react";
import { RadioChangeEvent } from "antd/lib";
import AlectifyInput from "../input";
import AlectifyButton from "../button";
import {
  IDrCheckpoint,
  IDrGroup,
} from "services/data-round/data-round.interface";
import {
  createCheckpoint,
  createNewGroup,
  updateCheckpoint,
  updateGroup,
} from "services/data-round/data-round.service";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import GroupTable from "../group-table/GroupTable";
import { MODAL_NAMES } from "constants/modal.constants";
import { isEmpty, truncate } from "lodash";

const AddCheckpointManualForm: React.FC<IAddCheckpointManualFormProps> = (
  props: IAddCheckpointManualFormProps,
) => {
  const [groupActive, setgroupActive] = useState<string | null>("group");
  const [selectedArea, setSelectedArea] = useState<IDrGroup | null>(null);

  const [loader, setLoader] = useState<boolean>(false);

  const onChangeGroupOrCheckPoint = (e: RadioChangeEvent) => {
    setgroupActive(e.target.value);
  };

  const onSubmit = async (values: IDrGroup | IDrCheckpoint) => {
    try {
      setLoader(true);
      if ("is_reading_required" in values && "on_off_feature" in values) {
        const payloadForCheckpoint: IDrCheckpoint = {
          name: values.name,
          item_no: values.item_no,
          description: values.description,
          is_reading_required: values.is_reading_required,
          on_off_feature: values.on_off_feature,
          area: selectedArea?.area_id || "",
          tag_id: values.tag_id,
          package_id: values.package_id,
        };
        let response: any;
        if (!props.isEditing) {
          response = await createCheckpoint(
            props.projectId,
            payloadForCheckpoint,
          );
        } else {
          response = await updateCheckpoint(
            props.projectId || "",
            props.data?.checkpoint_id || "",
            payloadForCheckpoint,
          );
        }

        setLoader(false);
        props.onClose();
        if (response.success) {
          message.success(
            MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES
              .NEW_CHECKPOINT_UPDATED,
          );
        }
      } else {
        const payloadForGroup: IDrGroup = {
          name: values.name,
          item_no: Number(values.item_no),
          description: values.description,
        };
        let response: any;
        if (!props.isEditing) {
          response = await createNewGroup(props.projectId, payloadForGroup);
        } else {
          response = await updateGroup(
            props.projectId || "",
            props.data?.area_id || "",
            payloadForGroup,
          );
        }
        props.onClose();
        if (response.success) {
          setLoader(false);
          message.success(
            MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES.NEW_GROUP_UPDATED,
          );
        }
      }
    } catch (error) {
      setLoader(false);
      console.log("ERRO ", error);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  const equipmentModalHandler = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1200,
      name: MODAL_NAMES.SELECT_GROUP_MODAL,
      title: AlectifyText.SELECT_GROUP,
      children: (
        <GroupTable
          showSearch
          selectRowOption
          //   rowSelection
          projectId={props.projectId || ""}
          showAction
          onRowSelect={(record: IDrGroup) => {
            props.form && props.form.setFieldValue("area", record.area_id);
            setSelectedArea(record);
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.SELECT_GROUP_MODAL,
            });
          }}
        />
      ),
      footer: null,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.SELECT_GROUP_MODAL,
        });
      },
    });
  };

  const getDetails = async () => {
    try {
      if (props.type === "Area") {
        setgroupActive("group");
      } else {
        props.form.setFieldValue("type", "checkpoint");
        setgroupActive("checkpoint");
      }
      props.form.setFieldsValue({
        name:
          props.type === "Area"
            ? props.data?.area_name
            : props.data?.checkpoint_name,
        item_no: props.data?.item_no,
        description: props.data?.description,
        is_reading_required:
          props.type !== "Area" ? props.data?.is_reading_required : undefined,
        on_off_feature:
          props.type !== "Area" ? props.data?.on_off_feature : undefined,
        area: props.type !== "Area" ? props.data?.area_id : undefined,
      });
      setSelectedArea({
        item_no: 0,
        area_id: props.data?.area_id || "",
        name: props.data?.area_name || "",
        area_name: props.data?.area_name || "",
        description: props.data?.description || "",
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (props.type) {
      getDetails();
    }
  }, [props.projectId]);

  return (
    <Spin spinning={loader}>
      <Form
        form={props.form}
        layout="vertical"
        onFinish={onSubmit}
        className="alectify-manage-asset-form-container"
        initialValues={{ type: groupActive }}
      >
        <Row justify="start" gutter={20}>
          <Col span={24}>
            <h4 className="background-color-light-grey p-5 mb-15 mt-0">
              Detail
            </h4>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message:
                    MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES.PLEASE_SELECT,
                },
              ]}
            >
              <Radio.Group
                onChange={onChangeGroupOrCheckPoint}
                value={groupActive}
                disabled={props.isEditing}
                className="alectify-manage-asset-form-radio-buttons"
              >
                <Radio value={"group"} checked={groupActive === "group"}>
                  {AlectifyText.ADD_GROUP}
                </Radio>
                <Radio
                  value={"checkpoint"}
                  checked={groupActive === "checkpoint"}
                >
                  {" "}
                  {AlectifyText.ADD_CHECKPOINT}{" "}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {groupActive === "group" ? (
            <>
              <Col span={24}>
                <AlectifyInput
                  name="item_no"
                  type="text"
                  placeholder={MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    "Item No#",
                  )}
                  label="Item No#"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        "Item No#",
                      ),
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <AlectifyInput
                  name="name"
                  type="text"
                  placeholder={MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    "Name",
                  )}
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        "Name",
                      ),
                    },
                  ]}
                />
              </Col>
              {/* <Col span={24}>
                <AlectifyInput
                  name="description"
                  type={"textarea"}
                  label={"Description"}
                  rows={4}
                  resizeable
                />
              </Col> */}
            </>
          ) : (
            <>
              <Col span={24} className="p-0">
                <Form.Item
                  name="area"
                  label={AlectifyText.SELECT_GROUP}
                  rules={[
                    {
                      required: true,
                      message:
                        MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES
                          .PLEASE_SELECT_GROUP,
                    },
                  ]}
                >
                  <AlectifyButton
                    text={
                      (!isEmpty(selectedArea) && selectedArea?.area_name) ||
                      AlectifyText.SELECT_GROUP
                    }
                    type="primary"
                    htmlType="button"
                    className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                    onClick={equipmentModalHandler}
                    block
                  />
                </Form.Item>
              </Col>
              <Row justify={"start"} align={"middle"} gutter={20}>
                <Col span={24}>
                  <AlectifyInput
                    name="item_no"
                    type="text"
                    placeholder={MESSAGES.FIELD_RULES.REQUIRED.replace(
                      "{fieldName}",
                      "Item No#",
                    )}
                    label="Item No#"
                    rules={[
                      {
                        required: true,
                        message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                          "{fieldName}",
                          "Item No#",
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <AlectifyInput
                    name="name"
                    type="text"
                    placeholder={MESSAGES.FIELD_RULES.REQUIRED.replace(
                      "{fieldName}",
                      "Name",
                    )}
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                          "{fieldName}",
                          "Name",
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <AlectifyInput
                    name="description"
                    type={"textarea"}
                    placeholder={MESSAGES.FIELD_RULES.REQUIRED.replace(
                      "{fieldName}",
                      "Operating Range",
                    )}
                    label="Operating Range"
                    rows={4}
                    rules={[
                      {
                        required: true,
                        message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                          "{fieldName}",
                          "Operating Range",
                        ),
                      },
                    ]}
                    resizeable
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"is_reading_required"}
                    valuePropName="checked"
                  >
                    <Checkbox>{AlectifyText.READING_REQUIRED}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={"on_off_feature"} valuePropName="checked">
                    <Checkbox>{AlectifyText.DEVICE_STATUS_REQUIRED}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Row>
      </Form>
    </Spin>
  );
};

AddCheckpointManualForm.defaultProps = {
  isEditing: false,
};
export default AddCheckpointManualForm;
