import { ICalendarFiltersProps } from "./Calendar.interface";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";
import { fetchDropdownMasterProjects } from "services/master-project/masterProject.service";
import { IMasterProject } from "redux/components/master-project";
import { isEmpty } from "lodash";
import EmptyIcon from "components/icons/EmptyIcon";
import "./Calendar.scss";

const CalendarFilters: React.FC<ICalendarFiltersProps> = (
  props: ICalendarFiltersProps,
) => {
  const { activeMasterProject, showCalendarMyWorkOrders } = useSelector(
    (state: IRootState) => state.common,
  );
  const [masterProjects, setMasterProjects] = useState<IMasterProject[]>([]);

  const getMasterProjects = async () => {
    try {
      const params: any = {};
      if (!showCalendarMyWorkOrders && showCalendarMyWorkOrders !== undefined) {
        params.filter_by = "user_branch";
      }
      const response = await fetchDropdownMasterProjects(params);
      setMasterProjects([...(response.data as any)]);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getMasterProjects();
    props.FormInstance.resetFields();
  }, [activeMasterProject?.id]);
  const activeElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeMasterProject?.id && activeElementRef.current) {
      activeElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeMasterProject?.id, masterProjects]);
  return (
    <>
      <div className="masterproject-options">
        {isEmpty(activeMasterProject) ? (
          masterProjects.length ? (
            masterProjects.map((masterProject) => (
              <div
                key={masterProject.id}
                className="masterproject-option"
                id={masterProject.id}
              >
                <div
                  className="masterproject-circle-color"
                  style={{ backgroundColor: masterProject.color }}
                />
                <div>{masterProject?.name || AlectifyText.ALL_SITES}</div>
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center align-item-center">
              <EmptyIcon />
            </div>
          )
        ) : (
          <div
            key={activeMasterProject.id}
            className="masterproject-option active"
            id={activeMasterProject.id}
          >
            <div
              className="masterproject-circle-color"
              style={{ backgroundColor: activeMasterProject.color }}
            />
            <div>{activeMasterProject?.name || AlectifyText.ALL_SITES}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarFilters;
