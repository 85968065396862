//libs
import React, { useEffect, useState } from "react";
import { get, truncate } from "lodash";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import {
  Document,
  Page,
  View,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";

//components
import PmExternalIconPDF from "components/icons/pdf-icons/PmExternalIconPdf";
import PmInternalIconPdf from "components/icons/pdf-icons/PmInternalIconPdf";
import TaskIconPDF from "components/icons/pdf-icons/TaskIconPdf";
import EventDelayedIconPdf from "components/icons/pdf-icons/EventDelayedIconPdf";
import EventPendingIconPdf from "components/icons/pdf-icons/EventPendingIconPdf";
import EventCompletedIconPdf from "components/icons/pdf-icons/EventCompletedIconPdf";
import EventCompletedPastDueDateIconPdf from "components/icons/pdf-icons/EventCompletedPastDueDateIconPdf";
import EventSkippedIconPdf from "components/icons/pdf-icons/EventSkippedIconPdf";
import AlectifyButton from "components/shared/button";

//helpers, interfaces & enums
import { formatDate } from "utils/helpers";
import { CalendarEventStatusEnum, CalendarEventTypesEnum } from "enums";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import { styles } from "./CalendarPdfStyles";
import { ICalendarTemplateProps } from "./CalendarPdf.interface";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";

const EVENT_ICONS_PDF = {
  [CalendarEventTypesEnum.TASKS]: <TaskIconPDF />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIconPDF />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIconPdf />,
};
const EVENT_STATUS_ICONS = {
  [CalendarEventStatusEnum.DELAYED]: <EventDelayedIconPdf />,
  [CalendarEventStatusEnum.PENDING]: <EventPendingIconPdf />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <EventCompletedIconPdf />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <EventCompletedPastDueDateIconPdf />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <EventSkippedIconPdf />,
};

const EVENT_BORDER_COLOR: any = {
  [CalendarEventStatusEnum.DELAYED]: "#d92323",
  [CalendarEventStatusEnum.PENDING]: "#3e74dd",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.SKIPPED]: "#d7d7d7",
};

const WeeklyCalendarTemplate: React.FC<ICalendarTemplateProps> = (
  props: ICalendarTemplateProps,
) => {
  const { data } = props.calendarEvents;
  const { user } = useSelector((state: IRootState) => state.auth);

  const [columns, setColumns] = useState<{ [key: string]: ICalendarEvent[] }>(
    {},
  );

  useEffect(() => {
    const startOfWeek = new Date(props.startDate);
    const endOfWeek = new Date(props.endDate);

    const newColumns: { [key: string]: ICalendarEvent[] } = {};

    for (
      let date = new Date(startOfWeek);
      date <= endOfWeek;
      date.setDate(date.getDate() + 1)
    ) {
      const key = date.toISOString().slice(0, 10); // Using date as the key for the columns object
      newColumns[key] = data?.filter((event) => {
        const eventStart = moment(event.start).format();
        const eventEnd = moment(event.end).format();
        return (
          eventStart.slice(0, 10) <= key && key <= eventEnd.slice(0, 10) // Event falls within the date
        );
      }) as any;
    }

    setColumns(newColumns);
  }, [data, props.startDate, props.endDate]);

  return (
    <>
      <PDFDownloadLink
        document={
          <Document>
            <Page key={"introPage"} orientation="landscape">
              <View style={styles.titlePageContainer}>
                <View>
                  <Text style={styles.titlePageHeading}>
                    {AlectifyText.WEEKLY_WORK_ORDERS}
                  </Text>
                </View>
                <View>
                  <Text style={styles.titlePageSubHeading}>
                    {moment(props.startDate).format("DD MMM")} -{" "}
                    {moment(props.endDate).format("DD MMM, YYYY")}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{ ...styles.titlePageSubHeading, marginTop: 10 }}
                  >
                    Generated by: {`${user?.first_name} ${user?.last_name}`}
                  </Text>
                </View>
                <View>
                  <Text style={{ ...styles.titlePageSubHeading }}>
                    {moment().format("MMMM DD, YYYY, h:mm A")}
                  </Text>
                </View>
              </View>
            </Page>
            <Page orientation="landscape">
              <View style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <View style={styles.legendDateHeading}>
                    <Text>
                      {moment(props.startDate).format("DD MMM")} -{" "}
                      {moment(props.endDate).format("DD MMM, YYYY")}
                    </Text>
                  </View>
                  <View style={styles.legengContainer}>
                    <View style={styles.legend}>
                      <EventPendingIconPdf />{" "}
                      <Text style={{ marginLeft: 5 }}>
                        Open (Future Due Date)
                      </Text>
                    </View>
                    <View style={styles.legend}>
                      <EventDelayedIconPdf />{" "}
                      <Text style={{ marginLeft: 5 }}>Open (Over Due)</Text>
                    </View>
                    <View style={styles.legend}>
                      <EventCompletedIconPdf />{" "}
                      <Text style={{ marginLeft: 5 }}>Closed (On Time)</Text>
                    </View>
                    <View style={styles.legend}>
                      <EventCompletedPastDueDateIconPdf />{" "}
                      <Text style={{ marginLeft: 5 }}>
                        Closed (Due Date Passed)
                      </Text>
                    </View>
                    <View style={styles.legend}>
                      <EventSkippedIconPdf />{" "}
                      <Text style={{ marginLeft: 5 }}>Skipped</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.row}>
                  {Object.keys(columns).map((date, index) => (
                    <View style={styles.column} key={`${date}-${index}`}>
                      <View key={index} style={styles.header}>
                        <Text style={styles.rowChild}>
                          {formatDate(dayjs(date), "dddd, DD MMM")}
                        </Text>
                      </View>
                      <View>
                        {columns[date]?.map((event) => (
                          <View
                            key={index}
                            style={{
                              ...styles.event,
                              backgroundColor: event.master_project[0].color,
                            }}
                          >
                            <View
                              style={{
                                ...styles.eventIcon,
                                borderColor:
                                  EVENT_BORDER_COLOR[event.event_status],
                              }}
                            >
                              {
                                EVENT_ICONS_PDF[
                                  event.type as CalendarEventTypesEnum
                                ]
                              }
                              <View style={styles.eventStatusIcon}>
                                {
                                  EVENT_STATUS_ICONS[
                                    event.event_status as CalendarEventStatusEnum
                                  ]
                                }
                              </View>
                            </View>
                            <View style={styles.eventContent}>
                              <Text style={styles.eventTitle}>
                                {truncate(event.title, {
                                  length: 25,
                                  omission: "...",
                                }) || "NA"}
                              </Text>
                              <Text style={styles.eventDescription}>
                                {`${truncate(
                                  get(event, "asset[0].name")
                                    ? get(event, "asset[0].name")
                                    : get(event, "asset_package[0].name"),
                                  {
                                    length: 20,
                                    omission: "...",
                                  },
                                )}`}
                              </Text>
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </Page>
          </Document>
        }
        fileName={`calendar-${moment().format("YYYY-MM-DD").toString()}.pdf`}
      >
        {({ loading }) => (
          <AlectifyButton
            name="download-as-pdf"
            text={`${loading ? "Loading Document...." : "Weekly Pdf"}`}
            disabled={loading}
            icon={<CloudDownloadOutlined />}
            type="primary"
          />
        )}
      </PDFDownloadLink>
    </>
  );
};

export default WeeklyCalendarTemplate;
