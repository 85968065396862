import React from "react";
import EmptyIcon from "components/icons/EmptyIcon";

const AlectifyEmpty: React.FC<IAlectifyEmptyProps> = ({ description }) => {
  return (
    <div className="text-align-center">
      <EmptyIcon />

      <p className="color-light-grey">{description}</p>
    </div>
  );
};

export default AlectifyEmpty;

export interface IAlectifyEmptyProps {
  description: string;
}
