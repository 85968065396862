import moment from "moment";
import { Col, Row, Space, Spin, Typography } from "antd";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  ICalendarEvent,
  IPmCalendarEventResponse,
} from "services/calendar/calendar.interface";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import { ICalendarEventState, ICalendarFilters } from "./Calendar.interface";
import CalendarEvent from "./CalendarEvent";
import CalendarToolbar from "./CalendarToolbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import WeeklyCalendarTemplate from "./calendar-pdf/WeeklyCalendarTemplate";
import CalendarMonthlyTemplate from "./calendar-pdf/MonthlyCalendarTemplate";
import AlectifyButton from "components/shared/button";
import { CloudDownloadOutlined } from "@ant-design/icons";
import AlectifyText from "static/texts.json";
import { truncate } from "lodash";
import "./Calendar.scss";

const WeeklyCalendar: React.FC<ICalendarFilters> = (
  props: ICalendarFilters,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const startDateQ = new URLSearchParams(location.search).get("start") || "";
  const endDateQ = new URLSearchParams(location.search).get("end") || "";
  const localizer = momentLocalizer(moment);
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEventState>({
    data: [],
    fetching: false,
  });
  const [calendarEventMonthly, setCalendarEventMonthly] =
    useState<ICalendarEventState>({
      data: [],
      fetching: false,
    });
  const { common } = useSelector((state: IRootState) => state);

  const selectedMonthStartDate = moment({
    year: props.calendarDates?.year,
    month: Number(props.calendarDates?.month) - 1,
  });

  const getCalendarEvent = async (monthly: boolean) => {
    try {
      let pmEvents: IPmCalendarEventResponse;
      if (!monthly) {
        setCalendarEvents({ fetching: true });
      } else {
        setCalendarEventMonthly({ fetching: true });
      }
      if (
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
      ) {
        [pmEvents] = await Promise.all([
          fetchPmCalendarEvents(
            common.activeMasterProject?.id ?? "all",
            props.subProject?.id ?? "all",
            !monthly ? startDateQ : selectedMonthStartDate.format("YYYY-MM-DD"),
            !monthly
              ? endDateQ
              : selectedMonthStartDate
                  .clone()
                  .endOf("month")
                  .format("YYYY-MM-DD"),
          ),
        ]);
        if (!monthly) {
          setCalendarEvents({
            fetching: false,
            data: [...pmEvents.data],
          });
        } else {
          setCalendarEventMonthly({
            fetching: false,
            data: [...pmEvents.data],
          });
        }
      } else {
        pmEvents = await fetchPmCalendarEvents(
          common.activeMasterProject?.id ?? "all",
          props.subProject?.id ?? "all",
          !monthly ? startDateQ : selectedMonthStartDate.format("YYYY-MM-DD"),
          !monthly
            ? endDateQ
            : selectedMonthStartDate
                .clone()
                .endOf("month")
                .format("YYYY-MM-DD"),
          { global: "true" },
        );
        if (!monthly) {
          setCalendarEvents({
            fetching: false,
            data: [...pmEvents.data],
          });
        } else {
          setCalendarEventMonthly({
            fetching: false,
            data: [...pmEvents.data],
          });
        }
      }
    } catch (error) {
      setCalendarEvents({ fetching: false });
    }
  };

  const dayStyleGetter = (date: Date) => {
    const formattedSelectedDate = new Date(
      props?.selectedDate && props?.selectedDate,
    );
    const isSelected = moment(date).isSame(formattedSelectedDate, "day");
    return {
      style: {
        backgroundColor: isSelected ? "#e8f1fd" : "transparent",
      },
    };
  };

  useEffect(() => {
    if (startDateQ && endDateQ) {
      getCalendarEvent(false);
    }
  }, [
    props.masterProject,
    props.subProject,
    startDateQ,
    endDateQ,
    common.showCalendarMyWorkOrders,
    common.activeMasterProject?.id,
  ]);

  /*  const onButtonChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setShowCalendarMyWorkOrders(value));
  }; */

  return (
    <Spin spinning={calendarEvents.fetching}>
      <Row>
        <Col span={12}>
          <Typography.Title level={4} className="m-0">
            {common.showCalendarMyWorkOrders
              ? "All Work Orders"
              : `${
                  common.activeMasterProject
                    ? truncate(common?.activeMasterProject?.name, {
                        length: 30,
                        omission: "...",
                      })
                    : AlectifyText.ALL_SITES
                } ${AlectifyText.READ_ONLY}`}
          </Typography.Title>
        </Col>
        <Col span={12} className="text-align-right">
          <Space className="mb-5">
            <WeeklyCalendarTemplate
              startDate={startDateQ}
              endDate={endDateQ}
              calendarEvents={calendarEvents}
            />
            <AlectifyButton
              name="download-as-pdf"
              text={`Monthly Pdf`}
              icon={<CloudDownloadOutlined />}
              onClick={() => getCalendarEvent(true)}
              type="primary"
              loading={calendarEventMonthly.fetching}
              disabled={calendarEventMonthly.fetching}
            />
            <CalendarMonthlyTemplate
              startDate={selectedMonthStartDate.format("YYYY-MM-DD")}
              endDate={selectedMonthStartDate
                .clone()
                .endOf("month")
                .format("YYYY-MM-DD")}
              calendarEvents={calendarEventMonthly}
            />
          </Space>
        </Col>

        {/* <Col span={12} className="text-align-right">
          <Space className="alectify-work-order-tabs">
            <Radio.Group
              options={CALENDAR_TOGGLE_OPTIONS}
              optionType="button"
              buttonStyle="solid"
              onChange={onButtonChange}
              value={common.showCalendarMyWorkOrders}
            />
          </Space>
        </Col> */}
      </Row>
      <Calendar
        dayPropGetter={dayStyleGetter}
        formats={{
          dayRangeHeaderFormat: ({ start, end }, culture, local: any) =>
            local.format(start, "DD MMM", culture) +
            " – " +
            local.format(
              end,
              local.eq(start, end, "month") ? "DD MMM, YYYY" : "DD MMM, YYYY",
              culture,
            ),
        }}
        localizer={localizer}
        events={calendarEvents.data?.map((event: ICalendarEvent) => ({
          ...event,
          start: moment(event.start).format() as any,
          end: moment(event.end).format() as any,
          allDay: true,
        }))}
        startAccessor="start"
        endAccessor="end"
        views={["week"]}
        defaultView="week"
        date={startDateQ ? moment(startDateQ).format() : moment().format()}
        onNavigate={() => {}}
        defaultDate={moment().format()}
        components={{
          week: {
            header: ({ date, localizer }: any) =>
              localizer.format(date, "ddd, D"),
          },
          toolbar: (props: any) => {
            return <CalendarToolbar {...props} />;
          },
          eventWrapper: (data: { event: ICalendarEvent }) => (
            <CalendarEvent {...data.event} />
          ),
        }}
        showAllEvents
        onRangeChange={(range: any) => {
          const start = moment(range[0]).format("YYYY-MM-DD");
          const end = moment(range[range.length - 1]).format("YYYY-MM-DD");
          navigate({ search: `start=${start}&end=${end}` });
          props?.setCalendarState &&
            props?.setCalendarState(() => ({
              selectedDate: start,
              isSelectActive: false,
            }));
        }}
      />
    </Spin>
  );
};

export default memo(WeeklyCalendar);
