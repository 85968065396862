import axios, { AxiosResponse } from "axios";
import { http, MULTIPART_HEADERS, pmHttp } from "utils/client";
import { IParams } from "redux/interfaces";
import saveAs from "file-saver";

import {
  DELETE_ASSET_DOCUMENT,
  DELETE_ASSET_PACKAGE_DOCUMENT,
  DOWNLOAD_ZIP_FILES,
  GET_ALL_DOCUMENTS,
  GET_ASSET_DOCUMENTS,
  GET_ASSET_DOCUMENTS_COUNT,
  GET_ASSET_PACKAGE_DOCUMENTS,
  GET_DOCUMENTS_COUNT,
  GET_FILES_SIGNED_URLS,
  GET_PROJECT_DOCUMENTS,
  RESTORE_ASSET_DOCUMENT,
  RESTORE_ASSET_PACKAGE_DOCUMENT,
  RESTORE_PROJECT_DOCUMENT,
  UPDATE_ASSET_DOCUMENT,
  UPDATE_ASSET_PACKAGE_DOCUMENT,
  UPDATE_PROJECT_DOCUMENT,
  UPLOAD_ASSET_DOCUMENT,
} from "./documents.endpoints";
import {
  actions,
  IAssetDocumentResponse,
  IAssetPackageDocumentResponse,
  IDocumentsCountResponse,
  IProjectDocumentResponse,
} from "redux/components/documents";
import { message } from "antd";
import { MESSAGES } from "constants/messages";
import { extractFileNameFromSignedURL } from "utils/helpers";
import store from "redux/store/store";

/**
 * Fetches project documents for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project assets data.
 * @throws If an error occurs during the request.
 */
export const fetchProjectDocuments = async (
  subProjectId: string,
  params?: IParams, // Make params optional
): Promise<IProjectDocumentResponse> => {
  try {
    const url = GET_PROJECT_DOCUMENTS.replace("{subProjectId}", subProjectId);
    const response: AxiosResponse<IProjectDocumentResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches asset documents for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project assets data.
 * @throws If an error occurs during the request.
 */
export const fetchAssetAndPackageDocuments = async (
  assetId: string,
  params?: IParams, // Make params optional
): Promise<IAssetDocumentResponse> => {
  try {
    const url = GET_ASSET_DOCUMENTS.replace("{assetId}", assetId);
    const response: AxiosResponse<IAssetDocumentResponse> = await pmHttp.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches asset packages documents for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project assets data.
 * @throws If an error occurs during the request.
 */
export const fetchAssetPackagesDocuments = async (
  subProjectId: string,
  params?: IParams, // Make params optional
): Promise<IAssetPackageDocumentResponse> => {
  try {
    const url = GET_ASSET_PACKAGE_DOCUMENTS.replace(
      "{subProjectId}",
      subProjectId,
    );
    const response: AxiosResponse<IAssetPackageDocumentResponse> =
      await http.get(url, params);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Download documents in a zip.
 *
 * @param keys - List of s3 keys.
 * @returns A Promise that resolves to download the documents in zip.
 * @throws If an error occurs during the request.
 */
export const downloadDocumentsInZip = async (keys: string[]) => {
  try {
    message.loading(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_INPROGRESS);
    const response = await http.postWithResponseType(
      DOWNLOAD_ZIP_FILES,
      { s3_keys: keys },
      {},
      "arraybuffer",
    );
    if (response.data) {
      const zipBlob = new Blob([response.data], { type: "application/zip" });
      saveAs(zipBlob, "file.zip");
      message.success(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_SUCCESS);
    } else {
      message.error(MESSAGES.FILE_DOWNLOAD_MESSAGES.NO_RESPONSE_RETURNED);
    }
  } catch (error: any) {
    message.error(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_ERROR);
  }
};

/**
 * Retrieves a signed URL for the specified file key, allowing secure access to the file.
 *
 * @param {string} key - The unique identifier or key of the file.
 * @returns {Promise<any>} - A promise that resolves with the signed URL for secure file access.
 */
export const getSignedUrl = async (key: string) => {
  try {
    const response = await http.post(GET_FILES_SIGNED_URLS, { key });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Downloads a single file from the specified file path with a custom name.
 *
 * @param {string} file_path - The path to the file to be downloaded.
 * @param {string} file_name - The custom name to be used for the downloaded file.
 */
export const downloadSingleFile = async (
  file_path: string,
  file_name: string,
) => {
  let percentCompleted = 0;
  const messageKey = "downloadProgress";

  try {
    store.dispatch(actions.setDocumentsLoader(true));
    const response = await getSignedUrl(file_path);

    // Initialize the loading message
    message.open({
      key: messageKey,
      content: `${MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_INPROGRESS} 0%`,
      duration: 0,
    });

    const intervalId = setInterval(() => {
      // Update the loading message with current progress
      message.open({
        key: messageKey,
        content: `${MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_INPROGRESS} ${percentCompleted}%`,
        duration: 0,
      });
    }, 500); // Update every 500ms

    const fileResponse = await axios.get(response.data, {
      responseType: "blob", // Ensure the response is treated as a binary file
      headers: { "Content-Disposition": "attachment" },
      onDownloadProgress: (progressEvent) => {
        if (progressEvent.total) {
          percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        }
      },
    });

    clearInterval(intervalId); // Clear the interval once download is complete

    // Use the file data from the response to save the file
    saveAs(fileResponse.data, file_name);

    message.success({
      key: messageKey,
      content: MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_SUCCESS,
    });
  } catch (ex: any) {
    store.dispatch(actions.setDocumentsLoader(false));
    if (ex?.response?.data?.includes("NoSuchKey")) {
      message.error({
        key: messageKey,
        content: MESSAGES.FILE_DOWNLOAD_MESSAGES.FILE_REMOVED,
      });
    } else {
      message.error({
        key: messageKey,
        content: MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_ERROR,
      });
    }
  } finally {
    message.destroy(messageKey); // Destroy the message on completion
    store.dispatch(actions.setDocumentsLoader(false));
  }
};

/**
 * Fetches documents count for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @returns A Promise that resolves to the project assets data.
 * @throws If an error occurs during the request.
 */
export const fetchDocumentsCounts = async (
  subProjectId: string,
): Promise<IDocumentsCountResponse> => {
  try {
    const url = GET_DOCUMENTS_COUNT.replace("{projectId}", subProjectId);
    const response: AxiosResponse<IDocumentsCountResponse> =
      await http.get(url);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Uploads project documents for a specific sub-project.
 *
 * @param {string} subProjectId - The unique identifier of the sub-project.
 * @param {FormData} body - The form data containing the documents to be uploaded.
 * @returns {Promise<any>} - A promise that resolves with the uploaded document data, or logs an error in case of failure.
 */
export const uploadProjectDocuments = async (
  subProjectId: string,
  body: FormData,
): Promise<any> => {
  try {
    const url = GET_PROJECT_DOCUMENTS.replace("{subProjectId}", subProjectId);
    const response = await http.post(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Uploads assets documents for a specific sub-project.
 *
 * @param {string} subProjectId - The unique identifier of the sub-project.
 * @param {FormData} body - The form data containing the documents to be uploaded.
 * @returns {Promise<any>} - A promise that resolves with the uploaded document data, or logs an error in case of failure.
 */
export const uploadAssetsDocuments = async (
  subProjectId: string,
  body: FormData,
): Promise<any> => {
  try {
    const url = UPLOAD_ASSET_DOCUMENT.replace("{subProjectId}", subProjectId);
    const response = await http.post(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Uploads asset package documents for a specific sub-project.
 *
 * @param {string} subProjectId - The unique identifier of the sub-project.
 * @param {FormData} body - The form data containing the documents to be uploaded.
 * @returns {Promise<any>} - A promise that resolves with the uploaded document data, or logs an error in case of failure.
 */
export const uploadAssetPackageDocuments = async (
  subProjectId: string,
  body: FormData,
): Promise<any> => {
  try {
    const url = GET_ASSET_PACKAGE_DOCUMENTS.replace(
      "{subProjectId}",
      subProjectId,
    );
    const response = await http.post(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Deletes a document associated with a project.
 * @param {string} subProjectId - The ID of the subProject to which the document belongs.
 * @param {object} body - The body containing the ID of the document to be deleted.
 * @returns {Promise<any>} A Promise that resolves with the data returned from the server upon successful deletion.
 */
export const deleteProjectDocument = async (
  subProjectId: string,
  body: { id: string; deletedComment: string },
): Promise<any> => {
  try {
    const url = GET_PROJECT_DOCUMENTS.replace("{subProjectId}", subProjectId);
    const response = await http.delete(url, {
      id: body.id,
      deleted_comment: body.deletedComment,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Deletes a document associated with tags.
 * @param {string} subProjectId - The ID of the subProject to which the document belongs.
 * @param {object} body - The body containing the ID of the document to be deleted.
 * @returns {Promise<any>} A Promise that resolves with the data returned from the server upon successful deletion.
 */
export const deleteAssetDocument = async (
  subProjectId: string,
  body: { id: string; deletedComment?: string },
): Promise<any> => {
  try {
    const url = DELETE_ASSET_DOCUMENT.replace("{subProjectId}", subProjectId);
    const response = await http.delete(url, {
      id: body.id,
      deleted_comment: body.deletedComment,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Deletes a document associated with packages.
 * @param {string} subProjectId - The ID of the subProject to which the document belongs.
 * @param {object} body - The body containing the ID of the document to be deleted.
 * @returns {Promise<any>} A Promise that resolves with the data returned from the server upon successful deletion.
 */
export const deleteAssetPackageDocument = async (
  subProjectId: string,
  body: { id: string; deletedComment?: string },
): Promise<any> => {
  try {
    const url = DELETE_ASSET_PACKAGE_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    );
    const response = await http.delete(url, {
      id: body.id,
      deleted_comment: body.deletedComment,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Downloads a single file from the specified URL.
 *
 * @param {string} url - The URL to the file to be downloaded.
 */
export const downloadSingleFileFromURL = async (url: string) => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    const filename = extractFileNameFromSignedURL(url);
    saveAs(response.data, filename || "example"); // Save file with timestamp
    message.success("File downloaded successfully.");
  } catch (ex: any) {
    if (ex?.response?.status === 404) {
      message.error(MESSAGES.FILE_DOWNLOAD_MESSAGES.FILE_REMOVED);
    } else {
      message.error(ex.message || MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  }
};

export const fetchDocuments = async (params?: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.get(
      GET_ALL_DOCUMENTS,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Restores an asset document.
 *
 * @param {string} subProjectId - The ID of the subproject to which the asset document belongs.
 * @param {string} assetDocId - The ID of the asset document to restore.
 * @param {Object} body - The data to restore the asset document with.
 * @returns {Promise<any>} A Promise that resolves with the restored asset document data.
 */
export const restoreAssetDocument = async (
  subProjectId: string,
  assetDocId: string,
  body: { recoveredComment: string },
): Promise<any> => {
  try {
    const url = RESTORE_ASSET_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetDocId}", assetDocId);
    const response = await http.patch(url, {
      recovered_comment: body.recoveredComment,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Updates an asset document.
 *
 * @param {string} subProjectId - The ID of the subproject to which the asset document belongs.
 * @param {string} assetDocId - The ID of the asset document to update.
 * @param {Object} body - The data to update the asset document with.
 * @returns {Promise<any>} A Promise that resolves with the updated asset document data.
 */
export const updateAssetDocument = async (
  subProjectId: string,
  assetDocId: string,
  body: { fileName: string; comment: string },
): Promise<any> => {
  try {
    const url = UPDATE_ASSET_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetDocId}", assetDocId);
    const response = await http.patch(url, {
      comment: body.comment,
      file_name: body.fileName,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Restores an asset package document.
 *
 * @param {string} subProjectId - The ID of the subproject to which the asset package document belongs.
 * @param {string} assetPackageDocId - The ID of the asset package document to restore.
 * @param {Object} body - The data to restore the asset package document with.
 * @returns {Promise<any>} A Promise that resolves with the restored asset package document data.
 */
export const restoreAssetPackageDocument = async (
  subProjectId: string,
  assetPackageDocId: string,
  body: { recoveredComment: string },
): Promise<any> => {
  try {
    const url = RESTORE_ASSET_PACKAGE_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetPackageDocId}", assetPackageDocId);
    const response = await http.patch(url, {
      recovered_comment: body.recoveredComment,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Updates an asset package document.
 *
 * @param {string} subProjectId - The ID of the subproject to which the asset package document belongs.
 * @param {string} assetPackageDocId - The ID of the asset package document to update.
 * @param {Object} body - The data to update the asset package document with.
 * @returns {Promise<any>} A Promise that resolves with the updated asset package document data.
 */
export const updateAssetPackageDocument = async (
  subProjectId: string,
  assetPackageDocId: string,
  body: { fileName: string; comment: string },
): Promise<any> => {
  try {
    const url = UPDATE_ASSET_PACKAGE_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetPackageDocId}", assetPackageDocId);
    const response = await http.patch(url, {
      comment: body.comment,
      file_name: body.fileName,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Restores a project document.
 *
 * @param {string} subProjectId - The ID of the subproject to which the project document belongs.
 * @param {string} projectDocId - The ID of the project document to restore.
 * @param {Object} body - The data to restore the project document with.
 * @returns {Promise<any>} A Promise that resolves with the restored project document data.
 */
export const restoreProjectDocument = async (
  subProjectId: string,
  projectDocId: string,
  body: { recoveredComment: string },
): Promise<any> => {
  try {
    const url = RESTORE_PROJECT_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{projectDocId}", projectDocId);
    const response = await http.patch(url, {
      recovered_comment: body.recoveredComment,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Updates a project document.
 *
 * @param {string} subProjectId - The ID of the subproject to which the project document belongs.
 * @param {string} projectDocId - The ID of the project document to update.
 * @param {Object} body - The data to update the project document with.
 * @returns {Promise<any>} A Promise that resolves with the updated project document data.
 */
export const updateProjectDocument = async (
  subProjectId: string,
  projectDocId: string,
  body: { fileName: string; comment: string },
): Promise<any> => {
  try {
    const url = UPDATE_PROJECT_DOCUMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{projectDocId}", projectDocId);
    const response = await http.patch(url, {
      comment: body.comment,
      file_name: body.fileName,
    });
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Asynchronously deletes a document associated with a specific sub-project and document type.
 *
 * @param {string} subProjectId - The unique identifier for the sub-project.
 * @param {string} docType - The type of the document, determining the specific API endpoint to use.
 * @param {object} body - The request body containing the `id` of the document to be deleted.
 * @returns {Promise<any>} - The response data from the delete operation.
 */
export const deleteDocument = async (
  subProjectId: string,
  docType: string,
  body: { id: string },
): Promise<any> => {
  try {
    const url =
      docType === "ProjectDocuments"
        ? `/projects/${subProjectId}/documents/`
        : docType === "PackageRoomDocument"
        ? `/projects/${subProjectId}/packages/documents/`
        : `/projects/${subProjectId}/tags/documents/`;

    const response = await http.delete(url, body);
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Asynchronously fetches the count of work order documents for a specific asset.
 *
 * @param {string} assetId - The unique identifier for the asset.
 * @param {any} [params] - Optional query parameters that can be passed to the API request.
 * @returns {Promise<any>} - The count of work order documents associated with the specified asset.
 *
 */
export const fetchWorkOrderDocumentsCount = async (
  assetId: string,
  params?: any,
): Promise<any> => {
  try {
    const url = GET_ASSET_DOCUMENTS_COUNT.replace("{assetId}", assetId);
    const response = await pmHttp.get(url, params);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
