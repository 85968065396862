import { memo } from "react";

const BellIcon: React.FC<{ className?: string; onClick?: () => void }> = (
  props,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 26 27"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.68839 9.36635C3.68839 6.89675 4.66943 4.52829 6.41571 2.78201C8.16199 1.03574 10.5304 0.0546875 13.0001 0.0546875C15.4697 0.0546875 17.8381 1.03574 19.5844 2.78201C21.3307 4.52829 22.3117 6.89675 22.3117 9.36635V10.401C22.3117 13.3297 23.4153 15.9976 25.2335 18.0159C25.3468 18.1414 25.4275 18.2927 25.4688 18.4567C25.51 18.6207 25.5104 18.7922 25.47 18.9564C25.4296 19.1205 25.3495 19.2723 25.2369 19.3984C25.1242 19.5244 24.9824 19.621 24.8238 19.6795C22.6938 20.4659 20.4646 21.0452 18.1594 21.3943C18.2113 22.1035 18.1164 22.8159 17.8806 23.4868C17.6449 24.1577 17.2733 24.7729 16.7892 25.2938C16.305 25.8147 15.7186 26.2301 15.0667 26.5142C14.4147 26.7983 13.7112 26.945 13.0001 26.945C12.2889 26.945 11.5854 26.7983 10.9334 26.5142C10.2815 26.2301 9.69512 25.8147 9.21095 25.2938C8.72679 24.7729 8.35522 24.1577 8.11945 23.4868C7.88369 22.8159 7.7888 22.1035 7.8407 21.3943C5.56679 21.0497 3.33384 20.4747 1.17631 19.6782C1.01785 19.6197 0.876121 19.5233 0.763505 19.3974C0.650889 19.2715 0.570808 19.1199 0.530257 18.9559C0.489706 18.792 0.489918 18.6206 0.530875 18.4567C0.571831 18.2928 0.652286 18.1415 0.765213 18.0159C2.65073 15.9282 3.69263 13.2141 3.68839 10.401V9.36635ZM9.89892 21.644C9.88127 22.0622 9.94842 22.4797 10.0963 22.8713C10.2442 23.263 10.4698 23.6206 10.7595 23.9228C11.0492 24.225 11.397 24.4655 11.7821 24.6297C12.1671 24.794 12.5814 24.8787 13.0001 24.8787C13.4187 24.8787 13.833 24.794 14.218 24.6297C14.6031 24.4655 14.9509 24.225 15.2406 23.9228C15.5303 23.6206 15.7559 23.263 15.9038 22.8713C16.0517 22.4797 16.1188 22.0622 16.1012 21.644C14.0379 21.8298 11.9622 21.8298 9.89892 21.644Z"
        fill="#0954F1"
      />
    </svg>
  );
};

export default memo(BellIcon);
