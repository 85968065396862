//libs
import React from "react";
import { Avatar, Dropdown, MenuProps, Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";

//local imports
import { displayDateTime, getFullUserName, getUserImage } from "utils/helpers";
import { IDocuments } from "redux/components/documents";
import AlectifyText from "static/texts.json";
import { IUser } from "redux/components/Auth";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { IDocumentsTableProps } from "../DocumentsTable.interface";
import { CalendarDateIcon, UserIcon } from "components/icons";
import "../DocumentTable.scss";
import PreviewFile from "components/shared/preview-file";
import { isEmpty, truncate } from "lodash";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { downloadSingleFile } from "services/documents/documents.service";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import ViewImage from "components/shared/view-image/ViewImage";
import { DocumentEditTypes } from "components/shared/edit-document/EditDocument.interface";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import { useLocation, useNavigate } from "react-router-dom";
import TextToLink from "components/shared/text-to-link";
import AssetDetail from "components/shared/asset-detail";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import PackageDetailAssets from "components/shared/package-detail-assets";

export const getItems = (document: IDocuments, props: IDocumentsTableProps) => {
  const items: MenuProps["items"] = [
    {
      key: "download",
      label: "Download",
      disabled: !document.is_active,
      onClick: async (ev) => {
        ev.domEvent.stopPropagation();
        await downloadSingleFile(document.file_path, document.file_name);
      },
    },
    {
      key: "editFile",
      label: "Edit File Name",
      disabled: !document.is_active,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onEdit) props.onEdit(document, DocumentEditTypes.FILENAME);
      },
    },
    {
      key: "editComment",
      label: "Edit Comments",
      disabled: !document.is_active,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onEdit) props.onEdit(document, DocumentEditTypes.COMMENT);
      },
    },
    {
      key: "delete",
      label: "Delete",
      disabled: !document.is_active,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onDelete) props.onDelete(document);
      },
    },
    {
      key: "restore",
      label: "Restore",
      disabled: document.is_active,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onRestore) props.onRestore(document);
      },
    },
  ];

  return items;
};

const useDocumentsColumn = (props: IDocumentsTableProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isAssetDocumentsPath = location.pathname.includes("asset-documents");

  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.UPLOADED_AT,
          dataIndex: "created_by",
          width: 200,
          visible: true,
          render: (created_by: IUser, record: IDocuments) => (
            <Tooltip title={`Site: ${record?.master_project_name}`}>
              <Space direction="vertical" size={15} className="ml-10">
                <div className="creator-container">
                  <CalendarDateIcon />
                  <span className="creator-name-date">
                    {displayDateTime(record.created_at, true)}
                  </span>
                </div>
              </Space>
            </Tooltip>
          ),
        },
        {
          title: AlectifyText.FILE_NAME,
          dataIndex: "dir_key",
          width: "20%",
          visible: true,
          searchable: true,
          ellipsis: true,
          render: (dir_key: string, record: IDocuments) => (
            <div className="folder-file-container">
              <PreviewFile
                fileName={record?.file_name}
                fileUrl={record?.file_path}
                isActivevalue={record?.is_active}
              />
            </div>
          ),
        },
        {
          title: AlectifyText.DOCUMENT_TYPE,
          dataIndex: "document_type",
          visible: isAssetDocumentsPath,
          width: 150,
          ellipsis: false,

          render: (type: string, record: IDocuments) => (
            <>
              {type === "PackageRoomDocument" ? (
                <AssetPackageTag />
              ) : (
                <AssetTag />
              )}
            </>
          ),
        },
        {
          title: AlectifyText.PARENT_OR_SUB_ASSET,
          dataIndex: "document_type",
          visible: isAssetDocumentsPath,
          width: 170,

          render: (_: any, record: IDocuments) => {
            const subProject: string = record.project;
            return (
              <Space direction="vertical" size={15}>
                <TextToLink
                  className="text-to-link-options"
                  text={truncate(
                    record.document_type === "PackageRoomDocument"
                      ? record.package_name
                      : record.tag_name,
                    {
                      length: 20,
                      omission: "...",
                    },
                  )}
                  underline
                  onClick={() => {
                    const isPackageRoomDocument =
                      record.document_type === "PackageRoomDocument";

                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: isPackageRoomDocument ? 900 : 420,
                      title: truncate(
                        isPackageRoomDocument
                          ? record.package_name
                          : record.tag_name,
                      ),
                      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      closable: true,
                      closeIcon: <CloseOutlined />,
                      onClose: () =>
                        DrawerServiceInstance.close(AlectifyDrawer, {
                          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                        }),
                      children: isPackageRoomDocument ? (
                        <PackageDetailAssets
                          subProjectId={subProject}
                          assetPackageId={record.package}
                          isDetailsbutton
                          navigate={navigate}
                        />
                      ) : (
                        <AssetDetail
                          isDetailButton
                          subProjectId={subProject}
                          assetId={record.tag}
                          navigate={navigate}
                        />
                      ),
                    });
                  }}
                />
              </Space>
            );
          },
        },
        {
          title: AlectifyText.UPLOADED_BY,
          dataIndex: "created_by",
          width: 200,
          ellipsis: true,
          visible: true,
          render: (created_by: IUser, record: IDocuments) => (
            <Tooltip title={`Site: ${record?.master_project_name}`}>
              <div className="creator-container">
                {isEmpty(created_by.image) ? (
                  <UserIcon />
                ) : (
                  <Avatar
                    size="small"
                    src={created_by.image || getUserImage(created_by)}
                    className="cursor-pointer"
                    onClick={() => {
                      ModalServiceInstance.open(AlectifyModal, {
                        title: "Preview",
                        footer: null,
                        name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                        onCancel: () => {
                          ModalServiceInstance.close(AlectifyModal, {
                            name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                          });
                        },
                        children: (
                          <ViewImage
                            imageUrl={created_by.image || NO_IMAGE_AVATAR}
                            isDownloadable={!isEmpty(created_by.image)}
                          />
                        ),
                      });
                    }}
                  />
                )}
                <span className="creator-name-date">
                  {`${
                    !isEmpty(created_by) ? getFullUserName(created_by) : "-"
                  }`}
                </span>
              </div>
            </Tooltip>
          ),
        },

        {
          title: AlectifyText.COMMENTS,
          dataIndex: "comment",
          width: isAssetDocumentsPath ? "33%" : "43%",
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          render: (value: string, record: IDocuments) => (
            <Tooltip title={`Site: ${record?.master_project_name}`}>
              {value || "-"}
            </Tooltip>
          ),
        },
        {
          title: AlectifyText.ACTIONS,
          align: "center",
          dataIndex: "",
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          width: "10%",
          render: (_: any, record: IDocuments) => (
            <Dropdown menu={{ items: getItems(record, props) }}>
              <MoreOutlined style={{ fontSize: 20 }} />
            </Dropdown>
          ),
        },
      ].filter((column) => column.visible),
    [props.optionsPersisted],
  );
};

export default useDocumentsColumn;
