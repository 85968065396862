import { AxiosResponse } from "axios";
import { http, pmHttp } from "utils/client";
import { ITaskResponse } from "redux/components/tasks";
import { IAddTaskUserResponse, IAddUserPayload } from "./tasks.interface";
import { IParams } from "redux/interfaces";
import {
  ADD_USER_TASK_ASSET,
  ADD_USER_TASK_ASSET_PACKAGE,
  CHANGE_TASK_STATUS,
  GET_TASKS,
} from "./tasks.endpoints";
import { SERVICE_UNIQUE_ID } from "utils/constants";

/**
 * Fetches Tasks for a given master project, sub project ID, pmtype.
 *
 * @param masterProjectId - The ID of the master project to fetch tasks.
 * @param subProjectId - The ID of the sub project to fetch tasks for.
 * @param pmType - The PM type.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project task data.
 * @throws If an error occurs during the request.
 */
export const fetchTasksService = async (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
  requestId?: string | null,
) => {
  try {
    let URL = GET_TASKS.replace("{masterProjectId}", masterProjectId).replace(
      "{subProjectId}",
      subProjectId,
    );
    const response: AxiosResponse<ITaskResponse> = await pmHttp.get(
      URL,
      params,
      `${SERVICE_UNIQUE_ID.UNIQUE_FETCH_TASKS}${requestId && requestId}`,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches Task Attachments for a given sub project ID.
 * @param {string} subProjectId - Sub project's id to create task.
 * @param {string} assetId - Asset id to create user for asset task.
 * @returns {IAddTaskUserResponse} - Promise returns with data returned from the task user creation api.
 * @throws If an error occurs during the request.
 */
export const addUserTaskAsset = async (
  subProjectId: string,
  assetId: string,
  body: any,
): Promise<IAddTaskUserResponse> => {
  try {
    const response: AxiosResponse<IAddTaskUserResponse> = await http.post(
      ADD_USER_TASK_ASSET.replace("{subProjectId}", subProjectId).replace(
        "{assetId}",
        assetId,
      ),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches Task Attachments for a given sub project ID.
 * @param {string} subProjectId - Sub project's id to create task.
 * @param {string} assetPackageId - Asset Package id to create user for asset package task.
 * @returns {IAddTaskUserResponse} - Promise returns with data returned from the task user creation api.
 * @throws If an error occurs during the request.
 */
export const addUserTaskAssetPackage = async (
  subProjectId: string,
  assetPackageId: string,
  body: IAddUserPayload,
): Promise<IAddTaskUserResponse> => {
  try {
    const response: AxiosResponse<IAddTaskUserResponse> = await http.post(
      ADD_USER_TASK_ASSET_PACKAGE.replace(
        "{subProjectId}",
        subProjectId,
      ).replace("{assetPackageId}", assetPackageId),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const undoTaskStatus = async (taskId: string) => {
  try {
    const response = await pmHttp.patch(`${CHANGE_TASK_STATUS}${taskId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
