import { Space, Tag } from "antd";
import { CHIP_COLORS } from "../phone-number/constants";
import AlectifyText from "static/texts.json";
import GenericIcon from "components/icons/GenericIcon";

const GenericTag: React.FC<{ count?: number; text?: string }> = ({
  count,
  text,
}) => {
  return (
    <Tag color={CHIP_COLORS.GENERIC} className="generic-tag round-tag">
      <Space size={3}>
        <GenericIcon />
        {text || AlectifyText.GENERIC}
      </Space>
    </Tag>
  );
};

export default GenericTag;
