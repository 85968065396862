import { FormInstance, FormItemProps, TimePickerProps, UploadFile } from "antd";
import { IAlectifyInputProps } from "../input/AlectifyInput.interface";
import {
  IAlectifySelectProps,
  ISelectOption,
} from "../select/AlectifySelect.interface";
import { IAlectifyAutoCompleteProps } from "../autocomplete/autocomplete.interface";
import { CheckboxProps } from "antd/lib";
import { IAsset } from "redux/components/assets";
import { IOrganization, IUser } from "redux/components/Auth";
import { IAssetPackage } from "redux/components/asset-packages";
import { ITeamMemberComponentProps } from "../team-members/TeamMember.interface";
import { IAlectifyDatePickerProps } from "../datepicker/AlectifyDatePicker.interface";
import { IPmExternal } from "redux/components/pm-external";
import { IPmInternal } from "redux/components/pm-internal";
import { IAlectifyButtonProps } from "../button/AlectifyButton.interface";
import { ISelectMasterProjectProps } from "../master-project-select/SelectMasterProject.interface";
import { ISelectSubProjectProps } from "../sub-project-select/SelectSubProject.interface";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import { IProcedure } from "services/procedures/procedures.interface";
import { ITeams } from "services/teams/teams.interface";
import { ITask } from "redux/components/tasks";

export interface IPMCreateEditFormProps {
  subProjectId: string | undefined;
  masterProjectId: string | undefined;
  FormInstance: FormInstance;
  editing: boolean;
  copying?: boolean;
  editType: string;
  isPmExternal?: boolean;
  isMyWorkOrder?: boolean;
  myWorkOrder?: boolean;
  procedure?: IProcedure;
  isTask?: boolean;
  procedureLibraryId?: string;
  pmId: string;
  onEdit: (
    e: any,
    editing: boolean,
    editType: string,
    record: IPmExternal | IPmInternal,
  ) => void;
  callbackEffects?: () => void;
  config?: any;
}

export interface IPMFormFilds extends FormItemProps {
  fieldType: string;
  colSpan: number;
  element?: any;
  wrapped?: false;
  inputProps?: IAlectifyInputProps;
  selectProps?: IAlectifySelectProps;
  autcompleteProps?: IAlectifyAutoCompleteProps;
  buttonProps?: IAlectifyButtonProps;
  teamMembersProps?: ITeamMemberComponentProps;
  datePickerProps?: IAlectifyDatePickerProps;
  timePickerProps?: TimePickerProps;
  masterProjectProps?: ISelectMasterProjectProps;
  subProjectProps?: ISelectSubProjectProps;
  equipmentSelectProps?: { onClick: () => void };
  team_members?: IUser[];
  description?: string | null;
  checkBoxProps?: CheckboxProps;
  ckEditorProps?: any;
  detail?: string;
  show: boolean;
  [key: string]: any;
}

export interface IPMFormFieldsProps {
  onChangePMCategory?: (value: string) => void;
  assetLoader: boolean;
  assetLevel: string | null;
  assets?: any;
  selectedAsset: IAsset | null;
  assetPackage?: any;
  selectedAssetPackage: IAssetPackage | null;
  assetDetail?: any;
  assetPackageDetail?: any;
  assignees?: any;
  approvers?: any;
  details?: string;
  team_members?: IUser[];
  organizations: any;
  isRecurring: boolean;
  dayType: string;
  detail?: string;
  editing?: boolean;
  copying?: boolean;
  pmData?: IPmExternal | IPmInternal | null;
  isPmExternal?: boolean;
  isTask?: boolean;
  isGeneric?: boolean;
  selectedMasterProject: IMasterProject | null;
  selectedSubProject: ISubProject | null;
  showProjectFields: boolean;
  myWorkOrder?: boolean;
  masterProjectId: string;
  subProjectId: string;
  editType: string;
  frequencyType: string;
  taskCategory?: string;
  users?: IUser[];
  selectedTeamMembers: IUser[] | ITeams[] | null;
  onSelectMembersType: string | null;
  formDate?: FormInstance;
  selectedMemberType: string | null;
  onAssetLevelChange: (value: string) => void;
  onSelectAsset: (value: string, record: any) => void;
  onSelectAssetPackage: (value: string, record: any) => void;
  onOpenUserModal: () => void;
  onDetailsChange: (e: string) => void;
  getOrganizations: (e: string) => void;
  onSupplierSelect: (record: any) => void;
  onChangeRecurring: (checked: boolean) => void;
  onDayDateChange: (e: string) => void;
  createAssignee: (user: IUser, type?: string) => void;
  removeAssignee: (userId: string, type?: string) => void;
  equipmentModalHandler: () => void;
  onMasterProjectSelect: (value: string, masterProject: IMasterProject) => void;
  onSubProjectSelect: (value: string, subProject: ISubProject) => void;
  onMasterProjectChange: (value: any) => void;
  onFrequencyTypeChange: (value: string) => void;
  onSelectTeamMember: (value: string) => void;
  onDeselectTeamMember: (value: string) => void;
  handleUserTypeChange: (value: string) => void;
}

export interface IPMFormFieldsInitialState {
  loading: boolean;
  assets: Assets[] | null;
  fetchingAssets: boolean;
  assetPackage: AssetPackage[] | null;
  assetLevel: string | null;
  detail: string;
  assetDetail: any;
  assetPackageTeamMembers: IUser[] | null;
  selectedAsset: null;
  selectedAssetPackage: IAssetPackage | null;
  organizations: IOrganization[] | null;
  selectedSupplier: any;
  isRecurring: boolean;
  dayType: string;
  selectedMasterProject: IMasterProject | null;
  selectedSubProject: ISubProject | null;
  selectedProcedureLibrary: IProcedure | null;
  procedureLibraryId: string | null;
  frequencyType: FrequencyTypeEnum | null;
  taskCategory: string;
  pmDetails: ITask | null;
  previewImage: string;
  fileList: UploadFile[];
  selectedTeamMembers: IUser[] | ITeams[] | null;
  projectTeamMembers: IUser[] | null;
  projectTeams: ITeams[] | null;
  selectedMembertype: string | null;
  drawerConfig: any | null;
  selectedTeamId: string | null;
}

interface AssetPackage extends ISelectOption {}
interface Assets extends ISelectOption {}

export enum FrequencyTypeEnum {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
}
