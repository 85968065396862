import { useEffect, useState } from "react";
import {
  EmailTypes,
  IAlectifyEmailComposerProps,
} from "./AlectifyEmailComposer.interface";
import { IContact } from "services/contacts/contacts.interface";
import { Col, Form, message, Row, Select } from "antd";
import AlectifySelect from "../select";
import AlectifyInput from "../input";
import TextEditor from "components/text-editor/TextEditor";
import { debounce } from "lodash";
import { fetchContacts } from "services/contacts/contacts.service";
import "./AlectifyEmailComposer.scss";
import { useForm } from "antd/lib/form/Form";
import AlectifyButton from "../button";
import { SendOutlined } from "@ant-design/icons";
import { sendIncidentReportEmail } from "services/email-share/email-share.service";
import { IAlectifyEmailComposerBody } from "services/email-share/email.interface";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";

const AlectifyEmailComposer: React.FC<IAlectifyEmailComposerProps> = (
  props: IAlectifyEmailComposerProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<IContact[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [formInstance] = useForm();

  const getContactList = debounce(async (value: string) => {
    if (value.trim() === "") return;
    setLoader(true);
    try {
      const response = await fetchContacts({ search: value } as any);
      setContacts(response.data);
      setLoader(false);
    } catch (ex) {
      console.log("Error fetching contacts:", ex);
      setLoader(false);
    }
  }, 300);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    getContactList(value);
  };

  const handleChange = (value: any[], option: any) => {
    const newContacts: any[] = value.map((email: string) => {
      // Check if the selected value is already a contact
      const existingContact = contacts.find(
        (contact) => contact.email === email,
      );

      if (existingContact) {
        return existingContact;
      }

      // If not, add it as a new contact
      return {
        id: email, // Using email as ID for new contacts
        first_name: "",
        last_name: "",
        email,
      };
    });

    setSelectedContacts(newContacts);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      setSearchValue("");
    }
  };

  const mergedOptions = [
    ...selectedContacts.map((contact: IContact) => ({
      label: `${contact.first_name} ${contact.last_name} (${contact.email})`,
      value: contact.email, // Using email as the value to handle new entries
    })),
    ...contacts
      .filter(
        (contact: IContact) =>
          !selectedContacts.some((sel) => sel.email === contact.email),
      )
      .map((contact: IContact) => ({
        label: `${contact.first_name} ${contact.last_name} (${contact.email})`,
        value: contact.email,
      })),
  ];

  const onSubmit = async (values: any) => {
    setLoader(true);
    try {
      const payload: IAlectifyEmailComposerBody = {
        to: values.to,
        subject: values.subject,
        text: values.body,
      };
      // Type added for future integration this component can be used to send email for any module.
      if (props.type === EmailTypes.INCIDENT_REPORT) {
        const response = await sendIncidentReportEmail(payload);
        setLoader(false);
        if (response.status) {
          props.callback && props.callback();
          message.success(
            response.message || "Email has been sent successfully.",
          );
        }
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  return (
    <Form layout="vertical" form={formInstance} onFinish={onSubmit}>
      <Row
        justify="start"
        align="middle"
        className="alectify-email-composer-container"
      >
        <Col span={24}>
          <Form.Item
            name="to"
            label="To"
            rules={BASIC_REQUIRED_VALIDATION_RULES}
          >
            <AlectifySelect
              placeholder="Enter Recipient"
              className={
                selectedContacts.length < 2
                  ? "email-selection-height-fixed"
                  : ""
              }
              name="to"
              mode="tags"
              onSearch={handleSearchChange}
              loading={loader}
              filterOption={false}
              defaultActiveFirstOption={false}
              onChange={handleChange}
              onDropdownVisibleChange={handleDropdownVisibleChange}
              value={selectedContacts.map((contact) => contact.email)}
              searchValue={searchValue}
              options={mergedOptions}
              maxTagCount={4}
              showSearch
              noFormItem
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <AlectifyInput
            name="subject"
            label="Subject"
            type="text"
            rules={BASIC_REQUIRED_VALIDATION_RULES}
          />
        </Col>
        <Col
          span={24}
          className="alectify-email-composer-text-editor-container"
        >
          <Form.Item
            name="body"
            label="Body"
            rules={BASIC_REQUIRED_VALIDATION_RULES}
          >
            <TextEditor initialValue={props.defaultBodyValue || ""} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"end"} align={"middle"}>
        <Col span={24} className="text-align-right">
          <AlectifyButton
            htmlType="submit"
            text={"Send"}
            icon={<SendOutlined />}
            type="primary"
            loading={loader}
            className="dark-blue-button"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default AlectifyEmailComposer;
