import { Col, Row } from "antd";
import { memo } from "react";
import NotificationsMain from "components/shared/notifications";
// import NotificationsFilters from "components/shared/notifications/NotificationsFilters";

const Notifications: React.FC = () => {
  return (
    <div>
      <Row gutter={16}>
        {/* <Col span={4}>
          <NotificationsFilters />
        </Col> */}
        <Col span={24}>
          <NotificationsMain fullScreenActive />
        </Col>
      </Row>
    </div>
  );
};

export default memo(Notifications);
