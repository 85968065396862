import { IProceduresInitialState } from "./procedures.interface";

export const ProcedureInitialState: IProceduresInitialState = {
  tableView: false,
  activeToggleButton: "all",
  activeProcedureSwitcher: "cardView",
  procedures: [],
  fetching: false,
  meta: {
    totalPages: 0,
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 0,
    itemCount: 0,
  },
};
