import React from "react";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { displayDateTime } from "utils/helpers";
import AlectifyText from "static/texts.json";
import { IUser } from "redux/components/Auth";
import AvatarGroup from "components/shared/avatar-group";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import TextToLink from "components/shared/text-to-link";
import SparePartDetail from "components/shared/spare-part-detail";
import { ISparePartDraw } from "redux/components/spare-parts";
import { CloseOutlined } from "@ant-design/icons";

const useWorkOrderSparePartsHistoryColumns = () => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      width: 120,
      dataIndex: "createdAt",
      visible: true,
      ellipsis: true,
      title: AlectifyText.DATE,
      render: (created_at) => displayDateTime(created_at, true),
    },
    {
      width: 80,
      dataIndex: "quantity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.QUANTITY,
    },
    {
      width: 80,
      dataIndex: [
        "projectSparePart",
        "projectSparePartCategory",
        "sparePartCategory",
        "category",
      ],
      visible: true,
      ellipsis: true,
      title: AlectifyText.CATEGORY,
    },
    {
      width: 100,
      dataIndex: ["projectSparePart", "sparePart", ["partNumber"]],
      visible: true,
      ellipsis: true,
      title: AlectifyText.PART_NUMBER,
      render: (_, record: ISparePartDraw) => {
        return (
          <TextToLink
            className="text-to-link-options"
            text={record?.projectSparePart?.sparePart?.partNumber}
            underline
            onClick={() => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 900,
                title: record?.projectSparePart?.sparePart?.partNumber,
                name: DRAWER_CONSTANTS.DRAWER_SPARE_PART_DETAIL,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () =>
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.DRAWER_SPARE_PART_DETAIL,
                  }),
                children: (
                  <SparePartDetail
                    sparePart={{
                      ...record.projectSparePart,
                      project: record.project,
                    }}
                    showSimilarSparePart
                  />
                ),
              });
            }}
          />
        );
      },
    },
    {
      width: 80,
      dataIndex: ["projectSparePart", "sparePart", "description"],
      visible: true,
      ellipsis: true,
      title: AlectifyText.DESCRIPTION,
    },
    {
      width: 80,
      dataIndex: "price",
      visible: true,
      ellipsis: true,
      title: AlectifyText.PRICE,
    },
    // {
    //   width: 140,
    //   dataIndex: "activity",
    //   visible: true,
    //   ellipsis: true,
    //   title: AlectifyText.ACTIVITY,
    // },
    // {
    //   width: 100,
    //   dataIndex: ["asset", "name"],
    //   visible: true,
    //   ellipsis: true,
    //   title: AlectifyText.ASSET_OR_ASSET_PACKAGE,
    //   searchable: true,
    //   render: (_, record: ISparePartDraw) => {
    //     return (
    //       <span>
    //         {truncate(record.asset?.name, {
    //           length: 20,
    //           omission: "...",
    //         }) ||
    //           truncate(record.area?.name, {
    //             length: 20,
    //             omission: "...",
    //           })}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: AlectifyText.ASSET_CATEGORY,
    //   dataIndex: "subProject",
    //   visible: true,
    //   ellipsis: true,
    //   width: 150,
    //   render: (masterProject: IMasterProject) => (
    //     <Tooltip placement={"topLeft"} title={masterProject?.name}>
    //       <TextToLink
    //         underline={false}
    //         text={truncate(masterProject?.name, {
    //           length: 20,
    //           omission: "...",
    //         })}
    //       />
    //     </Tooltip>
    //   ),
    // },
    // {
    //   title: AlectifyText.SITE,
    //   dataIndex: "project",
    //   visible: true,
    //   ellipsis: true,
    //   width: 150,
    //   render: (masterProject: IMasterProject) => (
    //     <Tooltip placement={"topLeft"} title={masterProject?.name}>
    //       <TextToLink
    //         underline={false}
    //         text={truncate(masterProject?.name, {
    //           length: 20,
    //           omission: "...",
    //         })}
    //       />
    //     </Tooltip>
    //   ),
    // },
    {
      title: AlectifyText.DRAWN_BY,
      dataIndex: "user",
      visible: true,
      ellipsis: true,
      width: 100,
      render: (user: IUser) => (
        <AvatarGroup users={[user]} maxCount={2} showImage />
      ),
    },
    {
      title: AlectifyText.COMMENTS,
      dataIndex: "comments",
      visible: true,
      ellipsis: true,
      width: 150,
    },
  ];
  return React.useMemo(() => columns, []);
};

export default useWorkOrderSparePartsHistoryColumns;
