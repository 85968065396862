import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { BrowserRouter } from "react-router-dom";
import { config } from "utils/configs";
import { messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import { saveFirebaseToken } from "services/auth/auth.service";
import { setFirebaseToken } from "redux/components/Auth/sources";
import RootRoute from "routes/RootRoute";
import ModalRoot from "components/shared/CaaS/modal/component/ModalRoot";
import DrawerRoot from "components/shared/CaaS/drawer/component/DrawerRoot";
import "./assets/scss/index.scss";
import {
  getNotifications,
  getUnreadNotificationsCount,
} from "redux/components/notifications/sources";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import CrossIcon from "components/icons/CrossIcon";
import AlectifyButton from "components/shared/button";
import { MODAL_NAMES } from "constants/modal.constants";
import { MESSAGES } from "constants/messages";
import { getFromLocal, saveToLocal } from "utils/cache";
import { SECURITY_BANNER_FLAG } from "utils/constants";

function App() {
  const dispatch = useDispatch();
  const channel = new BroadcastChannel("background-message-channel");
  const { user } = useSelector((state: IRootState) => state.auth);

  const requestPermission = async () => {
    if (user?.fcmToken) return;
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: config.REACT_APP_FIREBASE_VAPIDKEY,
      });
      // subscribeNotifications();
      await saveFirebaseToken(token);
      dispatch(setFirebaseToken(token));
    } else if (permission === "denied") {
      requestPermission();
    }
  };

  onMessage(messaging, (payload: any) => {
    try {
      syncNotifications();
    } catch (error) {
      console.log("error: ", error);
    }
  });

  const syncNotifications = () => {
    dispatch(getUnreadNotificationsCount());
    dispatch(getNotifications({ page: 1, limit: 50 }));
  };

  // handler for background notifications when the tab is in-active.
  const subscribeChannelForBgNotifications = () => {
    channel.onmessage = (event) => {
      // const payload = event;
      syncNotifications();
    };
  };

  const onShowSecurityMessage = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.SECURITY_MESSAGE_MODAL,
      className: "security-banner-modal",
      width: 700,
      footer: (
        <AlectifyButton
          name="close"
          text="Close"
          type="default"
          className="alectify-default-button"
          onClick={() => {
            saveToLocal(false, SECURITY_BANNER_FLAG, false, false);
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.SECURITY_MESSAGE_MODAL,
            });
          }}
        />
      ),
      closable: false,
      closeIcon: <CrossIcon fill="#000" />,
      children: (
        <ConfirmationModal
          message={MESSAGES.GENERAL_MESSAGES.SECURITY_MESSAGE}
          showConfirmationButtons={false}
          onCancel={() => {
            saveToLocal(false, SECURITY_BANNER_FLAG, false, false);
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.SECURITY_MESSAGE_MODAL,
            });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (user?.token) {
      const showMessage = getFromLocal(SECURITY_BANNER_FLAG, false, false);
      if (showMessage === "true") {
        onShowSecurityMessage();
      }
      requestPermission();
      subscribeChannelForBgNotifications();
      syncNotifications();
    }

    return () => {
      channel.close();
    };
  }, [user?.token]);

  return (
    <BrowserRouter>
      <ModalRoot />
      <DrawerRoot />
      <RootRoute />
    </BrowserRouter>
  );
}

export default App;
