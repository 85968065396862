import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, Skeleton } from "antd";
import { IRootState } from "redux/rootReducer";
import { memo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationsHeader from "./NotificationsHeader";
import NotificationsDropDownCard from "./NotificationsDropDownCard";
import { INotificationsMainProps } from "./Notification.interface";
import {
  getNotifications,
  confirmNotificationsRead,
} from "redux/components/notifications/sources";
import "./Notification.scss";
import { actions as notificationActions } from "redux/components/notifications";

const NotificationsMain: React.FC<INotificationsMainProps> = (
  props: INotificationsMainProps,
) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Track drawer visibility
  const notifications = useSelector((state: IRootState) => state.notifications);
  const wrapperRef = useRef<HTMLDivElement>(null); // Reference for the component

  useEffect(() => {
    let limit = 50;
    dispatch(getNotifications({ page, limit }, page > 1));
  }, [page, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        !isDrawerOpen
      ) {
        dispatch(notificationActions.toggleNotificationDropdown(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, isDrawerOpen]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const markAllAsRead = () => {
    dispatch(confirmNotificationsRead([]));
  };

  return (
    <div className="notifications-main" ref={wrapperRef}>
      <div className="notification-header">
        <NotificationsHeader
          markAllAsRead={markAllAsRead}
          fullScreenActive={props.fullScreenActive}
        />
      </div>
      <div
        className={`notifications-body ${
          !props?.fullScreenActive ? "fixed-height" : ""
        }`}
        id="scrollableDiv"
      >
        <InfiniteScroll
          key="activity-sidebar"
          dataLength={notifications.data?.length}
          next={() => {
            setPage(page + 1);
          }}
          hasMore={notifications.data?.length < notifications.meta?.totalItems}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={
            !notifications.fetching && (
              <Divider plain>It is all, nothing more.</Divider>
            )
          }
          scrollableTarget="scrollableDiv"
          height={props.fullScreenActive ? "calc(100vh - 170px)" : 500}
        >
          {notifications.data?.map((notification) => {
            return (
              <NotificationsDropDownCard
                {...notification}
                key={notification.id}
                isPageNotification={props.fullScreenActive}
                onDrawerOpen={handleDrawerOpen}
                onDrawerClose={handleDrawerClose}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default memo(NotificationsMain);
