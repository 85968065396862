import React from "react";
import { Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { IAsset } from "redux/components/assets";
import AlectifyText from "static/texts.json";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import AssetDetail from "components/shared/asset-detail/AssetDetail";
import TextToLink from "components/shared/text-to-link";
import { IAssetsTableProps } from "../AssetsTable.interface";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyButton from "components/shared/button";
import { EditIconFilledIcon, WarningIcon } from "components/icons";
import AddAssetManualForm from "components/shared/manage-assets/AddAssetManualForm";
import { useNavigate } from "react-router-dom";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { truncate } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import DeleteIcon from "components/icons/DeleteIcon";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";

const AssetsColumn = (props: IAssetsTableProps) => {
  const navigate = useNavigate();
  const onCancel = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DELETE_ASSETS_MODAL,
    });
  };
  const subProjectId = props.subProjectId || "-";

  const goToDetailPage = (record: any) => {
    const equipmentType =
      record.type === "PackageRoom" ? "asset-package" : "asset";
    const equipmentId =
      record.type === "PackageRoom" ? record.package_id : record.tag_id;

    const url = `/sub-projects/${subProjectId}/timeline/${equipmentType}/${equipmentId}?page=timeline`;
    navigate(url, { replace: false });
  };
  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },
    onClick: () => {
      goToDetailPage(record);
    },
  });

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.ITEM_NUMBER,
      dataIndex: "serial_number",
      visible: true,
      width: 70,
      ellipsis: {
        showTitle: false,
      },
      onCell: props.gotoDetailPage ? onCell : undefined,
      render: (serial_number, record: IAsset) => (
        <Tooltip placement="topLeft" title={serial_number}>
          <span className={record.type !== "PackageRoom" ? "ml-10" : ""}>
            {serial_number}
          </span>
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.TYPE,
      dataIndex: "type",
      visible: true,
      width: 100,
      onCell: props.gotoDetailPage ? onCell : undefined,
      // filterMultiple: false,
      // filters: [
      //   {
      //     key: "1",
      //     text: AlectifyText.PARENT_ASSET_NAME,
      //     value: "PackageRoom",
      //   },
      //   {
      //     key: "2",
      //     text: AlectifyText.SUB_ASSET_NAME,
      //     value: "Tag",
      //   },
      // ],
      render: (type, record: IAsset) => (
        <>
          {type === "PackageRoom" ? (
            <AssetPackageTag />
          ) : (
            <span className={record.type !== "PackageRoom" ? "ml-10" : ""}>
              <AssetTag />
            </span>
          )}
        </>
      ),
    },
    {
      title: AlectifyText.PARENT_ASSET_NAME,
      dataIndex: "package_name",
      width: 110,
      ellipsis: {
        showTitle: false,
      },
      onCell: props.gotoDetailPage ? onCell : undefined,
      visible: true,
      searchable: true,
      render: (text: string, asset: IAsset) => {
        const assetType = asset.type === "Tag" ? false : true;

        return (
          <Space align="center">
            {asset.package_name ? (
              <Tooltip placement="topLeft" title={text}>
                <TextToLink
                  className={`${
                    !assetType ? "pointer-events-none" : "text-to-link-options"
                  } ${asset.type !== "PackageRoom" ? "ml-10" : ""}`}
                  underline={assetType}
                  text={truncate(text, {
                    length: 20,
                    omission: "...",
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: 900,
                      title: truncate(text, {
                        length: 20,
                        omission: "...",
                      }),
                      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      closable: true,
                      closeIcon: <CloseOutlined />,
                      onClose: () =>
                        DrawerServiceInstance.close(AlectifyDrawer, {
                          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                        }),
                      children: (
                        <PackageDetailAssets
                          asset={asset}
                          assetPackageId={asset?.package_id}
                          subProjectId={asset?.subproject_id}
                          navigate={navigate}
                          isDetailsbutton
                          isUploadbutton
                        />
                      ),
                    });
                  }}
                />
              </Tooltip>
            ) : (
              "-"
            )}
          </Space>
        );
      },
    },

    {
      title: AlectifyText.SUB_ASSET_NAME,
      dataIndex: "tag_name",
      visible: true,
      width: 110,
      onCell: props.gotoDetailPage ? onCell : undefined,
      ellipsis: {
        showTitle: false,
      },
      render: (name, asset: any) => (
        <>
          {asset?.tag_name ? (
            <Tooltip placement="topLeft" title={asset?.tag_name}>
              <TextToLink
                className={`text-to-link-options ${
                  asset.type !== "PackageRoom" ? "ml-10" : ""
                }`}
                text={asset?.tag_name}
                onClick={(e) => {
                  e.stopPropagation();
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: 500,
                    title: truncate(asset.tag_name, {
                      length: 20,
                      omission: "...",
                    }),
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    closable: true,
                    closeIcon: <CloseOutlined />,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      }),
                    children: (
                      <AssetDetail
                        assetId={asset.tag_id}
                        subProjectId={asset?.subproject_id}
                        navigate={navigate}
                        isDetailButton={props?.isDetailButton}
                        isUploadbutton
                      />
                    ),
                  });
                }}
              />
            </Tooltip>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: AlectifyText.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      searchable: true,
      onCell: props.gotoDetailPage ? onCell : undefined,
      render: (description, asset: IAsset) => (
        <Tooltip placement="topLeft" title={description}>
          <span className={`${asset.type !== "PackageRoom" ? "ml-10" : ""}`}>
            {description}
          </span>
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.MANUFACTURER,
      dataIndex: "manufacturer_name",
      width: 100,
      visible: true,
      searchable: true,
      ellipsis: {
        showTitle: false,
      },
      onCell: props.gotoDetailPage ? onCell : undefined,
      render: (manufacture, asset: IAsset) => (
        <Tooltip placement="topLeft" title={manufacture}>
          <span className={`${asset.type !== "PackageRoom" ? "ml-10" : ""}`}>
            {manufacture || "-"}
          </span>
        </Tooltip>
      ),
    },
    {
      visible: true,
      searchable: true,
      title: AlectifyText.ASSET_TYPE,
      dataIndex: "asset_type",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      onCell: props.gotoDetailPage ? onCell : undefined,
      render: (asset_type, asset: IAsset) => (
        <Tooltip placement="topLeft" title={asset_type}>
          <span className={`${asset.type !== "PackageRoom" ? "ml-10" : ""}`}>
            {asset_type}
          </span>
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.ACTIONS,
      align: "center",
      dataIndex: "",
      visible: props.showAction,
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (_, asset: IAsset) =>
        props.selectRowOption && (
          <AlectifyButton
            text="Select"
            type="primary"
            onClick={() => props.onRowSelect?.(asset)}
          />
        ),
    },
    {
      title: AlectifyText.ACTIONS,
      visible: props.displayActionButton,
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (value: string, asset: IAsset) =>
        props.displayActionButton ? (
          <>
            {(() => {
              const confirmationModal = () => {
                ModalServiceInstance.open(AlectifyModal, {
                  name: MODAL_NAMES.DELETE_ASSETS_MODAL,
                  title: AlectifyText.CONFIRMATION,
                  footer: false,
                  onCancel,
                  children: (
                    <ConfirmationModal
                      message={`${
                        asset.type === "Tag"
                          ? `${asset?.tag_name} will be deleted. Are you sure?`
                          : `${asset?.package_name} will be deleted with all its assets?. Are you sure?`
                      }`}
                      icon={WarningIcon}
                      onCancel={onCancel}
                      onConfirm={() => {
                        props.onDelete && props.onDelete(asset);
                        ModalServiceInstance.close(AlectifyModal, {
                          name: MODAL_NAMES.DELETE_ASSETS_MODAL,
                        });
                      }}
                    />
                  ),
                });
              };

              return (
                <Space>
                  <Tooltip title={AlectifyText.EDIT}>
                    <div
                      className={"cursor-pointer"}
                      onClick={() => {
                        DrawerServiceInstance.open(AlectifyDrawer, {
                          width: "70vw",
                          title: AlectifyText.UPDATE_ASSET,
                          name: DRAWER_CONSTANTS.ADD_ASSET_MANUAL,
                          showFooter: true,
                          closable: true,
                          closeIcon: <CloseOutlined />,
                          children: (
                            <AddAssetManualForm
                              isEditing
                              asset={asset}
                              form={props.form}
                              onClose={props?.onClose}
                              subProjectId={props.subProjectId}
                              onCloseAndFetchAssets={
                                props.onCloseAndFetchAssets
                              }
                            />
                          ),
                          onSubmit: props?.form?.submit,
                          onClose: () =>
                            DrawerServiceInstance.close(AlectifyDrawer, {
                              name: DRAWER_CONSTANTS.ADD_ASSET_MANUAL,
                            }),
                        });
                      }}
                    >
                      <EditIconFilledIcon />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={AlectifyText.DELETE}
                    className="cursor-pointer"
                  >
                    <span
                      className="cursor-pointer"
                      onClick={confirmationModal}
                    >
                      <DeleteIcon />
                    </span>
                  </Tooltip>
                </Space>
              );
            })()}
          </>
        ) : null,
    },
  ];

  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};

export default AssetsColumn;
