import React, { useEffect, useState } from "react";
import { Calendar, Spin } from "antd";
import { IMonthlyCalendarProps } from "./MonthlyCalendar.interface";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

const MonthlyCalendar: React.FC<IMonthlyCalendarProps> = (
  props: IMonthlyCalendarProps,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const startDateQ = new URLSearchParams(location.search).get("start") || "";
  const endDateQ = new URLSearchParams(location.search).get("end") || "";
  const currentQ = new URLSearchParams(location.search).get("current") || "";

  const [calendarValue, setCalendarValue] = useState(dayjs());

  const handleSelect = (date: Dayjs) => {
    setCalendarValue(dayjs(date.toDate()));
    props?.setCalendarState &&
      props?.setCalendarState((prevState: any) => ({
        isSelectActive: true,
        selectedDate: date,
      }));
    props.getMonthAndYearFromMonthlyCalendar &&
      props.getMonthAndYearFromMonthlyCalendar(date.month() + 1, date.year());
    const startOfWeek = date.clone().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = date.clone().endOf("week").format("YYYY-MM-DD");

    navigate({ search: `start=${startOfWeek}&end=${endOfWeek}` });
  };

  const customDateCellRender = (date: any, info: any) => {
    const startDates = props.calendarEvents?.data || [];
    const startDate = dayjs(startDateQ);
    const endDate = dayjs(endDateQ);
    const isBetweenDates =
      date.isSame(startDate) ||
      (date.isAfter(startDate) && date.isBefore(endDate)) ||
      date.isSame(endDate);

    const hasRecordExistEvent = startDates.some(
      (startDateObj: any) =>
        dayjs(startDateObj.start).isSame(date, "day") &&
        startDateObj.isRecordExist,
    );

    return (
      <div className={`${isBetweenDates ? "highlight-day" : ""}`}>
        {info.originNode}
        {hasRecordExistEvent && <div className="calendar-dot" />}
      </div>
    );
  };

  const shortMonths = Array.from({ length: 12 }, (_, index) =>
    dayjs().month(index).format("MMM"),
  );

  useEffect(() => {
    if (currentQ) {
      const dateObj = dayjs(currentQ);
      const month = dateObj.month() + 1;
      const year = dateObj.year();
      setCalendarValue(dateObj);
      props.setCalendarDates &&
        props.setCalendarDates({
          month: month,
          year: year,
        });
    } else if (!props.isSelectActive) {
      const dateObj = dayjs(startDateQ);
      const month = dateObj.month() + 1;
      const year = dateObj.year();
      props?.setCalendarState &&
        props?.setCalendarState((prevState: any) => ({
          ...prevState,
          isSelectActive: false,
        }));
      setCalendarValue(dateObj);
      props.setCalendarDates &&
        props.setCalendarDates({
          month: month,
          year: year,
        });
    }
  }, [startDateQ]);

  return (
    <div>
      <Spin spinning={props.calendarEvents.fetching}>
        <Calendar
          value={calendarValue}
          onSelect={handleSelect as any}
          fullscreen={false}
          className="alectify-weekly-selectable-calendar"
          headerRender={({ value, type, onChange }) => {
            const year = value?.year();
            const month = value?.month();

            return (
              <div className="alectify-weekly-calendar-item">
                <div>
                  <select
                    className="my-month-select"
                    value={String(year)}
                    onChange={(e) => {
                      const selectedYear = parseInt(e.target.value, 10);
                      if (!isNaN(selectedYear)) {
                        onChange(value.clone().year(selectedYear));
                      }
                    }}
                  >
                    {Array.from(
                      { length: 10 },
                      (_, i) => i + new Date().getFullYear() - 5,
                    )
                      .filter((y) => !isNaN(y))
                      .map((y) => (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      ))}
                  </select>
                </div>
                <select
                  className="my-month-select"
                  value={String(month + 1)}
                  onChange={(e) =>
                    onChange(
                      value.clone().month(parseInt(e.target.value, 10) - 1),
                    )
                  }
                >
                  {shortMonths.map((shortMonth, index) => (
                    <option
                      className="month-item"
                      key={`${shortMonth}`}
                      value={index + 1}
                    >
                      {shortMonth}
                    </option>
                  ))}
                </select>
              </div>
            );
          }}
          fullCellRender={customDateCellRender}
        />
      </Spin>
    </div>
  );
};

export default MonthlyCalendar;
