import React, { memo, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Form, message, Spin } from "antd";
import { useDispatch } from "react-redux";
import {
  createNewSubProject,
  fetchSubProjectDetail,
  subProjectDraftCompleted,
  updateSubProject,
} from "services/sub-project/sub-project.service";
import { getSubProjects } from "redux/components/sub-project/sources";
import { PAGINATION } from "constants/index";
import AlectifyText from "static/texts.json";
// import CreateSubProjectSteps from "./CreateSubProjectSteps";
import ManageAssets from "../manage-assets";
import ProjectBasicInformation from "../project-basic-information";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import CreateEditSubProjectFooter from "./CreateEditSubProjectFooter";
import {
  IAssetCategory,
  ICreateEditSubProjectProps,
  ISubProjectState,
} from "./CreateEditSubProject.interface";
import { ICreateNewSubProjectBody } from "services/sub-project/subProject.interface";
import { MESSAGES } from "constants/messages";
import StepNavigation from "./StepNavigation";
import "./CreateEditSubProject.scss";

const CreateEditSubProject: React.FC<ICreateEditSubProjectProps> = ({
  FormInstance,
  masterProjectId,
  subProjectEditId,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<ISubProjectState>({
    subProjectId: "",
    selectedValues: [1],
    currentActiveTab: 1,
    loading: false,
  });
  const [assetCategoryDetail, setAssetCategoryDetail] =
    useState<IAssetCategory>();

  const subProjectCompleted = async (subProjectId: string) => {
    try {
      const response = await subProjectDraftCompleted(
        AlectifyText.BASIC_INFO,
        subProjectId,
        {
          is_draft: false,
        },
      );
      if (response.success) {
        message.success(
          isEdit
            ? MESSAGES.ASSET_CATEGORY.ASSET_CATEGORY_UPDATE
            : MESSAGES.ASSET_CATEGORY.ASSET_CATEGORY_SUCCESS,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Function to handle form submission
  const onSubmitProfile = async () => {
    try {
      await FormInstance.validateFields();
      const values = FormInstance.getFieldsValue();
      const requestBody: ICreateNewSubProjectBody = {
        name: values?.name,
        work_id_prefix: values?.work_id_prefix,
        color: values?.color || "#000000",
        description: values?.description,
        owner: values?.owner,
        master_project: masterProjectId,
      };

      if (state.subProjectId) {
        await updateSubProject(
          state.subProjectId,
          requestBody,
          AlectifyText.BASIC_INFO,
        );
        setState((prevState) => ({
          ...prevState,
          currentActiveTab: prevState.currentActiveTab + 1,
        }));
      } else {
        const response = await createNewSubProject(requestBody);
        if (response.success) {
          setState((prevState) => ({
            ...prevState,
            currentActiveTab: prevState.currentActiveTab + 1,
          }));
          setState((prevState) => ({
            ...prevState,
            subProjectId: response.data?.id,
          }));
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(
        getSubProjects(masterProjectId, {
          page: PAGINATION.DEFAULT_START_PAGE,
          per_page: PAGINATION.DEFAULT_PAGE_SIZE,
        }),
      );
    }
  };

  // Array to store stages
  const stages = [
    <ProjectBasicInformation
      FormInstance={FormInstance}
      masterProjectId={masterProjectId}
    />,
    // <UserAccess subProjectId={state.subProjectId} user={user} />,
    <ManageAssets
      subProjectId={state.subProjectId}
      isEdit={isEdit}
      assetCategoryDetail={assetCategoryDetail}
    />,
    // <ManageAssetPackage subProjectId={state.subProjectId} />,
  ];

  // Function to navigate to the next step
  const goNext = () => {
    try {
      if (state.currentActiveTab === 1) {
        onSubmitProfile();
        return;
      }
      setState((prevState) => ({
        ...prevState,
        currentActiveTab: prevState.currentActiveTab + 1,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  // Function to navigate to the previous step
  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      currentActiveTab: prevState.currentActiveTab - 1,
    }));
  };

  // Function to close the drawer
  const onClose = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_SUB_PROJECT_DRAWER,
    });
  };

  // Function to handle final submission
  const onSubmit = async () => {
    if (!isEmpty(state.subProjectId)) {
      await subProjectCompleted(state.subProjectId);
    }
    onClose();
    dispatch(
      getSubProjects(masterProjectId, {
        page: PAGINATION.DEFAULT_START_PAGE,
        per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      }),
    );
  };

  // Function to handle step activation
  const handleStepActivation = (selectedValue: number) => {
    if (isEmpty(state.subProjectId)) {
      message.warning(MESSAGES.ASSET_CATEGORY.ASSET_CATEGORY_WARNING);
      return;
    }

    setState((prevState) => ({
      ...prevState,
      currentActiveTab: Number(selectedValue),
    }));

    if (state.selectedValues.includes(selectedValue)) {
      // If selectedValue is already in selectedValues, remove values less than it
      setState((prevState) => ({
        ...prevState,
        selectedValues: prevState.selectedValues.filter(
          (value) => value <= selectedValue,
        ),
      }));
    } else {
      // If activeTab is not in selectedValues, add it and values lower than it
      setState((prevState) => ({
        ...prevState,
        selectedValues: Array.from({ length: selectedValue }, (_, i) => i + 1),
      }));
    }
  };

  // Function to get sub-project detail
  const getSubProjectDetail = async (id: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const response = await fetchSubProjectDetail(id, AlectifyText.BASIC_INFO);
      if (response.data) {
        setState((prevState) => ({
          ...prevState,
          subProjectId: response.data.id,
        }));
        setAssetCategoryDetail(response.data);
        FormInstance.setFieldsValue({
          name: response.data.name,
          work_id_prefix: response.data.work_id_prefix,
          color: response.data.color,
          description: response.data.description,
        });
      }
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } catch (err) {
      console.error(err);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  // Trigger handleStepActivation when activeTab changes
  useEffect(() => {
    if (!isEmpty(state.subProjectId)) {
      handleStepActivation(state.currentActiveTab);
    }
  }, [state.currentActiveTab]);

  useEffect(() => {
    if (!isEmpty(subProjectEditId)) {
      getSubProjectDetail(subProjectEditId);
    }
  }, []);

  return (
    <div className="create-edit-sub-project-container">
      <StepNavigation currentActiveTab={state.currentActiveTab} />
      <Spin spinning={state.loading}>
        {/* <Row className="create-sub-project-body" gutter={16}> */}
        {/* <Col span={5}>
            <CreateSubProjectSteps
              currentActiveTab={state.currentActiveTab}
              selectedValues={state.selectedValues}
              handleStepActivation={handleStepActivation}
            />
          </Col> */}

        <div className="stages-container">
          {stages[state.currentActiveTab - 1]}
        </div>
        <div className="sub-footer-container">
          <Form.Item>
            <CreateEditSubProjectFooter
              isEdit={isEdit}
              currentActiveTab={state.currentActiveTab}
              onCancel={state.currentActiveTab === 1 ? onClose : goBack}
              onSubmit={state.currentActiveTab === 2 ? onSubmit : goNext}
            />
          </Form.Item>
        </div>
      </Spin>
    </div>
  );
};

export default memo(CreateEditSubProject);
