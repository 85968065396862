import { Dropdown, Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import TextToLink from "components/shared/text-to-link";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { SparePartStatusEnum } from "enums";
import { truncate } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";
import { displayDateTime, isExternalUser, renderAmount } from "utils/helpers";

import "assets/scss/global.scss"; //@Todo: need to add this file in to single style files, so it can be imported automatically
import SparePartDetail from "components/shared/spare-part-detail";
import LowInventory from "components/shared/tags/LowInventory";
import OutOfStock from "components/shared/tags/OutOfStock";
import { useNavigate } from "react-router-dom";
import { setActiveMasterProject } from "redux/components/common/sources";
import { IMasterProject } from "redux/components/master-project";
import { IProjectSparePart, ISparePart } from "redux/components/spare-parts";
import { ROUTES } from "routes/Routes.constants";
import { getSparePartStatus } from "../SparePartsTable.helpers";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import {
  getSparePartAssociatedCategories,
  getSparePartAssociatedVendors,
} from "redux/components/spare-parts/sources";
import { getDropdownMasterProjects } from "redux/components/master-project/sources";
import AlectifyButton from "components/shared/button";
import AvatarGroup from "components/shared/avatar-group";

interface ISparePartsColumnProps {
  isGlobal?: boolean;
  selectRowOption?: boolean;
  pendingOrdersTab?: boolean;
  disabledOrderedParts?: boolean;
  showSelectbutton?: boolean;
  showAction?: boolean;
  onRowSelect?: (sparePart: IProjectSparePart) => void;
  openDrawDrawer?: (sparePart: IProjectSparePart) => void;
  openOrderModal?: (sparePart: IProjectSparePart) => void;
  openDrawHistory?: (sparePart: IProjectSparePart) => void;
  openRefillDrawer?: (sparePart: IProjectSparePart) => void;
  openSparePartForm?: (sparePart: IProjectSparePart) => void;
  onDeleteSparePart?: (sparePart: IProjectSparePart) => void;
  onDeleteSparePartOrder?: (sparePart: IProjectSparePart) => void;
}

export const SPARE_PART_STATUS_COMPONENTS_TEXTS = {
  [SparePartStatusEnum.NORMAL]: AlectifyText.NORMAL,
  [SparePartStatusEnum.OUT_OF_STOCK]: AlectifyText.OUT_OF_STOCK,
  [SparePartStatusEnum.LOW_INVENTORY]: AlectifyText.LOW_INVENTORY,
};

export const SPARE_PART_STATUS_COMPONENTS = {
  [SparePartStatusEnum.LOW_INVENTORY]: <LowInventory />,
  [SparePartStatusEnum.OUT_OF_STOCK]: <OutOfStock />,
  [SparePartStatusEnum.NORMAL]: null,
};

const useSparePartsColumns = (props: ISparePartsColumnProps) => {
  const {
    openDrawDrawer,
    openRefillDrawer,
    openSparePartForm,
    openDrawHistory,
    openOrderModal,
    onDeleteSparePart,
    onDeleteSparePartOrder,
    selectRowOption,
    disabledOrderedParts,
    pendingOrdersTab,
    showSelectbutton,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    auth: { user },
    common: { activeMasterProject },
  } = useSelector((state: IRootState) => state);
  const { categories, vendors } = useSelector(
    (state: IRootState) => state.spareParts,
  );
  // const dropdownMasterProjects = MasterProject?.dropdownMasterProjects || [];

  useEffect(() => {
    const params = {
      projectId: !props.isGlobal ? activeMasterProject?.id : undefined,
    };
    dispatch(getSparePartAssociatedVendors(params));
    dispatch(getSparePartAssociatedCategories(params));
    dispatch(getDropdownMasterProjects({ filter_by: "user_branch" }));
  }, [activeMasterProject?.id]);

  const onMasterProjectClick = (masterProject: IMasterProject) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveMasterProject(masterProject));
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
    }
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.PO_DATE,
      dataIndex: "manageOrders",
      visible: pendingOrdersTab,
      width: 80,
      render: (_: any, sparePart: IProjectSparePart) =>
        displayDateTime(sparePart?.manageOrders?.[0]?.orderedDate),
      order: 1,
    },
    {
      title: AlectifyText.ORDERED_BY,
      dataIndex: "manageOrders",
      visible: pendingOrdersTab,
      width: 50,
      render: (_: any, sparePart: IProjectSparePart) => (
        <AvatarGroup users={[sparePart?.manageOrders?.[0]?.orderedBy]} />
      ),
      order: 9,
    },
    {
      title: AlectifyText.PO_NUMBER,
      dataIndex: "manageOrders",
      visible: pendingOrdersTab,
      width: 80,
      order: 2,
      render: (_: any, sparePart: IProjectSparePart) =>
        sparePart?.manageOrders?.[0]?.poNumber || "-",
    },
    {
      title: AlectifyText.STATUS,
      dataIndex: "status",
      visible: !pendingOrdersTab,
      searchable: true,
      ellipsis: true,
      width: 90,
      filterMultiple: false,
      filters: Object.keys(SparePartStatusEnum).map((key) => ({
        text: SPARE_PART_STATUS_COMPONENTS_TEXTS[key as SparePartStatusEnum],
        value: key,
      })),
      render: (_: string, sparePart: IProjectSparePart) => {
        const status = getSparePartStatus(sparePart);
        return (
          <Space direction="vertical" size={15}>
            {status && SPARE_PART_STATUS_COMPONENTS[status]}
          </Space>
        );
      },
    },
    {
      width: 80,
      dataIndex: "projectId",
      title: AlectifyText.SITE,
      visible: !pendingOrdersTab,
      searchable: true,
      ellipsis: true,
      // filterMultiple: false,
      // filters: (!props.isGlobal
      //   ? MasterProject.data
      //   : dropdownMasterProjects
      // ).map((mp) => ({
      //   text: mp.name,
      //   value: mp.id,
      // })),
      render: (_: any, sparePart: IProjectSparePart) => (
        <Space direction="vertical" size={15}>
          <Tooltip placement="topLeft" title={sparePart.project?.name}>
            {!props.isGlobal ? (
              <TextToLink
                className="text-to-link-options"
                text={
                  sparePart.project?.name
                    ? truncate(sparePart.project?.name, {
                        length: 20,
                        omission: "...",
                      })
                    : "-"
                }
                underline={!isExternalUser(user)}
                onClick={() => onMasterProjectClick(sparePart.project)}
              />
            ) : (
              <span>
                {sparePart.project?.name
                  ? truncate(sparePart.project?.name, {
                      length: 20,
                      omission: "...",
                    })
                  : "-"}
              </span>
            )}{" "}
          </Tooltip>
        </Space>
      ),
    },
    {
      width: 80,
      dataIndex: "categoryId",
      title: AlectifyText.CATEGORY,
      visible: !pendingOrdersTab,
      searchable: true,
      ellipsis: true,
      filterMultiple: true,
      filters: (categories || []).map((mp) => ({
        key: mp.sparePartCategory.id,
        text: mp.sparePartCategory.category,
        value: mp.sparePartCategory.id,
      })),
      render: (_: any, sparePart: IProjectSparePart) => (
        <Space direction="vertical" size={15}>
          {sparePart?.projectSparePartCategory?.sparePartCategory?.category}
        </Space>
      ),
    },
    {
      width: 80,
      dataIndex: "sparePart",
      title: AlectifyText.PART_NUMBER,
      visible: true,
      searchable: true,
      ellipsis: true,
      order: 3,
      render: (sparePart: ISparePart, record: IProjectSparePart) => (
        <TextToLink
          className="text-to-link-options"
          text={sparePart.partNumber}
          underline
          onClick={() => {
            DrawerServiceInstance.open(AlectifyDrawer, {
              width: 900,
              title: sparePart.partNumber,
              name: DRAWER_CONSTANTS.DRAWER_SPARE_PART_DETAIL,
              closable: true,
              closeIcon: <CloseOutlined />,
              onClose: () =>
                DrawerServiceInstance.close(AlectifyDrawer, {
                  name: DRAWER_CONSTANTS.DRAWER_SPARE_PART_DETAIL,
                }),
              children: (
                <SparePartDetail sparePart={record} showSimilarSparePart />
              ),
            });
          }}
        />
      ),
    },
    {
      width: 80,
      dataIndex: "sparePart",
      title: AlectifyText.DESCRIPTION,
      visible: true,
      searchable: true,
      ellipsis: true,
      order: 4,
      render: (sparePart: ISparePart) => (
        <Tooltip
          placement="topLeft"
          title={
            <span
              className="dangerousHTML"
              dangerouslySetInnerHTML={{ __html: sparePart.description }}
            />
          }
        >
          <span
            className="dangerousHTML"
            dangerouslySetInnerHTML={{ __html: sparePart.description }}
          />
        </Tooltip>
      ),
    },
    {
      width: 80,
      dataIndex: "preferredSupplierId",
      title: AlectifyText.VENDOR,
      visible: true,
      searchable: true,
      ellipsis: true,
      filterMultiple: true,
      filters: (vendors || []).map((mp) => ({
        key: mp.id,
        text: mp.name,
        value: mp.id,
      })),
      order: 8,
      render: (_: any, sparePart: IProjectSparePart) => (
        <Tooltip title={sparePart.preferredSupplier.name}>
          {sparePart.preferredSupplier.name}
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.UNIT_PRICE,
      dataIndex: "manageOrders",
      visible: pendingOrdersTab,
      width: 80,
      order: 5,
      render: (_: any, sparePart: IProjectSparePart) =>
        renderAmount(sparePart?.manageOrders?.[0]?.unitPrice),
    },
    {
      title: AlectifyText.ORDER_QUANTITY,
      dataIndex: "manageOrders",
      visible: pendingOrdersTab,
      width: 80,
      order: 6,
      render: (_: any, sparePart: IProjectSparePart) =>
        sparePart?.manageOrders?.[0]?.quantity,
    },
    {
      width: 80,
      dataIndex: "remainingQuantity",
      title: AlectifyText.PENDING_QTY,
      visible: true,
      searchable: true,
      ellipsis: true,
      order: 7,
      render: (_: any, record: IProjectSparePart) =>
        record?.manageOrders?.[0]?.remainingQuantity ?? 0,
    },
    {
      width: 80,
      dataIndex: "remainingQuantity",
      title: AlectifyText.IN_HAND_QTY,
      visible: !pendingOrdersTab,
      searchable: true,
      ellipsis: true,
      render: (remainingQuantity: number) => (
        <Space direction="vertical" size={15}>
          {remainingQuantity}
        </Space>
      ),
    },
    {
      width: 80,
      dataIndex: "minimumQuantity",
      title: AlectifyText.MIN_QTY,
      visible: !pendingOrdersTab,
      searchable: true,
      ellipsis: true,
      render: (minimumQuantity: number) => (
        <Space direction="vertical" size={15}>
          {minimumQuantity}
        </Space>
      ),
    },
    {
      title: AlectifyText.COMMENTS,
      dataIndex: "manageOrders",
      visible: pendingOrdersTab,
      width: 80,
      order: 10,
      render: (_: any, sparePart: IProjectSparePart) =>
        sparePart?.manageOrders?.[0]?.comments,
    },
    {
      title: AlectifyText.ACTIONS,
      align: "center",
      dataIndex: "",
      ellipsis: {
        showTitle: false,
      },
      fixed: "right",
      visible: props.showAction,
      width: selectRowOption ? 100 : 50,
      render: (_: any, sparePart: IProjectSparePart) => {
        const status = getSparePartStatus(sparePart);
        return selectRowOption ? (
          <AlectifyButton
            type="primary"
            onClick={() => props.onRowSelect?.(sparePart)}
            text={
              disabledOrderedParts && sparePart.manageOrders?.length
                ? "Already Ordered"
                : "Select"
            }
            style={{ width: 131 }}
            disabled={
              status === SparePartStatusEnum.OUT_OF_STOCK && !showSelectbutton
            }
          />
        ) : (
          <Dropdown
            menu={{
              items: [
                {
                  label: "Receive",
                  key: "receive-order",
                  onClick: () => openRefillDrawer?.(sparePart),
                  visible: openRefillDrawer,
                },
                {
                  label: "Edit",
                  key: "edit-order",
                  onClick: () => openOrderModal?.(sparePart),
                  visible: openOrderModal,
                },
                {
                  label: "Draw",
                  key: "draw-spare-part",
                  onClick: () => openDrawDrawer?.(sparePart),
                  visible: openDrawDrawer,
                },
                {
                  label: "Edit",
                  key: "edit",
                  onClick: () => openSparePartForm?.(sparePart),
                  visible: openSparePartForm,
                },
                {
                  label: "Delete",
                  key: "delete-order",
                  onClick: () => onDeleteSparePartOrder?.(sparePart),
                  visible: onDeleteSparePartOrder,
                },
                {
                  label: "Delete",
                  key: "delete",
                  onClick: () => onDeleteSparePart?.(sparePart),
                  visible: onDeleteSparePart,
                },
                {
                  label: "History",
                  key: "history",
                  onClick: () => openDrawHistory?.(sparePart),
                  visible: openDrawHistory,
                },
              ].filter((item) => item.visible) as any,
            }}
          >
            <MoreOutlined style={{ fontSize: 20 }} />
          </Dropdown>
        );
      },
    },
  ].filter((column) => column.visible);

  return React.useMemo(
    () =>
      pendingOrdersTab ? columns.sort((a, b) => a.order - b.order) : columns,
    [vendors, categories],
  );
};

export default useSparePartsColumns;
