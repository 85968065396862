import { CloseOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyDrawer from "components/drawer";
import { PmExternalCircleIcon, TaskCircleIcon } from "components/icons";
import AlectifyBulletIcon from "components/icons/BulletIcon";
import StampCheckIcon from "components/icons/StampCheckIcon";
import AssetDetail from "components/shared/asset-detail";
import AvatarGroup from "components/shared/avatar-group";
import AlectifyButton from "components/shared/button";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { FrequencyTypeEnum } from "components/shared/pm-create-edit-form/PMCreateEditForm.interace";
import PmDetail from "components/shared/pm-detail";
import { ACTION_REQUIRED_COMPONENTS } from "components/shared/pm-external-table/effects/usePmExternalColumns";
import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import ProcedureDetail from "components/shared/procedure-detail";
import {
  AssetPackageTag,
  AssetTag,
  CompletedTag,
  GenericTag,
  NonRecurringTag,
  RecurringTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DangerTag from "components/shared/tags/DangerTag";
import TextToLink from "components/shared/text-to-link";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { PME_ACTION_REQUIRED_ENUM } from "enums";
import { get, isEmpty, truncate } from "lodash";
import moment from "moment";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveMasterProject,
  setActiveSubProject,
} from "redux/components/common/sources";
import { IMasterProject } from "redux/components/master-project";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import { ISubProject } from "redux/components/sub-project";
import { IPmAssignees } from "redux/interfaces";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import { IProcedure } from "services/procedures/procedures.interface";
import AlectifyText from "static/texts.json";
import {
  capitalizeFirstLetter,
  displayDateTime,
  enumToTile,
  getFullUserName,
  isExternalUser,
} from "utils/helpers";

export const WORK_ORDER_ICONS = {
  [PM_TYPES.TASK]: <TaskCircleIcon />,
  [PM_TYPES.PM_INTERNAL]: <PmExternalCircleIcon fill="#D85F02" />,
  [PM_TYPES.PM_EXTERNAL]: <PmExternalCircleIcon fill="#D85F02" />,
};

const DashboardOrdersColumns = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(({ auth }: IRootState) => auth);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const onMasterProjectClick = (masterProject: IMasterProject) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveMasterProject(masterProject));
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
    }
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
    });
  };

  const onSubProjectClick = (
    masterProject: IMasterProject,
    subProject: ISubProject,
  ) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveSubProject(subProject));
      dispatch(setActiveMasterProject(masterProject));
      navigate(
        `${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}${ROUTES.SUB_PROJECT}/${subProject.id}`,
      );
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
      });
    }
  };

  const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
    const isAllChecked =
      procedure.procedureStepCheckedTotalCount ===
      procedure.procedureStepTotalCount;
    return isAllChecked ? (
      <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
    ) : (
      <StampCheckIcon />
    );
  };

  const getDueDateStatus = (dueDate: Date) => {
    const datetimeUtc = moment.utc(dueDate);
    const userTimezone = moment.tz.guess();
    const datetime = datetimeUtc.clone().tz(userTimezone);

    const diffInDays = moment()
      .startOf("day")
      .diff(datetime.startOf("day"), "days");

    if (diffInDays <= 0) {
      return <CompletedTag text={"On-time"} />;
    } else if (diffInDays >= 1 && diffInDays <= 7) {
      return <WaitingForReviewTag text="< 7 Days" />;
    } else if (diffInDays >= 8 && diffInDays <= 14) {
      return <DangerTag color="#dc2f02" text="< 7 - 14 Days" />;
    } else {
      return <DangerTag color="#d00000" text="+14 Days" />;
    }
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.WORK_ORDER_TYPE.replace(":", ""),
      key: "pmType",
      dataIndex: "pmType",
      visible: true,
      width: 80,
      filters: props?.overDueTag
        ? [
            {
              text: AlectifyText.TASKS,
              value: PM_TYPES.TASK,
            },
            // {
            //   text: `PM Internal`,
            //   value: PM_TYPES.PM_INTERNAL,
            // },
            {
              text: AlectifyText.PM_EXTERNAL,
              value: PM_TYPES.PM_EXTERNAL,
            },
          ]
        : null,
      filterMultiple: false,
      render: (target: PM_TYPES) => (
        <Space align="center" size={5}>
          {WORK_ORDER_ICONS[target]}{" "}
          {target === PM_TYPES.PM_EXTERNAL
            ? AlectifyText.PM_EXTERNAL
            : enumToTile(target)}
        </Space>
      ),
    },
    {
      title: AlectifyText.STATUS,
      dataIndex: "dueDate",
      sorter: true,
      key: "dueDate",
      width: 80,
      visible: true,
      render: (value: Date, pmExternal: IPmExternal) => {
        return (
          <Space direction="vertical" size={15}>
            {`Due: ${displayDateTime(value)}`}
            {!props?.overDueTag
              ? PM_STATUS_COMPONENTS[pmExternal.status]
              : getDueDateStatus(value)}
          </Space>
        );
      },
    },
    {
      key: "master-project",
      dataIndex: "project",
      title: AlectifyText.SITE_AND_ASSET_CATEGORY,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (target: any, record: any) => {
        const masterProject = record?.projects
          ? get(record, "projects[0]", target)
          : record?.project;
        const subProject = record?.sub_projects
          ? get(record, "sub_projects[0]", target)
          : record?.subProject;
        return (
          <Space direction="vertical" size={15}>
            {masterProject?.name ? (
              <Tooltip placement="topLeft" title={masterProject?.name}>
                {masterProject?.name
                  ? truncate(masterProject?.name, {
                      length: 20,
                      omission: "...",
                    })
                  : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
            <Tooltip placement="topLeft" title={subProject?.name}>
              <Space direction="horizontal" size={8}>
                <AlectifyBulletIcon />
                <TextToLink
                  className="text-to-link-options"
                  text={truncate(subProject?.name)}
                  underline={!isExternalUser(user)}
                  onClick={() => {
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: 420,
                      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                      title: masterProject?.name,
                      closeIcon: <CloseOutlined />,
                      closable: true,
                      onClose: () =>
                        DrawerServiceInstance.close(AlectifyDrawer, {
                          name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                        }),
                      children: (
                        <SwitchSubProjectDrawer
                          masterProjectId={masterProject.id}
                          navigate={navigate}
                        />
                      ),
                      customFooter: (
                        <Space>
                          <AlectifyButton
                            text={AlectifyText.VISIT_ASSET_CATEGORY}
                            type="default"
                            className="light-blue-button"
                            onClick={() =>
                              onSubProjectClick(masterProject, subProject)
                            }
                          />
                          <AlectifyButton
                            text={AlectifyText.VISIT_SITE}
                            className="ant-btn-primary"
                            onClick={() => onMasterProjectClick(masterProject)}
                          />
                        </Space>
                      ),
                    });
                  }}
                />{" "}
              </Space>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: AlectifyText.WORKID_AND_TITLE,
      dataIndex: "workTitle",
      width: 150,
      visible: true,
      searchable: true,
      render: (value: string, pmExternal: IPmExternal) => {
        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 680,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            closeIcon: <CloseOutlined />,
            closable: true,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              // props.fetchPmExternal(props.optionsPersisted as any);
            },
            showFooter: true,
            destroyOnClose: true,
            readOnly: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={pmExternal.procedure as any}
                taskId={pmExternal.id}
                readonly
                isDisabled={false}
              />
            ),
          });
        };
        return (
          <Space direction="vertical" size={15}>
            <TextToLink
              className="text-to-link-options"
              text={truncate(value)}
              onClick={() => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 480,
                  title: value,
                  name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                  closeIcon: <CloseOutlined />,
                  closable: true,
                  onClose: () =>
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                    }),
                  children: (
                    <PmDetail
                      pmExternalRecord={pmExternal}
                      pmId={pmExternal.id}
                      pmType={pmExternal.pmType}
                    />
                  ),
                });
              }}
            />
            {(!isEmpty(pmExternal.procedure) && (
              <Tooltip
                title={`${AlectifyText.PROCEDURE_CHECKLIST} ${pmExternal?.procedure?.procedureStepCheckedTotalCount}/${pmExternal?.procedure?.procedureStepTotalCount}`}
              >
                <span
                // className="cursor-pointer"
                // onClick={openProcedureCheckListDrawer}
                >
                  {truncate(pmExternal.workId)}{" "}
                  {!isEmpty(pmExternal.procedure) &&
                    getTaskStatusIcon(pmExternal?.procedure)}
                </span>
              </Tooltip>
            )) || <span>{truncate(pmExternal.workId)} </span>}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.PARENT_OR_SUB_ASSET,
      key: "asset_type",
      dataIndex: "asset_type",
      width: 120,
      visible: true,
      render: (_, record: IPmExternal) => {
        const areaName: string = record.area?.name;
        const subProject: ISubProject = record.subProject;
        const isGeneric = record?.isGeneric;
        return (
          <Space direction="vertical" size={15}>
            <TextToLink
              className="text-to-link-options"
              text={truncate(record.asset?.name) || truncate(areaName) || "-"}
              underline={!isGeneric}
              onClick={() => {
                if (!isGeneric) {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: record.asset?.name ? 420 : 900,
                    title: record.asset?.name || areaName || "",
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    closeIcon: <CloseOutlined />,
                    closable: true,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      }),
                    children: !isEmpty(record.asset) ? (
                      <AssetDetail
                        pmExternalRecord={record}
                        subProjectId={subProject.id}
                        assetId={record.asset?.id}
                        navigate={navigate}
                      />
                    ) : (
                      <PackageDetailAssets
                        pmExternalRecord={record}
                        subProjectId={subProject?.id || ""}
                        assetPackageId={record.area?.id || ""}
                        navigate={navigate}
                      />
                    ),
                  });
                }
              }}
            />
            {record.asset?.name ? (
              <AssetTag />
            ) : record.area?.name ? (
              <AssetPackageTag />
            ) : (
              <GenericTag />
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.FREQUENCY,
      dataIndex: "isRecurring",
      key: "isRecurring",
      width: 90,
      visible: true,
      render: (isRecurring, record) => (
        <div className="pminternalcolumn-cell-tag">
          <Space direction="vertical">
            <span>
              {record?.frequency && isRecurring
                ? record.frequencyType === FrequencyTypeEnum.MONTHLY
                  ? `${record?.frequency} Month(s)`
                  : "-"
                : (record.frequencyType &&
                    capitalizeFirstLetter(record.frequencyType)) ||
                  "-"}
            </span>
            {isRecurring ? <RecurringTag /> : <NonRecurringTag />}
          </Space>
        </div>
      ),
    },
    {
      title: AlectifyText.ACTION_REQUIRED,
      dataIndex: "assignees",
      key: "assignees",
      width: 80,
      filters: projectTeamMembers?.data.map((user) => ({
        text: getFullUserName(user),
        value: user.id,
      })),
      visible: true,
      render: (assignees: IPmAssignees[], record: IPmExternal) => {
        const actionRquired = getPMEActionRequired(record);
        const ACTION_REQUIRED = ACTION_REQUIRED_COMPONENTS(actionRquired);
        if (assignees?.length || !isEmpty(record.createdBy)) {
          return (
            <Space direction="vertical" size={5}>
              <AvatarGroup
                maxCount={2}
                users={
                  (actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                    ? assignees.map(({ user }) => user)
                    : [record.createdBy]) as any
                }
              />
              {ACTION_REQUIRED}
            </Space>
          );
        }
        return <></>;
      },
    },
  ];
  return columns.filter((col) => col.visible);
};

export default DashboardOrdersColumns;
