import { Divider, Popover, Tooltip } from "antd";
import "./AlectifyAiSummaryButton.scss";
import { IAlectifyAiSummaryButtonProps } from "./AlectifySummaryButton.interface";
import AlectifyEmpty from "../empty/AlectifyEmpty";
import AiSummaryIcon from "components/icons/AiSummaryIcon";
import React, { useState, useEffect, useRef } from "react";
import AlectifyButton from "../button";
import AlectifyText from "static/texts.json";

const AlectifyAiSummaryButton: React.FC<IAlectifyAiSummaryButtonProps> = (
  props: IAlectifyAiSummaryButtonProps,
) => {
  const [placement, setPlacement] = useState<"topLeft" | "bottomLeft">(
    "bottomLeft",
  );
  const [activeButtonId, setActiveButtonId] = useState<string | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    buttonId: string,
  ) => {
    setActiveButtonId(buttonId);

    const buttonRect = event.currentTarget.getBoundingClientRect();
    const spaceAbove = buttonRect.top;
    const spaceBelow = window.innerHeight - buttonRect.bottom;
    if (spaceBelow < 300 && spaceAbove > 300) {
      setPlacement("topLeft");
    } else {
      setPlacement("bottomLeft");
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setActiveButtonId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const content = (
    <>
      <Divider className="m-0" />
      {props.content ? (
        <div
          dangerouslySetInnerHTML={{ __html: (props.content as any) || "" }}
          className="ai-summary-content-container"
        />
      ) : (
        <AlectifyEmpty description="No Summary Found" />
      )}
    </>
  );

  return (
    <Tooltip title={AlectifyText.AI_SUMMARY}>
      <div className="alectify-ai-summary-container" ref={buttonRef}>
        <Popover
          content={content}
          title={`AI Summary for ${props.name}`}
          trigger="click"
          placement={placement}
          overlayClassName={
            placement !== "topLeft"
              ? "custom-ai-summary-popover"
              : "custom-ai-summary-popover-top"
          }
          arrow={{ pointAtCenter: true }}
        >
          <AlectifyButton
            type="default"
            text="AI Summary"
            className={`alectify-ai-summary-button ${
              activeButtonId === props.key ? "clicked" : ""
            }`}
            icon={<AiSummaryIcon />}
            onClick={(e) => handleButtonClick(e, props.key)}
            disabled={props.disabled || false}
          >
            AI Summary
          </AlectifyButton>
        </Popover>
      </div>
    </Tooltip>
  );
};

export default AlectifyAiSummaryButton;
