//libs
import React, { useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { get, isEmpty, truncate } from "lodash";
import { useSelector } from "react-redux";
import { Document, Page, View, Text, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
//components
import TaskIconPDF from "components/icons/pdf-icons/TaskIconPdf";
import PmExternalIconPDF from "components/icons/pdf-icons/PmExternalIconPdf";
import PmInternalIconPdf from "components/icons/pdf-icons/PmInternalIconPdf";
import EventDelayedIconPdf from "components/icons/pdf-icons/EventDelayedIconPdf";
import EventPendingIconPdf from "components/icons/pdf-icons/EventPendingIconPdf";
import EventCompletedIconPdf from "components/icons/pdf-icons/EventCompletedIconPdf";
import EventCompletedPastDueDateIconPdf from "components/icons/pdf-icons/EventCompletedPastDueDateIconPdf";
import EventSkippedIconPdf from "components/icons/pdf-icons/EventSkippedIconPdf";

//helpers, interface & enums
import { formatDate } from "utils/helpers";
import { ICalendarTemplateProps } from "./CalendarPdf.interface";
import { IRootState } from "redux/rootReducer";
import { CalendarEventStatusEnum, CalendarEventTypesEnum } from "enums";
import AlectifyText from "static/texts.json";

//styles
import { styles } from "./CalendarPdfStyles";

const EVENT_ICONS_PDF = {
  [CalendarEventTypesEnum.TASKS]: <TaskIconPDF />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIconPDF />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIconPdf />,
};
const EVENT_STATUS_ICONS = {
  [CalendarEventStatusEnum.DELAYED]: <EventDelayedIconPdf />,
  [CalendarEventStatusEnum.PENDING]: <EventPendingIconPdf />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <EventCompletedIconPdf />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <EventCompletedPastDueDateIconPdf />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <EventSkippedIconPdf />,
};

const EVENT_BORDER_COLOR: any = {
  [CalendarEventStatusEnum.DELAYED]: "#d92323",
  [CalendarEventStatusEnum.PENDING]: "#3e74dd",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.SKIPPED]: "#d7d7d7",
};

const CalendarMonthlyTemplate: React.FC<ICalendarTemplateProps> = (
  props: ICalendarTemplateProps,
) => {
  moment.tz.setDefault(moment.tz.guess());

  const { data } = props.calendarEvents;
  const { user } = useSelector((state: IRootState) => state.auth);

  const weeksInMonth = [];
  const startDate = moment(props.startDate);
  const endDate = moment(props.endDate);

  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate)) {
    weeksInMonth.push(currentDate.clone());
    currentDate.add(1, "week");
  }

  const isLeapYear = startDate.isLeapYear();
  if (
    isLeapYear &&
    startDate.isSameOrBefore(moment(startDate).endOf("month").date(29))
  ) {
    weeksInMonth.push(moment(startDate).endOf("month").date(29).clone());
  }

  const Template = (
    <Document>
      <Page key={"introPage"} orientation="landscape">
        <View style={styles.titlePageContainer}>
          <View>
            <Text style={styles.titlePageHeading}>
              {AlectifyText.MONTHLY_WORK_ORDERS}
            </Text>
          </View>
          <View>
            <Text style={styles.titlePageSubHeading}>
              {moment(endDate).format("MMMM YYYY")}
            </Text>
          </View>
          <View>
            <Text style={{ ...styles.titlePageSubHeading, marginTop: 10 }}>
              Generated by: {`${user?.first_name} ${user?.last_name}`}
            </Text>
          </View>
          <View>
            <Text style={{ ...styles.titlePageSubHeading }}>
              {moment().format("MMMM DD, YYYY, h:mm A")}
            </Text>
          </View>
        </View>
      </Page>
      {weeksInMonth.map((weekStartDate, index) => (
        <Page key={index} orientation="landscape">
          <View style={styles.container}>
            <View
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <View style={styles.legendDateHeading}>
                <Text>
                  {weekStartDate.format("DD MMM")} -{" "}
                  {weekStartDate
                    .clone()
                    .add(6, "days") // Add 6 days to get the end of the week
                    .format("DD MMM, YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <View style={styles.legengContainer}>
                  <View style={styles.legend}>
                    <EventPendingIconPdf />{" "}
                    <Text style={{ marginLeft: 5 }}>
                      Open (Future Due Date)
                    </Text>
                  </View>
                  <View style={styles.legend}>
                    <EventDelayedIconPdf />{" "}
                    <Text style={{ marginLeft: 5 }}>Open (Over Due)</Text>
                  </View>
                  <View style={styles.legend}>
                    <EventCompletedIconPdf />{" "}
                    <Text style={{ marginLeft: 5 }}>Closed (On Time)</Text>
                  </View>
                  <View style={styles.legend}>
                    <EventCompletedPastDueDateIconPdf />{" "}
                    <Text style={{ marginLeft: 5 }}>
                      Closed (Due Date Passed)
                    </Text>
                  </View>
                  <View style={styles.legend}>
                    <EventSkippedIconPdf />{" "}
                    <Text style={{ marginLeft: 5 }}>Skipped</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              {/* Render events for each day in the week */}
              {Array(7)
                .fill(0)
                .map((_, dayIndex) => {
                  const date = weekStartDate.clone().add(dayIndex, "day");
                  const eventsForDay = data?.filter((event) => {
                    const eventStart = moment(event.start)
                      .local()
                      .startOf("day")
                      .format();
                    return (
                      eventStart === date.format() ||
                      (date.month() === 1 &&
                        date.date() === 29 &&
                        moment(event.start).local().month() === 1 &&
                        moment(event.start).local().date() === 29)
                    );
                  });
                  return (
                    <View key={dayIndex} style={styles.column}>
                      <View key={index} style={styles.header}>
                        <Text style={styles.rowChild}>
                          {formatDate(dayjs(date as any), "dddd, DD MMM")}
                        </Text>
                      </View>
                      {eventsForDay?.map((event) => (
                        <View
                          key={index}
                          style={{
                            ...styles.event,
                            backgroundColor: event.master_project[0].color,
                          }}
                        >
                          <View
                            style={{
                              ...styles.eventIcon,
                              borderColor:
                                EVENT_BORDER_COLOR[event.event_status],
                            }}
                          >
                            {
                              EVENT_ICONS_PDF[
                                event.type as CalendarEventTypesEnum
                              ]
                            }
                            <View style={styles.eventStatusIcon}>
                              {
                                EVENT_STATUS_ICONS[
                                  event.event_status as CalendarEventStatusEnum
                                ]
                              }
                            </View>
                          </View>
                          <View style={styles.eventContent}>
                            <Text style={styles.eventTitle}>
                              {truncate(event.title, {
                                length: 25,
                                omission: "...",
                              }) || "NA"}
                            </Text>
                            <Text style={styles.eventDescription}>
                              {`${truncate(
                                get(event, "asset[0].name")
                                  ? get(event, "asset[0].name")
                                  : get(event, "asset_package[0].name"),
                                {
                                  length: 20,
                                  omission: "...",
                                },
                              )}`}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  );
                })}
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
  useEffect(() => {
    if (!isEmpty(props.calendarEvents.data)) {
      const generatePDF = async () => {
        const doc = Template;

        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(
          blob,
          `calendar-${moment().format("YYYY-MM-DD").toString()}.pdf`,
        );
      };

      generatePDF();
    }
  }, [props.calendarEvents]);
  return null;
};

export default CalendarMonthlyTemplate;
