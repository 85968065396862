import { isEmpty } from "lodash";
import DayJs from "dayjs";
import {
  IPMCreateEditFormProps,
  IPMFormFieldsInitialState,
} from "./PMCreateEditForm.interace";
import {
  PM_CREATE_EDIT_FORM_FIELD_TYPES,
  PM_EDITING_TYPES,
} from "./PMCreateEditForm.constants";
import { MutableRefObject } from "react";
import { IUser } from "redux/components/Auth";
import { isExternalUser } from "utils/helpers";
import { IMasterProject } from "redux/components/master-project";
import { ITask } from "redux/components/tasks";

export const createPayload = (
  values: any,
  state: IPMFormFieldsInitialState,
  props: IPMCreateEditFormProps,
  pmDetails: ITask | null,
) => {
  const {
    ASSIGNEES,
    APPROVERS,
    RECURRING,
    FREQUENCY,
    DAY_DATE,
    WEEK,
    DAY,
    DATE,
  } = PM_CREATE_EDIT_FORM_FIELD_TYPES;
  const pm = pmDetails;

  const teamInfo =
    state?.selectedMembertype === "users" && state.selectedTeamMembers
      ? {
          teamMembers: state?.selectedTeamMembers?.map(
            (member: any) => member.id,
          ),
        }
      : state?.selectedMembertype === "teams" && state.selectedTeamMembers
      ? { teamId: state?.selectedTeamId }
      : undefined;

  const payload = {
    ...values,
    assignees: values[ASSIGNEES],
    approvers: values[APPROVERS],
    assets: undefined,
    ...teamInfo,
    dueDate: DayJs(values["dueDate"]).hour(6).minute(0).format(),
    projectId: !props.isMyWorkOrder
      ? props.masterProjectId
      : state.selectedMasterProject?.id || props.masterProjectId,
    subProjectId: props.subProjectId || state.selectedSubProject?.id,
    isRecurring: values[RECURRING] || false,
    frequency: values[FREQUENCY] || null,
    frequencyType: values.frequencyType?.toUpperCase(),
    dayType: values[DAY_DATE],
    week: values[WEEK],
    day: values[DAY] || null,
    date: values[DATE],
    procedureLibraryId:
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      null,
    assetLevel: pm?.isGeneric ? "generic" : state.assetLevel,
  };
  if (!props.editing || props.copying) {
    payload.procedureLibraryId =
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      state.selectedProcedureLibrary?.id ||
      null;
    payload.subProjectId = state.selectedSubProject?.id;
    if (state.assetLevel === "asset") {
      payload.assetId = state.assetDetail?.id;
      payload.search_area = state.assetDetail?.id;
    } else {
      console.log("selected asset package", state.selectedAssetPackage);
      payload.areaId = state.selectedAssetPackage?.id;
      payload.search_area = state.selectedAssetPackage?.id;
    }
  }

  if (props.editing && !props.copying) {
    delete payload.assignees;
    delete payload.approvers;
    delete payload.subProject;
    delete payload.masterProject;
    payload.detail = values[PM_CREATE_EDIT_FORM_FIELD_TYPES.DETAILS];
    payload.preferredSupplierName = !isEmpty(state.selectedSupplier)
      ? state?.selectedSupplier?.name
      : pmDetails?.preferredSupplier?.name || null;
    payload.changeAllFuturePM = props.editType === PM_EDITING_TYPES.ALL_EVENTS;
    payload.assetId = pmDetails?.asset?.id || null;
    payload.areaId = pmDetails?.area?.id;
    payload.dueDate = DayJs(values["dueDate"]).hour(6).minute(0).format();
    payload.procedureLibraryId =
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      state.selectedProcedureLibrary?.id ||
      null;
  }
  delete payload.subProject;
  delete payload.masterProject;
  delete payload.members;
  if (props.copying) {
    payload[ASSIGNEES] = convertArrayToStrings(payload[ASSIGNEES]);
    payload[APPROVERS] = convertArrayToStrings(payload[APPROVERS]);
  }
  return payload;
};

export const initializeFormFields = (
  props: IPMCreateEditFormProps,
  pmDetails: ITask,
  activeMasterProject: IMasterProject | null,
  ckEditorRef: MutableRefObject<string>,
  getAssetDetails: (_: any, record: any) => void,
  getAssetPackageDetails: (_: any, record: any) => void,
  onChangeRecurring: (recurring: boolean) => void,
  onChangePMCategory: (value: string) => void,
  getSubProjectDettail: (subProjectId: string, basicInfo: any) => void,
  onFrequencyTypeChange: (value: string, dayType?: string) => void,
) => {
  const pm = pmDetails;

  if (props.editing) {
    if (pmDetails?.taskCategory) {
      onChangePMCategory(pmDetails?.taskCategory);
    }
    if (!pm?.isGeneric) {
      if (pmDetails?.area) {
        getAssetPackageDetails(null, pmDetails?.area);
      } else {
        getAssetDetails(null, pmDetails?.asset);
      }
    } else if (pm?.isGeneric) {
      getSubProjectDettail(pm.subProject.id, pm.subProject);
    }

    if (!isEmpty(pmDetails)) {
      ckEditorRef.current = pmDetails?.detail || "";
      // onDayDateChange(pmDetails?.dayType);
      onChangeRecurring(
        pmDetails?.isRecurring || pmDetails?.isRecurring || false,
      );

      onFrequencyTypeChange(
        pmDetails?.frequencyType || (null as any),
        pmDetails?.dayType || "",
      );
      const pmAssigneesToMap = pmDetails?.assignees;
      const assigneesValue =
        pmAssigneesToMap &&
        pmAssigneesToMap.map((assignee: any) => ({
          ...assignee,
          label: `${assignee.user.first_name} ${assignee.user.last_name}`,
          name: assignee.user.first_name,
          value: assignee.user.id,
        }));
      const pmTeamMember = isEmpty(pmDetails?.teamMembers)
        ? pmDetails?.team?.projectTeamMembers
        : pmDetails?.teamMembers;
      const teamSValue = pmTeamMember?.map((member: any) => member?.user?.id);

      const pmApproversToMap = pmDetails?.approvers;
      const approversValue =
        pmApproversToMap &&
        pmApproversToMap.map((approvers: any) => ({
          ...approvers,
          label: `${approvers.user.first_name} ${approvers.user.last_name}`,
          name: approvers.user.first_name,
          value: approvers.user.id,
        }));
      const fieldsToSet = [
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.MASTER_PROJECT,
          value: pmDetails?.project.name,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.SUB_PROJECT,
          value: pmDetails?.subProject.name,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.PM_TRACKING,
          value: pm?.isGeneric ? "generic" : "asset",
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSETS,
          value: pmDetails?.asset?.name || pmDetails?.area?.name,
        },
        // {
        //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSET_PACKAGE,
        //   value: pmDetails?.area?.name,
        // },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.WORK_TITLE,
          value: pmDetails?.workTitle,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DETAILS,
          value: pmDetails?.detail,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TEAM_MEMBERS_LIST,
          value: teamSValue,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSIGNEES,
          value: assigneesValue,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.APPROVERS,
          value: approversValue,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.RECURRING,
          value: pmDetails?.isRecurring || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.PREFERRED_SUPPLIER,
          value: pmDetails?.preferredSupplier?.name || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY,
          value: pmDetails?.frequency || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY_TYPE,
          value: pmDetails?.frequencyType || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY_DATE,
          value: pmDetails?.dayType,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK,
          value: pmDetails?.week || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
          value: pmDetails?.day || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE,
          value: pmDetails?.date || null,
        },
        // {
        //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.CONTRACT_END_DATE,
        //   value: DayJs(pmDetails?.contractEndDate) || null,
        // },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DUE_DATE,
          value: DayJs(pmDetails?.dueDate) || null,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY,
          value: pmDetails?.taskCategory,
        },
        {
          name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_PRIORITY,
          value: pmDetails?.priority,
        },
      ];

      if (props.isTask && !isEmpty(pmDetails)) {
        // fieldsToSet.push({
        //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY,
        //   value: props.pmExternal.taskCategory,
        // });
        // fieldsToSet.push({
        //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_PRIORITY,
        //   value: props.pmExternal.priority,
        // });
      }
      props.FormInstance.setFields(fieldsToSet);
    }
  } else {
    const fieldsToSet = [
      /*  {
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.MASTER_PROJECT,
        value: activeMasterProject?.name || null,
      }, */
      {
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.PM_TRACKING,
        value: "asset",
      },
      // {
      //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY,
      //   value: 1,
      // },
      {
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY_DATE,
        value: "date",
      },
      {
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK,
        value: "first",
      },
      // {
      //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
      //   value: PM_DAYS_NAME[0].value,
      // },
      // {
      //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE,
      //   value: 1,
      // },
    ];
    props.FormInstance.setFields(fieldsToSet);
  }
};

export const initializeFormFieldsOnCreate = (
  props: IPMCreateEditFormProps,
  activeMasterProject: IMasterProject | null,
) => {
  const fieldsToSet = [
    {
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.MASTER_PROJECT,
      value: activeMasterProject?.name || null,
    },
    // {
    //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.PM_TRACKING,
    //   value: "asset",
    // },
    // {
    //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY,
    //   value: 1,
    // },
    {
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY_DATE,
      value: "date",
    },
    // {
    //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK,
    //   value: "first",
    // },
    // {
    //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
    //   value: PM_DAYS_NAME[0].value,
    // },
    // {
    //   name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE,
    //   value: 1,
    // },
  ];
  props.FormInstance.setFields(fieldsToSet);
};

/**
 * Craates Assignees list for assignees dropdown on the basis of user type and pm type.
 * @param { IUsers } users - list of team members.
 * @param { boolean } isPmExternal - boolean on pm type if its external then it will be true otherwise it will be false.
 */
export const createAssigneeListForPM = (users: IUser[]) => {
  if (!isEmpty(users)) {
    // if (isPmExternal) {
    return users.map((user: IUser) => ({
      label: `${user?.first_name} ${user?.last_name} ${
        isExternalUser(user) ? "(External)" : ""
      }`,
      value: user?.id,
      ...user,
    }));
    // }
    /* else {
      return users
        .filter((user: IUser) => !isExternalUser(user))
        .map((user: IUser) => ({
          label: user.first_name,
          value: user.id,
          ...user,
        }));
    } */
  }
};

function convertArrayToStrings(arr: any[]) {
  // Check if the array contains objects
  const containsObjects = arr.some((item) => typeof item === "object");

  // If the array contains objects, convert them to strings
  if (containsObjects) {
    return arr.map((item) =>
      typeof item === "object" ? item?.user?.id : item,
    );
  }

  // If the array doesn't contain objects, return the original array
  return arr;
}
