import { memo, useEffect, useRef } from "react";
import { Tooltip } from "antd";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { extractFileName } from "utils/helpers";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import PreviewPdf from "components/shared/preview-pdf";
import PdfIcon from "components/icons/PdfIcon";
import DocIcon from "components/icons/DocIcon";
import { ChatBotIcon } from "components/icons";
import { IChatBotItems, IChatBotSourceProps } from "./Chatbot.interface";
import TypingIndicator from "components/typing-indicator";
import AlectifyText from "static/texts.json";
import "./Chatbot.scss";

const ChatBotSource: React.FC<IChatBotSourceProps> = ({ data, ref }) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data.chatbotItems]);
  return (
    <div ref={chatContainerRef} style={{ overflowY: "auto" }}>
      {data.chatbotItems?.map((message: IChatBotItems, index) => {
        const isUser = message?.role === "user";
        return (
          <div key={index} className="chatbot-conversion-container">
            <div className={isUser ? "user-question" : "received-message"}>
              <div className="mt-5">{!isUser && <ChatBotIcon />}</div>
              <div className={isUser ? "user-text" : "assistant-text"}>
                {!message?.temporary ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: message?.content || AlectifyText.DATABASE_ERROR,
                    }}
                  />
                ) : (
                  <TypingIndicator text={"Typing"} />
                )}
              </div>
            </div>
            {message?.sources?.map((source: any, sourceIndex) => (
              <div className="read-page-container" key={sourceIndex}>
                <h3>
                  Read Page No: <span>{source?.page_number || "-"}</span>
                </h3>
                <Tooltip title={source?.file_name || "-"}>
                  <div
                    className="pdf-container"
                    onClick={() => {
                      ModalServiceInstance.open(AlectifyModal, {
                        title: `${extractFileName(source?.file_name)}- Preview`,
                        name: MODAL_NAMES.PREVIEW_PDF_MODAL,
                        width: 1300,
                        style: { top: 20, right: 250 },
                        footer: null,
                        className: "alectify-pdf-viewer-container",
                        onCancel: () => {
                          ModalServiceInstance.close(AlectifyModal, {
                            name: MODAL_NAMES.PREVIEW_PDF_MODAL,
                          });
                        },
                        children: (
                          <PreviewPdf
                            documentUrl={source?.file_name}
                            pageNumber={source?.page_number}
                            zoom={80}
                          />
                        ),
                      });
                    }}
                  >
                    <div className="icon-font-size-16 mr-5">
                      {source?.file_name.toLowerCase().endsWith(".pdf") ? (
                        <PdfIcon />
                      ) : (
                        <DocIcon />
                      )}
                    </div>
                    <p>{extractFileName(source?.file_name)}</p>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default memo(ChatBotSource);
