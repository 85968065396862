import { Button, Form } from "antd";
import { ITeamMemberComponentProps } from "./TeamMember.interface";
import { PlusOutlined } from "@ant-design/icons";
import AlectifyText from "static/texts.json";
import AvatarGroup from "../avatar-group";

import "./TeamMember.scss";

const TeamMember: React.FC<ITeamMemberComponentProps> = (
  props: ITeamMemberComponentProps,
) => {
  const { users, onAdd, disable } = props;
  return (
    <div className="alectify-team-members-container">
      <Form.Item
        name="members"
        className="members"
        label={
          <div className="alectify-team-memebers-label">
            {" "}
            <div>{AlectifyText.SELECTED_TEAM_MEMBERS}</div>
            {/* <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={onAdd}
              disabled={disable}
            >
              Add New
            </Button> */}
          </div>
        }
      >
        <AvatarGroup users={users} maxCount={props.maxCount} showImage />
      </Form.Item>
    </div>
  );
};
export default TeamMember;
