import React, { ReactElement, useEffect, useState } from "react";
import { Avatar, Form, Radio, RadioChangeEvent, Select, Space } from "antd";
import { ITeamMembersDropdownProps } from "./TeamMemnersDropdown.interface";
import { fetchAllUsers } from "services/users/users.service";
import { IUser } from "redux/components/Auth";
import { isEmpty } from "lodash";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import { isExternalUser } from "utils/helpers";
import { fetchTeamsList } from "services/teams/teams.service";
import { ITeams } from "services/teams/teams.interface";
import AvatarGroup from "../avatar-group";

import "./TeamMembersDropdown.scss";
const { Option, OptGroup } = Select;

const TeamMembersDropdown: React.FC<ITeamMembersDropdownProps> = (
  props: ITeamMembersDropdownProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>(props.users || []);
  const [teams, setTeams] = useState<ITeams[]>([]);
  const [selectedRadio, setSelectedRadio] = useState<string>("teams");
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    props.value || props.selectedOptions || [],
  );

  const getUsers = async () => {
    setLoader(true);
    try {
      const response = await fetchAllUsers(props.projectId || "");
      if (response.success) {
        setUsers(response.data);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoader(false);
    }
  };

  const getTeamsList = async () => {
    const params = {
      is_active: "true",
    };
    setLoader(true);
    try {
      const response = await fetchTeamsList(
        props.projectId || "",
        params as any,
      );
      if (response.success) {
        setTeams(response.data);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setSelectedOptions([]);
    if (!isEmpty(props.projectId)) {
      if (isEmpty(props.users)) {
        getUsers();
      }
      getTeamsList();
    }
  }, [props.projectId]);

  useEffect(() => {
    if (!props?.showTeamOption) {
      setSelectedRadio("users");
    }
  }, [props?.showTeamOption]);

  useEffect(() => {
    if (props.pmData && props.iscoping) {
      if (!isEmpty(props.pmData.team)) {
        setSelectedRadio("teams");
        setSelectedOptions([props.pmData.team.id]);
      } else if (
        props.pmData.teamMembers &&
        !isEmpty(props.pmData.teamMembers)
      ) {
        setSelectedRadio("users");
        setSelectedOptions(
          props.pmData.teamMembers.map((member: any) => member?.user?.id),
        );
      }
    }
  }, [props.pmData, props.iscoping]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    const value = e.target.value as string;
    setSelectedRadio(value);
    setSelectedOptions([]);
    if (props.onSelectType) {
      props.onSelectType(value);
    }
  };

  const handleSelectChange = (value: string[]) => {
    setSelectedOptions(value);
  };

  const element: ReactElement = (
    <>
      {props?.showTeamOption && (
        <Radio.Group
          onChange={handleRadioChange}
          value={selectedRadio}
          style={{ marginBottom: 8 }}
          className="d-flex width-100 gap-15"
        >
          <Radio value="teams" className="alectify-teams-users-radio">
            Teams
          </Radio>
          <Radio value="users" className="alectify-teams-users-radio">
            Users
          </Radio>
        </Radio.Group>
      )}
      <Select
        mode={selectedRadio === "users" ? "multiple" : undefined}
        style={{ width: "100%" }}
        placeholder={`Select ${
          selectedRadio === "teams" ? "One team" : "multiple Users"
        }`}
        loading={loader}
        optionFilterProp="children"
        onChange={handleSelectChange}
        value={selectedOptions}
        defaultValue={selectedOptions}
        disabled={props?.disable}
        {...props}
      >
        {selectedRadio === "teams" ? (
          <>
            {teams.map((team: ITeams) => (
              <Option key={team.id} value={team.id} label={team.name}>
                <Space
                  direction="horizontal"
                  className="d-flex width-100  justify-content-space-between"
                >
                  <span>{team?.name}</span>
                  <AvatarGroup
                    users={team?.team_members}
                    size={"small"}
                    maxCount={4}
                  />
                </Space>
              </Option>
            ))}
          </>
        ) : (
          <OptGroup label="Select Multiple Users">
            {users.map((user: IUser) => (
              <Option
                key={user.id}
                value={user.id}
                label={`${user.first_name} ${user.last_name}`}
              >
                <Space direction="horizontal">
                  <Avatar size={"small"} src={user.image || NO_IMAGE_AVATAR} />
                  <span>{user.first_name}</span>
                  <span>
                    {user.last_name}{" "}
                    {isExternalUser(user) ? "(External)" : <></>}
                  </span>
                </Space>
              </Option>
            ))}
          </OptGroup>
        )}
      </Select>
    </>
  );
  return props.formItem ? (
    <Form.Item
      name="teamMembers"
      label="Team Members"
      rules={[
        {
          required: props?.isRequired ? true : false,
          message: "Please select at least one team member",
        },
      ]}
    >
      {element}
    </Form.Item>
  ) : (
    <>{element}</>
  );
};

TeamMembersDropdown.defaultProps = {
  projectId: "",
  users: [],
  formItem: true,
};

export default TeamMembersDropdown;
