import { memo } from "react";

interface IBackArrowIconProps {
  onClick?: () => void;
  className?: string;
}
const BackArrowIcon: React.FC<IBackArrowIconProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M7.0057 2.13614L7.00578 2.13604C7.13247 1.9863 7.19932 1.78944 7.19932 1.58974C7.19932 1.39005 7.13247 1.19319 7.00578 1.04345L7.00562 1.04326C6.8779 0.892673 6.69696 0.8 6.49975 0.8C6.30253 0.8 6.12159 0.892673 5.99388 1.04326L5.9938 1.04335L0.993626 6.94599L0.993547 6.94608C0.866856 7.09582 0.8 7.29268 0.8 7.49238C0.8 7.69207 0.866856 7.88893 0.993547 8.03868L0.993626 8.03877L5.99173 13.939C6.05327 14.0162 6.12898 14.0803 6.21561 14.1259C6.3035 14.1721 6.40006 14.1978 6.49923 14.1999C6.59842 14.2019 6.69594 14.1803 6.78556 14.1376C6.87502 14.0949 6.95382 14.0326 7.01839 13.9564C7.08291 13.8802 7.13232 13.7913 7.16507 13.6955C7.19783 13.5998 7.21366 13.4981 7.21214 13.3963C7.21061 13.2945 7.19176 13.1934 7.15606 13.0989C7.12082 13.0055 7.06947 12.9193 7.00362 12.8462L3.13782 8.28264H13.5C13.6974 8.28264 13.8784 8.18982 14.0062 8.03903L13.8535 7.90976L14.0062 8.03903C14.133 7.88925 14.2 7.69223 14.2 7.49238C14.2 7.29252 14.133 7.09551 14.0062 6.94573L13.8535 7.075L14.0062 6.94573C13.8784 6.79494 13.6974 6.70212 13.5 6.70212H3.13782L7.0057 2.13614Z"
        fill="black"
        stroke="black"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export default memo(BackArrowIcon);
