import { memo } from "react";
import { Col, Divider, Row, Space, Tooltip, Typography } from "antd";
import AvatarGroup from "components/shared/avatar-group";
import { IncidentDetailProps } from "../../pages/incident-report/Incident.interface";
import CrossIcon from "components/icons/CrossIcon";
import {
  displayDateTime,
  extractUserFromTeamMembers,
  truncateText,
} from "utils/helpers";
import { isEmpty } from "lodash";
import AlectifyText from "static/texts.json";
import TextEditor from "components/text-editor/TextEditor";
import AlectifyChip from "components/shared/chips/AlectifyChip";
import AttachmentIcon from "components/icons/AttachmentIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import IncidentReportsDocuments from "./IncidentReportsDocuments";
import "./IncidentReport.scss";
import { TASK_PROIRITY_COMPONENTS } from "components/shared/tasks-table/effects/useTasksColumns";
import { StatusEnum, TaskPriorityEnum } from "enums";
import { INCIDENT_STATUS } from "./effects/useIncidentReportsColumn";

const IncidentDetail: React.FC<IncidentDetailProps> = ({
  closeIncidentDetail,
  detail,
}) => {
  const openAttachmentsDrawer = (documents: any) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 860,
      closeIcon: true,
      title: AlectifyText.ATTACHMENTS,
      name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
        });
      },
      children: <IncidentReportsDocuments details={documents} />,
    });
  };

  return (
    <div className="incident-detail-container">
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Space>
            <Typography.Title level={5} className="m-0">
              <Space>
                {`(${detail.incidentId || ""})`}
                {truncateText(detail?.title || "-", 50)}
              </Space>
            </Typography.Title>
            {detail?.isDraft && (
              <AlectifyChip
                boxShadow
                text="Drafted"
                isRound
                backgroundColor="#E8E8E8"
                textColor="#888181"
              />
            )}
          </Space>
        </Col>
        <Col span={12} className="text-align-right">
          <Tooltip title={"Close"}>
            <span className="cursor-pointer" onClick={closeIncidentDetail}>
              <CrossIcon fill={"#888181"} />
            </span>
          </Tooltip>
        </Col>
      </Row>
      <Divider className="mt-5 mb-5" />
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Space>
            <div className="site-container">
              <span>Incident Date:</span>
              {displayDateTime(detail?.incidentDate, false)}
            </div>
          </Space>
        </Col>
        <Col span={12} className="text-align-right">
          <Space>
            {detail?.priority === TaskPriorityEnum.CRITICAL &&
              TASK_PROIRITY_COMPONENTS[detail?.priority as TaskPriorityEnum]}

            {INCIDENT_STATUS[detail.status as StatusEnum] || "-"}
          </Space>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"middle"} className="mt-10">
        <Col span={12}>
          <Space>
            <span>Site:</span>
            <span>{detail?.project?.name}</span>
          </Space>
        </Col>
        <Col span={12} className="text-align-right">
          <Space>
            <span>Created by:</span>
            <AvatarGroup users={[detail?.createdBy]} maxCount={3} showImage />
          </Space>
        </Col>
        <Col span={12} className="mt-10">
          <Space>
            <>
              {(detail?.team?.name && (
                <Space>
                  <span>
                    <Space>
                      Team:
                      <Tooltip title={detail.team.name}>
                        {detail.team.name}
                      </Tooltip>
                    </Space>
                  </span>
                  <AvatarGroup
                    users={
                      !isEmpty(detail.teamMembers)
                        ? extractUserFromTeamMembers(detail.teamMembers)
                        : detail.team?.projectTeamMembers?.map(
                            (d: any) => d.user,
                          )
                    }
                    maxCount={2}
                    showImage
                  />
                </Space>
              )) || (
                <Space>
                  <span>Team Members: </span>
                  <AvatarGroup
                    users={
                      !isEmpty(detail.teamMembers)
                        ? extractUserFromTeamMembers(detail.teamMembers)
                        : detail.team?.projectTeamMembers?.map(
                            (d: any) => d.user,
                          )
                    }
                    maxCount={2}
                    showImage
                  />
                </Space>
              )}
            </>
          </Space>
        </Col>
        <Col span={12} className="text-align-right">
          <Space>
            Attachments:
            <div
              className="attachment-count-container"
              onClick={() => openAttachmentsDrawer(detail?.documents || [])}
            >
              <AttachmentIcon />
              <span>{detail?.documents?.length || 0}</span>
            </div>
          </Space>
        </Col>
      </Row>
      <div className="detail-incident-box mt-10">
        <div className="incident-report-content">
          <TextEditor initialValue={detail?.description} readOnly />
        </div>
      </div>
    </div>
  );
};

export default memo(IncidentDetail);
