import GroupCheckpointsTable from "../group-checkpoint-table/GroupCheckpointsTable";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { useForm } from "antd/lib/form/Form";
import { IManageGroupsProps } from "./ManageGroups.interface";

const ManageGroups: React.FC<IManageGroupsProps> = (
  props: IManageGroupsProps,
) => {
  const [FormInstance] = useForm();

  const onClose = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_NEW_GROUP_CHECKPOINT,
    });
  };
  return (
    <GroupCheckpointsTable
      projectId={props.projectId}
      displayActionButton
      onClose={onClose}
      form={FormInstance}
      showExtraButton
      showAddButton
    />
  );
};

export default ManageGroups;
