import { Space, Tag } from "antd";
import AssetPackagesIcon from "components/icons/AssetPackagesIcon";
import AlectifyText from "static/texts.json";
import { CHIP_COLORS } from "../phone-number/constants";

const AssetPackageTag: React.FC<{ count?: number }> = ({ count }) => {
  return (
    <Tag
      color={CHIP_COLORS.ASSET_PACKAGE}
      className="asset-package-tag round-tag"
    >
      <Space size={2}>
        <AssetPackagesIcon fill="#fff" />{" "}
        {`${AlectifyText.PARENT_ASSET_NAME}${count ? `: ${count}` : ""}`}
      </Space>
    </Tag>
  );
};

export default AssetPackageTag;
