import {
  Avatar,
  Col,
  Collapse,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
  Steps,
  Typography,
  Upload,
} from "antd";
import { ReactNode, useState } from "react";
import { ITask } from "redux/components/tasks";
import {
  NO_IMAGE_AVATAR,
  NO_PHOTO_AVAILABLE,
} from "components/shared/assets-table/AssetsTable.constants";

import AlectifyText from "static/texts.json";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import TextToLink from "components/shared/text-to-link";
import { IProcedure } from "services/procedures/procedures.interface";
import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import StatusCard from "components/shared/status-card/StatusCard";
import { PM_STATUS, PME_ACTION_REQUIRED_ENUM, TaskPriorityEnum } from "enums";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { StepProps } from "antd/lib";
import ViewImage from "components/shared/view-image/ViewImage";
import { displayDateTime, enumToTile } from "utils/helpers";
import { updatePrevMaintenances } from "services/pm-external/pm-external.service";
import { MESSAGES } from "constants/messages";
import DayJs from "dayjs";
import { PM_DETAIL_PRIORITY_COMPONENTS } from "components/shared/tasks-table/effects/useSimpleColumns";

import { PM_TYPES } from "redux/components/pm-external";
import AlectifyDescription from "components/shared/detailed-view/AlectifyDescriptionView";
import WorkOrderSpareParts from "components/shared/work-order-spare-parts";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import ProcedureDetail from "components/shared/procedure-detail";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import "./style.scss";

interface IWorkOrderStatusProps {
  task: ITask | null;
  actionButton: ReactNode;
  loader: boolean;
  prepareRoutes: (type: string) => string;
  setActiveTab: (name: string) => void;
  submittedInformationModal: () => void;
  getPmExternalDetails: (value: any) => void;
  procedure: IProcedure;
  taskId?: string;
  subProjectId?: string;
  referenceId: string;
  isDisabled: boolean;
}

const WorkOrderStatus: React.FC<IWorkOrderStatusProps> = (
  props: IWorkOrderStatusProps,
) => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

  const openImagePreviewModal = (url: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
        });
      },
      name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
      title: "Preview",
      footer: null,
      children: <ViewImage imageUrl={url} />,
    });
  };

  const getStepsItems = (): StepProps[] => {
    const items: StepProps[] = [
      {
        title: "Created Work Order",
        description: (
          <Space direction="vertical" size={1}>
            <span className="status-description-step-date-text">
              {`${displayDateTime(props.task?.createdAt, true, false)}`}
            </span>
            {props.task?.createdAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )}
          </Space>
        ),
        status: (!props.task?.createdAt && "error") || "finish",
        icon: (
          <Avatar
            size={"large"}
            src={props.task?.createdBy?.image_url || NO_IMAGE_AVATAR}
          />
        ),
      },
      {
        title: (
          <Space className="review-text">
            {props.task?.reviewedAt ? (
              <TextToLink
                text={<span>{AlectifyText.SUBMITTED_REVIEW}</span>}
                onClick={() => props.submittedInformationModal()}
              />
            ) : (
              <span>{AlectifyText.SUBMITTED_REVIEW}</span>
            )}
          </Space>
        ),
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completionAt &&
              `${
                (props.task?.reviewedAt &&
                  displayDateTime(
                    (props.task?.reviewedAt as any) || "-",
                    true,
                    false,
                  )) ||
                "-"
              }`}
            {(props.task?.completionAt && (
              <span className="completed-text">[AlectifyText.COMPLETED]</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.completionAt && "finish") || "error",
        icon: props.task?.reviewedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.reviewedBy?.image_url} />
        ),
      },
    ];
    if (!props.task?.skippedAt) {
      items.push({
        title: AlectifyText.REVIEW_COMPLETED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completedAt &&
              `${
                (props.task?.completedAt &&
                  displayDateTime(
                    props.task?.completedAt as any,
                    true,
                    false,
                  )) ||
                "-"
              }`}
            {(props.task?.completedAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.completedAt && "finish") || "error",
        icon: props.task?.completedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.completedBy?.image_url} />
        ),
      });
    }
    if (props.task?.skippedAt) {
      items.push({
        title: AlectifyText.SKIPPED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.skippedAt &&
              `${
                (props.task?.skippedAt &&
                  displayDateTime(props.task?.skippedAt as any, true, false)) ||
                "-"
              }`}
            {(props.task?.skippedAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.skippedAt && "finish") || "error",
        icon: props.task?.skippedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.skippedBy?.image_url} />
        ),
      });
    }
    return items;
  };

  const handleFileUpload = async (file: any) => {
    const { task } = props;
    setIsLoadingImage(true);

    try {
      const formData = new FormData();

      if (task && !isEmpty(task)) {
        const taskFields = [
          { key: "taskCategory", value: task.taskCategory },
          { key: "pm_tracking", value: "asset" },
          { key: "assetLevel", value: task.asset ? "asset" : "area" },
          { key: "workTitle", value: task.workTitle },
          { key: "priority", value: task.priority || "-" },
          { key: "detail", value: task.detail || "-" },
          { key: "projectId", value: task.project?.id },
          { key: "subProjectId", value: task.subProject?.id },
          { key: "dayType", value: task.dayType },
          { key: "day", value: task.day },
          { key: "date", value: task.date },
          { key: "week", value: task.week },
          { key: "frequencyType", value: task.frequencyType?.toUpperCase() },
          { key: "frequency", value: task.frequency },
          { key: "isRecurring", value: task.isRecurring.toString() },
          {
            key: "changeAllFuturePM",
            value: task.isRecurring ? "true" : "false",
          },
          { key: "assetId", value: task.asset?.id },
          { key: "areaId", value: task.area?.id },
          { key: "procedureLibraryId", value: task.procedureLibraryId as any },
          { key: "image", value: file },
          {
            key: "dueDate",
            value:
              task.dueDate && DayJs(task.dueDate).hour(6).minute(0).format(),
          },
        ];

        taskFields.forEach(({ key, value }) => {
          if (value) formData.append(key, value);
        });
      }

      const response = await updatePrevMaintenances(task?.id || "-", formData);
      if (response.status) {
        message.success(MESSAGES.GENERAL_MESSAGES.IMAGE_UPLOADED);
      }
    } catch (error: any) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      console.error(error);
    } finally {
      setIsLoadingImage(false);
      props.getPmExternalDetails?.(task?.id);
    }
  };

  const formatDateFrequency = (
    date: string | number,
    frequency: string | number,
  ) => {
    if (date === 1 && frequency === 1) {
      return "This work order repeats on the first day of every month.";
    }
    if (frequency === 1) {
      return `This work order repeats on the ${date} day of every month.`;
    }
    return `This work order repeats on the ${date} day of every ${frequency} months.`;
  };

  const formatDayFrequency = (
    frequency: string | number,
    day: string | number,
    week: string | number,
  ) => {
    if (frequency === 1) {
      return `This work order repeats every month on ${day} of the ${week} week.`;
    }
    return `This work order repeats every ${frequency} months on ${day} of the ${week} week.`;
  };

  const renderWorkOrderRepeats = () => {
    const { frequencyType, dayType, date, frequency, day, week } =
      props.task ?? {};

    switch (frequencyType) {
      case "WEEKLY":
        return `This work order repeats weekly every ${day}.`;

      case "MONTHLY":
        if (dayType === "date") {
          return formatDateFrequency(date, frequency);
        }
        if (dayType === "day") {
          return formatDayFrequency(frequency, day, week);
        }
        break;

      case "DAILY":
        return "This work order repeats everyday.";

      default:
        return null;
    }
  };

  return (
    <Spin spinning={props.loader}>
      <div className="work-order-status-container">
        <div className="status-asset-section">
          <Row gutter={[6, 6]}>
            <Col sm={24} md={24} lg={4}>
              <div className="alectify-pm-basic-info-image-card">
                <Spin spinning={isLoadingImage}>
                  <div
                    className={`alectify-pm-basic-info-image ${
                      props.task?.imageUrl ? "cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      props.task?.imageUrl &&
                      openImagePreviewModal(props.task?.imageUrl)
                    }
                    style={{
                      backgroundImage: `url(${
                        props.task?.imageUrl || NO_PHOTO_AVAILABLE
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <Upload
                    maxCount={1}
                    accept="image/*"
                    onChange={(info) => {
                      const { fileList } = info;
                      handleFileUpload(fileList[0].originFileObj);
                    }}
                    fileList={[]}
                    beforeUpload={() => false}
                  >
                    <div className="edit-profile-text">
                      {props.task?.imageUrl ? "Change Image" : "Add Image"}
                    </div>
                  </Upload>
                </Spin>
              </div>
            </Col>
            <Col sm={24} md={24} lg={20}>
              <div className="status-work-order-detail-section">
                {!props.loader ? (
                  <Row>
                    <Col span={24}>
                      <span className="pm-detail-title-text">{`(Work Id: ${props.task?.workId} ${props.task?.workTitle}`}</span>

                      <Space className="space-container">
                        <span className="alectify-pm-info-detail-value">
                          {
                            PM_DETAIL_PRIORITY_COMPONENTS[
                              props.task?.priority as TaskPriorityEnum
                            ]
                          }
                          <span className="date-chip">
                            <strong>Due Date:</strong>
                            {displayDateTime(props.task?.dueDate) || "-"}
                          </span>
                        </span>
                        <span className="pm-detail-cm-pm-chip">
                          {enumToTile(
                            props.task?.pmType === PM_TYPES.PM_EXTERNAL
                              ? props.task?.taskCategory
                              : props.task?.pmType || "",
                          )}
                        </span>
                        <div className="site-asset-container">
                          {props.task?.asset?.name || props.task?.area?.name ? (
                            <>
                              <span className="key-text">
                                {props.task?.area?.name
                                  ? "Parent asset:"
                                  : "Sub asset:"}
                              </span>
                              <div
                                className="alectify-pm-info-detail-key-site"
                                onClick={() =>
                                  navigate(props.prepareRoutes("subproject"))
                                }
                              >
                                {props.task?.asset?.name ? (
                                  <span className="text-link">
                                    {props.task.asset.name}
                                  </span>
                                ) : (
                                  <span className="text-link">
                                    {props.task?.area?.name}
                                  </span>
                                )}
                              </div>
                            </>
                          ) : (
                            <span>{AlectifyText.GENERIC_WORK_ORDER}</span>
                          )}
                        </div>{" "}
                      </Space>

                      <span className="sub-info">
                        {renderWorkOrderRepeats()}
                      </span>

                      <div className="description-body">
                        <AlectifyDescription
                          detail={props.task?.detail || ""}
                          height={"80px"}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Skeleton />
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className="border-title d-flex justify-content-space-between align-items-center">
          <Typography.Title level={5} className="mt-5">
            {AlectifyText.WORK_ORDER_STATUS}
          </Typography.Title>

          <span>{props.actionButton}</span>
        </div>

        <Row gutter={[6, 6]} align="middle" className="pt-10 pb-10">
          <Col xs={24} sm={12} md={8}>
            <StatusCard
              text="Assignees"
              helperText={
                getPMEActionRequired(props.task as any) ===
                PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                  ? "(Action Required)"
                  : ""
              }
              users={props.task?.assignees?.map(({ user }) => user) || []}
            />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <StatusCard
              text="Approvers"
              helperText={
                getPMEActionRequired(props.task as any) ===
                PME_ACTION_REQUIRED_ENUM.APPROVERS
                  ? "(Action Required)"
                  : ""
              }
              users={props.task?.approvers?.map(({ user }) => user) || []}
            />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <StatusCard
              text="Team members"
              users={
                (!isEmpty(props.task?.team) &&
                  props.task?.team.projectTeamMembers.map((d) => d.user)) ||
                (!isEmpty(props.task?.teamMembers) &&
                  props.task?.teamMembers?.map((d: any) => d.user)) ||
                []
              }
            />
          </Col>
        </Row>

        <div className="pm-detail-info-steps mb-10">
          <Steps current={3} status={"process"} items={getStepsItems()} />
        </div>
        <div className="p-5 border-title">
          <Typography.Title level={5} className="mt-5">
            {AlectifyText.MORE_INFORMATION}
          </Typography.Title>
        </div>

        <Collapse bordered={false} expandIconPosition="right">
          <Panel
            header={
              <>
                {AlectifyText.SPARE_PARTS_USED} (
                {props.task?.sparePartsUsed?.count || "0"})
              </>
            }
            key="1"
          >
            {props.task && (
              <WorkOrderSpareParts
                record={props.task as any}
                showDrawSparePartsButton={
                  props.task?.status !== PM_STATUS.COMPLETED &&
                  props.task?.status !== PM_STATUS.SKIPPED
                }
                callback={() =>
                  props.task?.id && props?.getPmExternalDetails(props.task?.id)
                }
              />
            )}
          </Panel>
        </Collapse>

        <Collapse bordered={false} className="mt-10" expandIconPosition="right">
          <Panel
            header={
              <>
                {AlectifyText.PROCEDURE_STEPS} (
                {countCheckedSteps(props.task?.procedure?.procedureSteps || [])}
                )
              </>
            }
            key="2"
          >
            {isEmpty(props?.procedure) ? (
              <AlectifyEmpty description="No data" />
            ) : (
              <ProcedureDetail
                procedure={props.procedure}
                taskId={props.taskId}
                subProjectId={props.subProjectId}
                referenceId={props?.referenceId}
                isDisabled={props.isDisabled}
                getPmExternalDetails={props.getPmExternalDetails}
              />
            )}
          </Panel>
        </Collapse>
      </div>
    </Spin>
  );
};

export default WorkOrderStatus;
