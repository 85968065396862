import { Layout, Menu } from "antd";
import {
  menuStyles,
  siderStyle,
  menuStylesSecond,
  listStyles,
  listCenteredStyles,
} from "./Layout.syles";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetDetailSidebar,
  setMasterProjectSidebar,
  setShowCalendarMyWorkOrders,
  setShowMyWorkOrders,
} from "redux/components/common/sources";
import { IRootState } from "redux/rootReducer";
import { AlertIcon, CalendarIcon, LeftArrowIcon } from "components/icons";
import { isExternalUser, isFeatureEnabled } from "utils/helpers";

import ProjectIcon from "components/icons/ProjectIcon";
import AnalyticsIcon from "components/icons/AnalyticsIcon";
import TasksIcon from "components/icons/TasksIcon";
import InventoryIcon from "components/icons/InventoryIcon";
import ContactsIcon from "components/icons/ContactsIcon";
import ProcedureIcon from "components/icons/ProceduresIcon";
import {
  PROJECT_THEME_COLOR,
  PROJECT_THEME_COLOR_SECONDARY,
  PROJECT_WHITE_COLOR,
} from "components/shared/phone-number/constants";
import ContactUsIcon from "components/icons/ContactUsIcon";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ContactUsForm from "components/shared/contact-us-form";
import AlectifyText from "static/texts.json";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import AssetsIcon from "components/icons/AssetsIcon";
import DataRoundsIcon from "components/icons/DataRoundsIcon";
import AssetPackagesIcon from "components/icons/AssetPackagesIcon";
import "./Sidebar.scss";
import RightArrowIcon from "components/icons/RightArrowIcon";

const { Sider } = Layout;

interface IMainSidebar {
  collapsed: boolean;
  setCollapsed: any;
}

const MainSidebar: React.FC<IMainSidebar> = ({ collapsed, setCollapsed }) => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef<any>(null);

  const [activeKey, setActiveKey] = useState("");

  const auth = useSelector((state: IRootState) => state.auth);
  const state = useSelector((state: IRootState) => state.common);

  /*  const onAssetDetailSidebarClick = () => {
    dispatch(setAssetDetailSidebar(!state.assetDetailSidebar));
    dispatch(setMasterProjectSidebar(false));
  }; */

  const onMasterProjectClick = () => {
    if (isEmpty(state.activeMasterProject)) {
      dispatch(setMasterProjectSidebar(!state.masterProjectSidebar));
      if (pathname.includes(ROUTES.DATA_ROUND)) {
        navigate(ROUTES.DATA_ROUND);
      } else {
        navigate(ROUTES.PROJECT);
      }
    } else {
      if (pathname.includes(ROUTES.DATA_ROUND)) {
        navigate(`${ROUTES.DATA_ROUND}/${state.activeMasterProject.id}`);
      } else {
        navigate(`${ROUTES.PROJECT}/${state.activeMasterProject.id}`);
      }
    }
  };

  // const onClickDocuments = () => {
  //   navigate(ROUTES.DOCUMENTS);
  // };

  const onClickIncidentReports = () => {
    navigate(ROUTES.INCIDENT_REPORTS);
  };
  /*   const onClickIotDeviceIcon = () => {
    navigate(ROUTES.IOT_DEVICES);
  }; */

  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CONTACT_US_MODAL,
    });
  };

  const contactUsModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.CONTACT_US_MODAL,
      width: 800,
      footer: null,
      onCancel: onCloseModal,
      className: "contantUs-modal",
      children: <ContactUsForm onCloseModal={onCloseModal} />,
    });
  };

  useEffect(() => {
    let key = "";
    if (pathname.includes(ROUTES.COMPANY_WIDE.SPARE_PARTS)) {
      key = ROUTES.COMPANY_WIDE.SPARE_PARTS;
    } else if (pathname.includes(ROUTES.COMPANY_WIDE.CALENDAR)) {
      key = ROUTES.COMPANY_WIDE.CALENDAR;
    } else if (pathname.includes(ROUTES.DASHBOARD)) {
      key = ROUTES.DASHBOARD;
    } else if (pathname.includes(ROUTES.PROJECT)) {
      key = ROUTES.PROJECT;
    } else if (pathname.includes(ROUTES.ALL_WORK_ORDERS)) {
      key = `${ROUTES.ALL_WORK_ORDERS}?page=pm-external`;
    } else if (pathname.includes(ROUTES.MY_ITEMS)) {
      key = `${ROUTES.MY_ITEMS}?page=pm-external`;
    } else if (pathname.includes(ROUTES.CALENDAR)) {
      key = ROUTES.CALENDAR;
    } else if (pathname.includes(ROUTES.INVENTORY)) {
      key = ROUTES.INVENTORY;
    } else if (pathname.includes(ROUTES.PROCEDURES)) {
      key = ROUTES.PROCEDURES;
    } else if (pathname.includes(ROUTES.CONTACTS)) {
      key = ROUTES.CONTACTS;
    } else if (pathname.includes(ROUTES.USER_SETTING)) {
      key = ROUTES.USER_SETTING;
    } else if (pathname.includes(ROUTES.IOT_DEVICES)) {
      key = ROUTES.IOT_DEVICES;
    } else if (pathname.includes(ROUTES.ALL_TIME_LINE)) {
      key = ROUTES.ALL_TIME_LINE;
    } else if (pathname.includes(ROUTES.DOCUMENTS)) {
      key = ROUTES.DOCUMENTS;
    } else if (pathname.includes(ROUTES.INCIDENT_REPORTS)) {
      key = ROUTES.INCIDENT_REPORTS;
    } else if (pathname.includes(ROUTES.DATA_ROUND)) {
      key = ROUTES.DATA_ROUND;
    } else if (pathname.includes(ROUTES.ASSET_DOCUMENTS)) {
      key = ROUTES.ASSET_DOCUMENTS;
    } else if (pathname.includes(ROUTES.SITE_DOCUMENTS)) {
      key = ROUTES.SITE_DOCUMENTS;
    }
    setActiveKey(key);
  }, [pathname]);

  const closedIcon = () => {
    return (
      <div className="collapse-icon-container">
        <RightArrowIcon stroke={"#0954F1"} strokeWidth={"3"} />
      </div>
    );
  };

  const openIcon = () => {
    return (
      <div className="collapse-icon-container">
        <LeftArrowIcon stroke={"#0954F1"} strokeWidth={"3"} />
      </div>
    );
  };

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setCollapsed(false);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current);
    setCollapsed(true);
  };

  return (
    <div
      className={`${
        collapsed ? "sidebar-container" : "sidebar-container-expanded"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`logo-container ${
          collapsed ? "logo-background-collapsed " : "logo-background-open"
        }`}
        onClick={() => {
          if (isExternalUser(auth.user)) return;
          navigate(ROUTES.PROJECT);
          dispatch(setAssetDetailSidebar(false));
        }}
      >
        <div className="alectify-logo-sidebar closed-logo" />
        <div
          className="trigger-sidebar"
          onClick={(e) => {
            e.stopPropagation();
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? closedIcon() : openIcon()}
        </div>
      </div>
      <Sider
        style={siderStyle}
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="alectify-siderbar-container"
      >
        <div className="sidebar-transparent-box">
          <Menu
            theme="light"
            mode="inline"
            activeKey={activeKey}
            selectedKeys={[activeKey]}
            defaultSelectedKeys={[pathname]}
            style={menuStyles}
            onClick={(ev) => {
              ev.domEvent.stopPropagation();
              navigate(ev.key);
              dispatch(setMasterProjectSidebar(false));
            }}
            items={
              [
                {
                  key: ROUTES.DASHBOARD,
                  style: collapsed ? listCenteredStyles : listStyles,

                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <AnalyticsIcon
                        fill={
                          pathname.includes(ROUTES.DASHBOARD)
                            ? PROJECT_WHITE_COLOR
                            : null
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname.includes("/dashboard") ? "active" : ""
                        }`}
                      >
                        Dashboard
                      </span>
                    </div>
                  ),
                  title: "Dashboard",
                  onClick: onMasterProjectClick,
                  visible: isFeatureEnabled(FEATURE_FLAGS.DASHBOARD),
                },
              ].filter((menu) => menu.visible) as any
            }
          />
        </div>
        {/* CMMS Box */}
        <div className="sidebar-box">
          <div className="sidebar-titles">{AlectifyText.CMMS}</div>

          <Menu
            theme="light"
            mode="inline"
            activeKey={activeKey}
            selectedKeys={[activeKey]}
            defaultSelectedKeys={[pathname]}
            style={menuStyles}
            onClick={(ev) => {
              ev.domEvent.stopPropagation();
              navigate(ev.key);
              dispatch(setMasterProjectSidebar(false));
            }}
            items={
              [
                {
                  key: `${ROUTES.ALL_WORK_ORDERS}?page=pm-external`,
                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <TasksIcon
                        fill={
                          pathname.includes(ROUTES.ALL_WORK_ORDERS)
                            ? PROJECT_WHITE_COLOR
                            : null
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname.includes(ROUTES.ALL_WORK_ORDERS)
                            ? "active"
                            : ""
                        }`}
                      >
                        All Work Orders
                      </span>
                    </div>
                  ),
                  title: AlectifyText.ALL_WORK_ORDERS,
                  style: collapsed ? listCenteredStyles : listStyles,
                  visible:
                    !isExternalUser(auth.user) &&
                    isFeatureEnabled(FEATURE_FLAGS.ALL_WORKORDER),
                  onClick: () => {
                    dispatch(setShowMyWorkOrders(false));
                  },
                },
                {
                  key: `${ROUTES.MY_ITEMS}?page=pm-external`,
                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <TasksIcon
                        fill={
                          pathname.includes(ROUTES.MY_ITEMS)
                            ? PROJECT_WHITE_COLOR
                            : null
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname.includes(ROUTES.MY_ITEMS) ? "active" : ""
                        }`}
                      >
                        My Work Orders
                      </span>
                    </div>
                  ),
                  title: AlectifyText.MY_WORK_ORDERS,
                  style: collapsed ? listCenteredStyles : listStyles,
                  visible: true,
                  onClick: () => {
                    dispatch(setShowMyWorkOrders(true));
                  },
                },
                {
                  key: ROUTES.CALENDAR,
                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <CalendarIcon
                        fill={
                          pathname === ROUTES.CALENDAR
                            ? PROJECT_WHITE_COLOR
                            : null
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname === ROUTES.CALENDAR ? "active" : ""
                        }`}
                      >
                        {AlectifyText.CALENDAR}
                      </span>
                    </div>
                  ),
                  title: AlectifyText.CALENDAR,
                  style: collapsed ? listCenteredStyles : listStyles,
                  onClick: () => {
                    dispatch(setShowCalendarMyWorkOrders(true));
                  },
                  visible:
                    !isExternalUser(auth.user) &&
                    isFeatureEnabled(FEATURE_FLAGS.MY_CALENDAR),
                },
                {
                  key: ROUTES.INVENTORY,
                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <InventoryIcon
                        fill={
                          pathname === ROUTES.INVENTORY
                            ? PROJECT_WHITE_COLOR
                            : null
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname === ROUTES.INVENTORY ? "active" : ""
                        }`}
                      >
                        {AlectifyText.SPARE_PARTS}
                      </span>
                    </div>
                  ),
                  title: AlectifyText.SPARE_PARTS,
                  style: collapsed ? listCenteredStyles : listStyles,
                  visible:
                    !isExternalUser(auth.user) &&
                    isFeatureEnabled(FEATURE_FLAGS.SPAREPART),
                },
                {
                  key: ROUTES.PROCEDURES,
                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <ProcedureIcon
                        fill={
                          pathname.includes(ROUTES.PROCEDURES)
                            ? PROJECT_WHITE_COLOR
                            : null
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname.includes(ROUTES.PROCEDURES) ? "active" : ""
                        }`}
                      >
                        {AlectifyText.PROCEDURES}
                      </span>
                    </div>
                  ),
                  title: AlectifyText.PROCEDURES,
                  style: collapsed ? listCenteredStyles : listStyles,
                  visible:
                    !isExternalUser(auth.user) &&
                    isFeatureEnabled(FEATURE_FLAGS.PROCEDURE_LIBRARY),
                },
                {
                  title: "All Sites",

                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-dropdown-container ${
                        collapsed ? "label-none" : "label-left"
                      }`}
                    >
                      <AssetPackagesIcon
                        fill={
                          pathname.includes(ROUTES.COMPANY_WIDE.COMPANY_WIDE)
                            ? collapsed
                              ? PROJECT_WHITE_COLOR
                              : PROJECT_THEME_COLOR_SECONDARY
                            : ""
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname.includes(ROUTES.COMPANY_WIDE.COMPANY_WIDE)
                            ? "active"
                            : ""
                        }`}
                      >
                        All Sites
                      </span>
                    </div>
                  ),
                  style: {
                    background: pathname.includes(
                      ROUTES.COMPANY_WIDE.COMPANY_WIDE,
                    )
                      ? collapsed
                        ? "#0954f1"
                        : ""
                      : "",
                  },

                  className: collapsed ? "company-wide-options" : "label-left",
                  visible: false,
                  // !isExternalUser(auth.user) &&
                  // isFeatureEnabled(FEATURE_FLAGS.COMPANYWIDE),
                  children: [
                    {
                      key: ROUTES.COMPANY_WIDE.SPARE_PARTS,
                      title: AlectifyText.SPARE_PARTS,
                      label: AlectifyText.SPARE_PARTS,
                      icon: (
                        <InventoryIcon
                          fill={
                            pathname.includes(ROUTES.COMPANY_WIDE.SPARE_PARTS)
                              ? PROJECT_WHITE_COLOR
                              : PROJECT_THEME_COLOR
                          }
                        />
                      ),
                    },
                    {
                      key: ROUTES.COMPANY_WIDE.CALENDAR,
                      title: AlectifyText.CALENDAR,
                      label: AlectifyText.CALENDAR,
                      icon: (
                        <CalendarIcon
                          fill={
                            pathname.includes(ROUTES.COMPANY_WIDE.CALENDAR)
                              ? PROJECT_WHITE_COLOR
                              : PROJECT_THEME_COLOR
                          }
                        />
                      ),
                    },
                  ],
                },
              ].filter((menu) => menu.visible) as any
            }
          />
        </div>
        {/* Asset management Box */}
        {!isExternalUser(auth.user) && (
          <div className="sidebar-box">
            <span className="sidebar-titles">
              {AlectifyText.ASSET_MANAGEMENT}
            </span>
            <Menu
              theme="light"
              mode="inline"
              activeKey={activeKey}
              selectedKeys={[activeKey]}
              defaultSelectedKeys={[pathname]}
              style={menuStylesSecond}
              onClick={(ev) => {
                ev.domEvent.stopPropagation();
                navigate(ev.key);
                if (ev.key === ROUTES.ASSET_DOCUMENTS) {
                  dispatch(setMasterProjectSidebar(false));
                  return;
                }
                if (ev.key === ROUTES.SITE_DOCUMENTS) {
                  dispatch(setMasterProjectSidebar(false));
                  return;
                }
              }}
              items={
                [
                  {
                    key: ROUTES.PROJECT,
                    label: (
                      <div
                        className={`alectify-sidebar-label-icon-container ${
                          collapsed ? "label-none" : ""
                        }`}
                      >
                        <ProjectIcon
                          fill={
                            pathname.includes(ROUTES.PROJECT)
                              ? PROJECT_WHITE_COLOR
                              : null
                          }
                        />
                        <span
                          className={`alectify-sidebar-label-text ${
                            pathname.includes(ROUTES.PROJECT) ? "active" : ""
                          }`}
                        >
                          {AlectifyText.ASSETS}
                        </span>
                      </div>
                    ),
                    title: AlectifyText.ASSETS,
                    style: collapsed ? listCenteredStyles : listStyles,

                    onClick: onMasterProjectClick,
                    visible: !isExternalUser(auth.user),
                  },

                  {
                    visible: !isExternalUser(auth.user),
                    key: ROUTES.ASSET_DOCUMENTS,
                    title: AlectifyText.ASSET_DOCUMENTS,
                    style: collapsed ? listCenteredStyles : listStyles,

                    label: (
                      <div
                        className={`alectify-sidebar-label-icon-container ${
                          collapsed ? "label-none" : ""
                        }`}
                      >
                        <AssetsIcon
                          fill={
                            pathname.includes(ROUTES.ASSET_DOCUMENTS)
                              ? PROJECT_WHITE_COLOR
                              : PROJECT_THEME_COLOR
                          }
                        />
                        <span
                          className={`alectify-sidebar-label-text ${
                            pathname.includes(ROUTES.ASSET_DOCUMENTS)
                              ? "active"
                              : ""
                          }`}
                        >
                          {AlectifyText.ASSET_DOCUMENTS}
                        </span>
                      </div>
                    ),
                  },
                  {
                    visible: !isExternalUser(auth.user),
                    key: ROUTES.SITE_DOCUMENTS,
                    title: AlectifyText.SITE_DOCUMENTS,
                    style: collapsed ? listCenteredStyles : listStyles,
                    label: (
                      <div
                        className={`alectify-sidebar-label-icon-container ${
                          collapsed ? "label-none" : ""
                        }`}
                      >
                        <ProjectIcon
                          fill={
                            pathname.includes(ROUTES.SITE_DOCUMENTS)
                              ? PROJECT_WHITE_COLOR
                              : null
                          }
                        />
                        <span
                          className={`alectify-sidebar-label-text ${
                            pathname.includes(ROUTES.SITE_DOCUMENTS)
                              ? "active"
                              : ""
                          }`}
                        >
                          {AlectifyText.SITE_DOCUMENTS}
                        </span>
                      </div>
                    ),
                    // label: AlectifyText.SITE_DOCUMENTS,
                    // icon: (
                    //   <ProjectIcon
                    //     fill={
                    //       pathname.includes(ROUTES.SITE_DOCUMENTS)
                    //         ? PROJECT_WHITE_COLOR
                    //         : PROJECT_THEME_COLOR
                    //     }
                    //   />
                    // ),
                  },

                  {
                    key: ROUTES.INCIDENT_REPORTS,
                    label: (
                      <div
                        className={`alectify-sidebar-label-icon-container ${
                          collapsed ? "label-none" : ""
                        }`}
                      >
                        <AlertIcon
                          stroke={
                            pathname.includes(ROUTES.INCIDENT_REPORTS)
                              ? PROJECT_WHITE_COLOR
                              : null
                          }
                        />
                        <span
                          className={`alectify-sidebar-label-text ${
                            pathname.includes(ROUTES.INCIDENT_REPORTS)
                              ? "active"
                              : ""
                          }`}
                        >
                          {AlectifyText.INCIDENT_REPORTS}
                        </span>
                      </div>
                    ),
                    title: AlectifyText.INCIDENT_REPORTS,
                    style: collapsed ? listCenteredStyles : listStyles,
                    onClick: onClickIncidentReports,
                    visible: !isExternalUser(auth.user),
                  },
                  {
                    key: ROUTES.DATA_ROUND,
                    label: (
                      <div
                        className={`alectify-sidebar-label-icon-container ${
                          collapsed ? "label-none" : ""
                        }`}
                      >
                        <DataRoundsIcon
                          fill={
                            pathname.includes(ROUTES.DATA_ROUND)
                              ? PROJECT_WHITE_COLOR
                              : PROJECT_THEME_COLOR
                          }
                        />
                        <span
                          className={`alectify-sidebar-label-text ${
                            pathname.includes(ROUTES.DATA_ROUND) ? "active" : ""
                          }`}
                        >
                          {AlectifyText.ROUNDS}
                        </span>
                      </div>
                    ),
                    title: AlectifyText.ROUNDS,
                    style: collapsed ? listCenteredStyles : listStyles,
                    onClick: onMasterProjectClick,
                    visible:
                      !isExternalUser(auth.user) &&
                      isFeatureEnabled(FEATURE_FLAGS.OPERATOR_ROUND),
                  },
                ].filter((menu) => menu.visible) as any
              }
            />
          </div>
        )}
        <div className="sidebar-transparent-box sidebar-bottom">
          <Menu
            theme="light"
            mode="inline"
            activeKey={activeKey}
            selectedKeys={[activeKey]}
            onClick={(ev) => {
              ev.domEvent.stopPropagation();
              if (ev.key === ROUTES.CONTACT_US) {
                contactUsModal();
                return;
              }
              navigate(ev.key);
              dispatch(setMasterProjectSidebar(false));
            }}
            style={menuStyles}
            items={
              [
                {
                  key: ROUTES.CONTACTS,
                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <ContactsIcon
                        fill={
                          pathname.includes(ROUTES.CONTACTS)
                            ? PROJECT_WHITE_COLOR
                            : ""
                        }
                      />
                      <span
                        className={`alectify-sidebar-label-text ${
                          pathname.includes(ROUTES.CONTACTS) ? "active" : null
                        }`}
                      >
                        {AlectifyText.CONTACTS}
                      </span>
                    </div>
                  ),
                  title: AlectifyText.CONTACTS,
                  style: collapsed ? listCenteredStyles : listStyles,
                  visible:
                    !isExternalUser(auth.user) &&
                    isFeatureEnabled(FEATURE_FLAGS.CONTACT),
                },

                {
                  key: ROUTES.CONTACT_US,
                  style: collapsed ? listCenteredStyles : listStyles,

                  label: (
                    <div
                      className={`alectify-sidebar-label-icon-container ${
                        collapsed ? "label-none" : ""
                      }`}
                    >
                      <ContactUsIcon />
                      <span className={`alectify-sidebar-label-text`}>
                        Support
                      </span>
                    </div>
                  ),
                  title: AlectifyText.SUPPORT,
                  visible: true,
                  onClick: contactUsModal,
                },
              ].filter((menu) => menu.visible) as any
            }
          />
        </div>
      </Sider>
    </div>
  );
};

export default MainSidebar;
