import { IAsset } from "redux/components/assets";
import { IUser } from "redux/components/Auth";
import { IPmInternal } from "redux/components/pm-internal";
import { ISparePart } from "redux/components/spare-parts";
import { ISubProject } from "redux/components/sub-project";
import { IArea, IMetaNest } from "redux/interfaces";

export enum NotificationTypesEnum {
  PM_INTERNAL = "PM_INTERNAL",
  PM_EXTERNAL = "PM_EXTERNAL",
  TASK = "TASK",
  SPARE_PART = "SPARE_PART",
  ASSET = "ASSET",
  ASSET_PACKAGE = "ASSET_PACKAGE",
  PROJECT = "PROJECT",
  SUB_PROJECT = "SUB_PROJECT",
}

export interface INotification {
  id: string;
  message: string;
  notificationType: NotificationTypesEnum;
  preventiveMaintenance: IPmInternal;
  area: IArea;
  asset: IAsset;
  subProject: ISubProject;
  projectSparePart: ISparePart;
  createdAt: Date;
  updatedAt: Date;
  createdBy: IUser;
}

export interface IUserNotification {
  id: string;
  isRead: boolean;
  createdAt: Date;
  updatedAt: Date;
  notification: INotification;
  user: IUser;
}

export interface INotificationsResponse {
  message: string;
  meta: IMetaNest;
  data: IUserNotification[];
}
