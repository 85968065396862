import { Checkbox, Form, Radio } from "antd";
import { isEmpty, truncate } from "lodash";
import AlectifySelect from "components/shared/select";
import TeamMember from "components/shared/team-members";
import AlectifyDatePicker from "components/shared/datepicker";
import AlectifyText from "static/texts.json";
import {
  FrequencyTypeEnum,
  IPMFormFieldsProps,
  IPMFormFilds,
} from "./PMCreateEditForm.interace";
import {
  FREQUENCY_TYPES,
  PM_CREATE_EDIT_FORM_FIELD_TYPES,
  PM_DATES,
  PM_DAYS_NAME,
  PM_DAY_DATE_OPTIONS,
  PM_EDITING_TYPES,
  PM_FREQUENCIES,
  PM_WEEKS,
} from "./PMCreateEditForm.constants";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "../button";
import SelectMasterProject from "../master-project-select";
import SelectSubProject from "../sub-project-select/SelectSubProject";
import dayjs from "dayjs";
import { createAssigneeListForPM } from "./PMCreateEditForm.helpers";
import { capitalizeFirstLetter, snakeCaseToTitle } from "utils/helpers";
import {
  MaintenanceCategoriesEnum,
  TaskCategoriesEnum,
  TaskPriorityEnum,
} from "enums";
import { RangePickerProps } from "antd/es/date-picker";
import TeamMembersDropdown from "../team-members-dropdown";
import "./PMCreateEdit.scss";
import AlectifyEditor from "../alectify-editor/AlectifyEditor";

export const PMFormFilds = (props: IPMFormFieldsProps): IPMFormFilds[] => {
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };
  const getAssignees = () => {
    return createAssigneeListForPM(props.selectedTeamMembers as any);
  };

  const CategoryField = () => {
    return (
      <div className="toggle-cm-pm-btn-container">
        <div
          className={`toggle-btn-box ${
            props.taskCategory ===
            MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
              ? "isActiveBtn"
              : ""
          } ${props.editing ? "disabled" : ""}`}
          onClick={() => {
            if (!props.editing) {
              props.onChangePMCategory &&
                props.onChangePMCategory(
                  MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE,
                );
            }
          }}
        >
          <Radio
            checked={
              props.taskCategory ===
              MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
            }
            disabled={props.editing}
          />
          Preventive Maintenance
        </div>
        <div className="border-right" />
        <div
          className={`toggle-btn-box ${
            props.taskCategory ===
            MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
              ? "isActiveBtn"
              : ""
          } ${props.editing ? "disabled" : ""}`}
          onClick={() => {
            if (!props.editing) {
              props.onChangePMCategory &&
                props.onChangePMCategory(
                  MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE,
                );
            }
          }}
        >
          <Radio
            checked={
              props.taskCategory ===
              MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
            }
            disabled={props.editing}
          />
          Corrective Maintenance
        </div>
      </div>
    );
  };
  return [
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY,
      label: AlectifyText.CATEGORY_MAINTENANCE,
      show: !props.isTask || false,
      colSpan: 24,
      element: CategoryField,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.CATEGORY,
          ),
        },
      ],
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.MASTER_PROJECT,
      element: SelectMasterProject,
      show:
        props.showProjectFields &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      colSpan: 12,
      masterProjectProps: {
        onSelect: props.onMasterProjectSelect,
        onChange: props.onMasterProjectChange,
        disabled: props.editing && !props.copying,
        // defaultValue: !props.editing "",
        rules: [
          {
            required: true,
            message: MESSAGES.FIELD_RULES.REQUIRED.replace(
              "{fieldName}",
              AlectifyText.SITE,
            ),
          },
        ],
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.SUB_PROJECT,
      element: SelectSubProject,
      show:
        props.showProjectFields &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      colSpan: 12,
      subProjectProps: {
        masterProjectId: props.selectedMasterProject?.id ?? "",
        onSelect: props.onSubProjectSelect,
        disabled: props.editing && !props.copying,
        rules: [
          {
            required: true,
            message: MESSAGES.FIELD_RULES.REQUIRED.replace(
              "{fieldName}",
              AlectifyText.ASSET_CATEGORY,
            ),
          },
        ],
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.PM_TRACKING,
      element: AlectifySelect,
      colSpan: 12,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.PM_TRACKING,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      label: AlectifyText.PM_TRACKING,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.PM_TRACKING,
          ),
        },
      ],
      selectProps: {
        placeholder: AlectifyText.ENTER_PM_TRACKING,
        disabled:
          (props.editing && !props.copying) || !props.selectedMasterProject,
        onChange: props.onAssetLevelChange,
        defaultActiveFirstOption: true,
        name: "pm_tracking",
        options: [
          {
            label: AlectifyText.ASSETS_PARENT_AND_SUB_ASSETS,
            value: "asset",
          },
          {
            label: AlectifyText.GENERIC,
            value: "generic",
          },
        ],
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSETS,
      colSpan: 12,
      name: "assets",
      label: AlectifyText.SELECT_ASSET,
      show:
        props.assetLevel === "generic"
          ? false
          : props.isTask && !isEmpty(props.assetLevel)
          ? true
          : !isEmpty(props.taskCategory) && !isEmpty(props.assetLevel),
      element: AlectifyButton,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.ASSET,
          ),
        },
      ],
      buttonProps: {
        text:
          (!isEmpty(props.selectedAsset || props?.selectedAssetPackage) &&
            truncate(
              props?.selectedAsset?.name || props?.selectedAssetPackage?.name,
              {
                length: 20,
                omission: "...",
              },
            )) ||
          AlectifyText.SELECT_ASSET,
        loading: props.assetLoader,
        block: true,
        className:
          "light-blue-button alectify-task-creation-asset-selection-btn text-ellipsis",
        disabled:
          (props.editing && !props.copying) || // false (props.editing is false, so it checks the next condition)
          (!props.editing && // true (props.editing is false)
            (isEmpty(props.masterProjectId) ||
              props.masterProjectId === "all") &&
            isEmpty(props.selectedSubProject)), // true (masterProjectId is 'all', selectedSubProject is '')
        onClick: props.equipmentModalHandler,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.WORK_TITLE,
      label: AlectifyText.WORK_TITLE,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.WORK_TITLE,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      element: AlectifyInput,
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.WORK_TITLE,
          ),
        },
      ],
      inputProps: {
        placeholder: AlectifyText.ENTER_WORK_TITLE,
        type: "text",
        name: "workTitle",
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.DETAILS,
      label: AlectifyText.DETAILS,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DETAILS,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      element: AlectifyEditor,
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.DETAILS,
          ),
        },
      ],
      ckEditorProps: {
        placeholder: AlectifyText.ENTER_TASK_DETAILS,
        onDataChange: props.onDetailsChange,
        type: "textarea",
        defaultValue: props.pmData?.detail || "",
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DETAILS,
        max: 255,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_PRIORITY,
      element: AlectifySelect,
      colSpan: 24,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_PRIORITY,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      label: AlectifyText.PRIORITY,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.PRIORITY,
          ),
        },
      ],
      selectProps: {
        placeholder: AlectifyText.PRIORITY,
        // onChange: props.onAssetLevelChange,
        defaultActiveFirstOption: true,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_PRIORITY,
        defaultValue: TaskPriorityEnum.NORMAL,
        options: [
          {
            label: capitalizeFirstLetter(
              snakeCaseToTitle(TaskPriorityEnum.NORMAL),
            ),
            value: TaskPriorityEnum.NORMAL,
          },
          {
            label: capitalizeFirstLetter(TaskPriorityEnum.CRITICAL),
            value: TaskPriorityEnum.CRITICAL,
          },
        ],
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.TEAM_MEMBERS_DROPDOWN,
      element: TeamMembersDropdown,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            "Team Members",
          ),
        },
      ],
      props: {
        onSelect: props.onSelectTeamMember,
        onDeselect: props.onDeselectTeamMember,
        users: props?.users,
        projectId:
          props.selectedMasterProject?.id ||
          props.pmData?.project.id ||
          props?.masterProjectId,
        showTeamOption: !props?.editing || props?.copying,
        onSelectType: props?.handleUserTypeChange,
        disable: props?.editing && !props?.copying,
        pmData: props?.pmData,
        iscoping: props?.copying,
        formdata: props?.formDate,
        // value: props.selectedTeamMembers.map((member:any) => member?.user) || [],
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.TEAM_MEMBERS,
      element: TeamMember,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      colSpan: 24,
      teamMembersProps: {
        users: (props.selectedTeamMembers as any) || [],
        onAdd: props.onOpenUserModal,
        maxCount: 10,
        disable:
          (props.assetLevel !== "generic" &&
            isEmpty(props.assetDetail) &&
            isEmpty(props.assetPackageDetail)) ||
          (props.assetLevel === "generic" &&
            isEmpty(props.selectedSubProject) &&
            props?.myWorkOrder) ||
          props?.onSelectMembersType === "teams" ||
          props?.editing,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSIGNEES,
      label: AlectifyText.ASSIGNEES,
      element: AlectifySelect,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.ASSIGNEES,
          ),
        },
      ],
      selectProps: {
        // tooltip: "Assignees cannot be external user",
        placeholder: AlectifyText.SELECT_ASSIGNEES,
        mode: "multiple",
        disabled: false,
        showSearch: false,
        onSelect:
          props.editing && !props.copying
            ? (_, record) => {
                props.createAssignee(record as any);
              }
            : () => {},
        onDeselect:
          props.editing && !props.copying
            ? (e) => {
                props.removeAssignee(e);
              }
            : () => {},
        defaultActiveFirstOption: true,
        name: "assignees",
        options: getAssignees(),
        maxTagCount: 5,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.APPROVERS,
      label: AlectifyText.APPROVERS,
      element: AlectifySelect,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.APPROVERS,
          ),
        },
      ],
      selectProps: {
        // tooltip: "Approvers cannot be external user",
        placeholder: AlectifyText.SELECT_APPROVERS,
        mode: "multiple",
        disabled: false,
        showSearch: false,
        onSelect:
          props.editing && !props.copying
            ? (_, record) => {
                props.createAssignee(record as any, "approvers");
              }
            : () => {},
        onDeselect:
          props.editing && !props.copying
            ? (e) => {
                props.removeAssignee(e, "approvers");
              }
            : () => {},
        defaultActiveFirstOption: true,
        name: "approvers",
        options: getAssignees(),
        maxTagCount: 5,
      },
    },

    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.CATEGORY,
      element: AlectifySelect,
      colSpan: 24,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY,
      show: props.isTask || false,
      label: AlectifyText.CATEGORY,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.CATEGORY,
          ),
        },
      ],
      selectProps: {
        placeholder: AlectifyText.CATEGORY,
        disabled: props.editing && !props.copying,
        // onChange: props.onAssetLevelChange,
        defaultActiveFirstOption: true,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY,
        options: [
          // {
          //   label: capitalizeFirstLetter(
          //     snakeCaseToTitle(TaskCategoriesEnum.CORRECTIVE_MAINTENANCE),
          //   ),
          //   value: TaskCategoriesEnum.CORRECTIVE_MAINTENANCE,
          // },
          {
            label: capitalizeFirstLetter(TaskCategoriesEnum.CLEANUP),
            value: TaskCategoriesEnum.CLEANUP,
          },
          {
            label: capitalizeFirstLetter(TaskCategoriesEnum.REPLACEMENT),
            value: TaskCategoriesEnum.REPLACEMENT,
          },
          {
            label: capitalizeFirstLetter(TaskCategoriesEnum.DAMAGE),
            value: TaskCategoriesEnum.DAMAGE,
          },
          {
            label: capitalizeFirstLetter(TaskCategoriesEnum.SAFETY),
            value: TaskCategoriesEnum.SAFETY,
          },
          {
            label: capitalizeFirstLetter(TaskCategoriesEnum.INSPECTION),
            value: TaskCategoriesEnum.INSPECTION,
          },
          {
            label: capitalizeFirstLetter(TaskCategoriesEnum.OTHERS),
            value: TaskCategoriesEnum.OTHERS,
          },
        ],
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.DUE_DATE,
      name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DUE_DATE,
      label: AlectifyText.DUE_DATE,
      show: props.isTask ? true : !isEmpty(props.taskCategory),
      element: AlectifyDatePicker,
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.DUE_DATE,
          ),
        },
      ],
      datePickerProps: {
        className: "full-width-picker",
        disabledDates: (current) => disabledDate(current),
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.RECURRING,
      label: AlectifyText.MAKE_IT_RECURRING,
      name: "recurring",
      show: props.isTask
        ? true
        : !isEmpty(props.taskCategory) &&
          props.taskCategory !==
            MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE,
      element: Checkbox,
      checkBoxProps: {
        checked: props.isRecurring,
        disabled: props.editing && !props.copying,
        onChange: (e) => props.onChangeRecurring(e.target.checked),
      },
      colSpan: 24,
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY_TYPE,
      label: AlectifyText.FREQUENCY_TYPE,
      show:
        props.isRecurring &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      element: AlectifySelect,
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.FREQUENCY_TYPE,
          ),
        },
      ],
      selectProps: {
        placeholder: AlectifyText.SELECT_FREQUENCY_TYPES,
        options: FREQUENCY_TYPES.filter((opt) => opt.visible),
        disabled:
          props.editType === PM_EDITING_TYPES.THIS_EVENT && !props.copying,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY_TYPE,
        onChange: (value: string) => props.onFrequencyTypeChange(value),
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY,
      label: AlectifyText.FREQUENCY,
      show:
        props.isRecurring &&
        props.frequencyType === FrequencyTypeEnum.MONTHLY &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      element: AlectifySelect,
      colSpan: 24,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText.FREQUENCY,
          ),
        },
      ],
      selectProps: {
        placeholder: AlectifyText.SELECT_MONTHS,
        options: PM_FREQUENCIES,
        disabled:
          props.editType === PM_EDITING_TYPES.THIS_EVENT && !props.copying,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY_DATE,
      label: AlectifyText["DAY/DATE"],
      name: "dayType",
      show:
        props.isRecurring &&
        props.frequencyType === FrequencyTypeEnum.MONTHLY &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      element: AlectifySelect,
      colSpan: 8,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
            "{fieldName}",
            AlectifyText["DAY/DATE"],
          ),
        },
      ],
      selectProps: {
        onChange: props.onDayDateChange,
        defaultValue: props.dayType,
        disabled:
          props.editType === PM_EDITING_TYPES.THIS_EVENT && !props.copying,
        options: PM_DAY_DATE_OPTIONS,
        name: "dayType",
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE,
      label: "Date",
      show:
        props.isRecurring &&
        props.dayType === "date" &&
        props.frequencyType === FrequencyTypeEnum.MONTHLY &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      element: AlectifySelect,
      colSpan: 8,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace("{fieldName}", "Date"),
        },
      ],
      selectProps: {
        // defaultValue: PM_DATES[0].value,
        options: PM_DATES,
        disabled:
          props.editType === PM_EDITING_TYPES.THIS_EVENT && !props.copying,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK,
      label: AlectifyText.WEEK,
      show:
        props.isRecurring &&
        props.dayType === "day" &&
        props.frequencyType === FrequencyTypeEnum.MONTHLY &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      element: AlectifySelect,
      colSpan: 8,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace("{fieldName}", "Week"),
        },
      ],
      selectProps: {
        // defaultValue: PM_WEEKS[0].value,
        options: PM_WEEKS,
        disabled:
          props.editType === PM_EDITING_TYPES.THIS_EVENT && !props.copying,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK,
      },
    },
    {
      fieldType: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
      label: "Day",
      show:
        props.isRecurring &&
        (props.dayType === "day" ||
          props.frequencyType === FrequencyTypeEnum.WEEKLY) &&
        (props.frequencyType === FrequencyTypeEnum.MONTHLY ||
          props.frequencyType === FrequencyTypeEnum.WEEKLY) &&
        (props.isTask ? true : !isEmpty(props.taskCategory)),
      element: AlectifySelect,
      colSpan: 8,
      rules: [
        {
          required: true,
          message: MESSAGES.FIELD_RULES.REQUIRED.replace("{fieldName}", "Day"),
        },
      ],
      selectProps: {
        // defaultValue: PM_DAYS_NAME[0].value,
        options: PM_DAYS_NAME,
        disabled:
          props.editType === PM_EDITING_TYPES.THIS_EVENT && !props.copying,
        name: PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY,
      },
    },
  ];
};

export const renderFields = (field: IPMFormFilds) => {
  switch (field.fieldType) {
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.MASTER_PROJECT:
      return <field.element {...field} {...field.masterProjectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.SUB_PROJECT:
      return <field.element {...field} {...field.subProjectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.PM_TRACKING:
      return <field.element {...field} {...field.selectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSETS:
      return (
        <Form.Item label={field.label} name={field.name} rules={field.rules}>
          <field.element {...field} {...field.buttonProps} />
        </Form.Item>
      );

    case PM_CREATE_EDIT_FORM_FIELD_TYPES.EQUIPMENT:
      return <field.element {...field} {...field.equipmentSelectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.ASSIGNEES:
      return <field.element {...field} {...field.selectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.APPROVERS:
      return <field.element {...field} {...field.selectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.WORK_TITLE:
      return <field.element {...field} {...field.inputProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.TEAM_MEMBERS_DROPDOWN:
      return <field.element {...field} {...field.props} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.TEAM_MEMBERS:
      return <field.element {...field} {...field.teamMembersProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.DETAILS:
      return (
        <Form.Item label={field.label} name={field.name} rules={field.rules}>
          <field.element {...field} {...field.ckEditorProps} />
        </Form.Item>
      );
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.CATEGORY:
      return <field.element {...field} {...field.selectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_PRIORITY:
      return <field.element {...field} {...field.selectProps} />;
    // case PM_CREATE_EDIT_FORM_FIELD_TYPES.PREFERRED_SUPPLIER:
    //   return (
    //     <Form.Item {...field}>
    //       <field.element {...field.autcompleteProps} />
    //     </Form.Item>
    //   );

    // case PM_CREATE_EDIT_FORM_FIELD_TYPES.CONTRACT_END_DATE:
    //   return (
    //     <Form.Item {...field}>
    //       <field.element {...field.datePickerProps} />
    //     </Form.Item>
    //   );

    case PM_CREATE_EDIT_FORM_FIELD_TYPES.RECURRING:
      return (
        <Form.Item name={field.name} valuePropName="checked">
          <field.element {...field.checkBoxProps}>{field.label}</field.element>
        </Form.Item>
      );
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.TASK_CATEGORY:
      return (
        <Form.Item
          label={field.label}
          name={field.name}
          valuePropName="checked"
          rules={field.rules}
        >
          <field.element {...field.checkBoxProps}>{field.label}</field.element>
        </Form.Item>
      );

    case PM_CREATE_EDIT_FORM_FIELD_TYPES.DUE_DATE:
      return (
        <Form.Item {...field}>
          <field.element {...field.datePickerProps} />
        </Form.Item>
      );
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY_TYPE:
      return <field.element {...field} {...field.selectProps} />;

    case PM_CREATE_EDIT_FORM_FIELD_TYPES.FREQUENCY:
      return <field.element {...field} {...field.selectProps} />;

    case PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY_DATE:
      return <field.element {...field} {...field.selectProps} />;

    case PM_CREATE_EDIT_FORM_FIELD_TYPES.DATE:
      return <field.element {...field} {...field.selectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.WEEK:
      return <field.element {...field} {...field.selectProps} />;
    case PM_CREATE_EDIT_FORM_FIELD_TYPES.DAY:
      return <field.element {...field} {...field.selectProps} />;
  }
};
