import axios, { AxiosInstance, CancelTokenSource } from "axios";
import store from "redux/store";
import { message, notification } from "antd";
import * as Sentry from "@sentry/react";
import { config } from "./configs";
import { getFromLocal, removeFromLocal, saveToLocal } from "./cache";
import { AxiosResponseTypes } from "redux/interfaces";
import { isString } from "lodash";
import { fetchRefreshToken } from "services/auth/auth.service";
import { actions } from "redux/components/Auth";
import { resetAllState } from "redux/rootReducer";
import { isTokenExpired } from "./helpers";

export class HttpService {
  private _axios: AxiosInstance;
  private cancelTokenSources: { [key: string]: CancelTokenSource } = {};
  private isRefreshing = false;
  private refreshTokenPromise: Promise<any> | null = null;
  private failedQueue: Array<(token: string) => void> = [];

  constructor(baseURL: string = "", headers = {}) {
    this._axios = axios.create({
      baseURL,
      headers,
    });
    this.initializeInterceptors();
  }

  private initializeInterceptors() {
    // Add request interceptor
    this._axios.interceptors.request.use(
      async (config) => {
        const token = getFromLocal("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Add response interceptor for handling 401 errors
    this._axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        console.log("error while intercepting", error.response);
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          console.log("hhhhhh", error);
          if (error.response?.data?.message === "Invalid credentials") {
            message.error(error.response?.data?.message);
            return Promise.reject(error);
          }
          originalRequest._retry = true;
          return this.refreshTokenAndRetry(originalRequest);
        } else if (error.response?.status === 429) {
          message.error(error.response?.data?.message);
          return Promise.reject(error);
        }

        return Promise.reject(error);
      },
    );
  }

  private async refreshTokenAndRetry(originalRequest: any) {
    const refreshToken = getFromLocal("refresh_token");

    // Check if the refresh token is present
    if (!refreshToken) {
      this.handleTokenRefreshFailure();
      return Promise.reject(new Error("No refresh token available"));
    }

    // Check if the refresh token is expired
    if (isTokenExpired(refreshToken)) {
      this.handleTokenRefreshFailure();
      return Promise.reject(new Error("Refresh token is expired."));
    }

    // Check if there is already a refresh token request in progress
    if (this.isRefreshing) {
      return new Promise((resolve) => {
        this.failedQueue.push((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(this._axios(originalRequest));
        });
      });
    } else {
      this.isRefreshing = true;
      this.refreshTokenPromise = fetchRefreshToken({
        refresh_token: refreshToken,
      })
        .then((response) => {
          const newAccessToken = response.data?.access;
          const newRefreshToken = response.data?.refresh;

          // Save new tokens to local storage
          saveToLocal(newAccessToken, "token");
          saveToLocal(newRefreshToken, "refresh_token");

          // Retry any failed requests with the new access token
          this.failedQueue.forEach((callback) => callback(newAccessToken));
          this.failedQueue = [];

          return newAccessToken;
        })
        .catch((error) => {
          this.failedQueue = [];
          this.handleTokenRefreshFailure(); // Handle failure (e.g., log out)
          return Promise.reject(error);
        })
        .finally(() => {
          this.isRefreshing = false;
          this.refreshTokenPromise = null;
        });

      // Retry the original request with the new access token
      return this.refreshTokenPromise.then((newAccessToken) => {
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return this._axios(originalRequest);
      });
    }
  }
  private handleTokenRefreshFailure() {
    removeFromLocal("token");
    removeFromLocal("refresh_token");
    store.dispatch(resetAllState() as any);
    store.dispatch(actions.logout());
    notification.info({
      message: "Session expired",
      description: "Your session has expired, please log in again.",
    });
    window.location.href = "/login";
    message.destroy();
  }

  private setupCancelToken(requestId: string) {
    this.cancelTokenSources[requestId] = axios.CancelToken.source();
    return this.cancelTokenSources[requestId].token;
  }

  private removeCancelToken(requestId: string) {
    if (this.cancelTokenSources[requestId]) {
      delete this.cancelTokenSources[requestId];
    }
  }

  get(
    endpoint: string,
    params = {},
    requestId?: string,
    headers = {},
    responseType?: any,
  ): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };

    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;

    const config: any = {
      params,
      headers: authHeaders,
      cancelToken,
    };

    if (responseType) {
      config.responseType = responseType;
    }

    return this._axios
      .get(endpoint, config)
      .then((response) => {
        if (requestId) {
          this.removeCancelToken(requestId);
        }
        return response;
      })
      .catch(this.errorHandling);
  }

  post(endpoint: string, body: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios
      .post(endpoint, body, { headers: authHeaders, cancelToken })
      .then((response) => {
        if (requestId) {
          this.removeCancelToken(requestId);
        }
        return response;
      })
      .catch(this.errorHandling);
  }

  postWithResponseType(
    endpoint: string,
    body: any,
    headers = {},
    responseType: AxiosResponseTypes,
    requestId?: string,
  ): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios({
      url: endpoint,
      data: body,
      method: "POST",
      responseType: responseType,
      headers: authHeaders,
      cancelToken,
    }).catch(this.errorHandling);
  }

  put(endpoint: string, data: any, headers = {}, requestId?: string) {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios({
      method: "put",
      url: endpoint,
      data,
      headers: authHeaders,
      cancelToken,
    }).catch(this.errorHandling);
  }

  patch(endpoint: string, data?: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios({
      method: "patch",
      url: endpoint,
      data,
      headers: authHeaders,
      cancelToken,
    }).catch(this.errorHandling);
  }

  delete(endpoint: string, data?: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios({
      method: "delete",
      url: endpoint,
      data,
      headers: authHeaders,
      cancelToken,
    }).catch(this.errorHandling);
  }

  cancelPendingRequests(requestId?: string) {
    if (requestId && this.cancelTokenSources[requestId]) {
      this.cancelTokenSources[requestId].cancel(
        "Operation canceled by the user.",
      );
      this.removeCancelToken(requestId);
    } else {
      Object.values(this.cancelTokenSources).forEach((source) => {
        source.cancel("Operation canceled by the user.");
      });
      this.cancelTokenSources = {};
    }
  }

  errorHandling(err: any): any {
    err?.code !== "ERR_CANCELED" && Sentry.captureException(err);
    if (err?.code === "ERR_CANCELED") {
      return;
    }
    if (err?.response?.status === 400) {
      if (isString(err?.response?.data?.message)) {
        notification.error({
          message: err?.response?.data?.message || "Something went wrong!",
        });
      }
    }

    if (err?.response?.status === 502 || err?.response?.status === 504) {
      notification.error({
        message:
          "504 Gateway Timeout: Your request was too large. Please try a smaller request or contact support for assistance.",
        description: "Please try with small request.",
      });
    }

    throw err;
  }
}

const JSON_HEADERS: any = {
  "Content-Type": "application/json",
};

export const MULTIPART_HEADERS: any = {
  "Content-Type":
    "multipart/form-data; boundary=<calculated when request is sent>",
  Authorization: `Bearer ${getFromLocal("token")}`,
};

export const pmHttp = new HttpService(
  config.REACT_APP_PM_SERVICE_BASE_URL,
  JSON_HEADERS,
);
// Django Service Client
export const http = new HttpService(config.REACT_APP_BASE_URL, JSON_HEADERS);

export const llmHttp = new HttpService(
  config.REACT_APP_LLM_BASE_URL,
  JSON_HEADERS,
);

export const cancelApiRequests = (requestId?: string) => {
  http.cancelPendingRequests(requestId);
};
export const cancelPMApiRequests = (requestId?: string) => {
  pmHttp.cancelPendingRequests(requestId);
};

export const cancelLlmApiRequests = (requestId?: string) => {
  llmHttp.cancelPendingRequests(requestId);
};
