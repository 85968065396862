import { memo } from "react";

interface ILeftArrowIconProps {
  width?: string;
  height?: string;
  stroke?: string;
  strokeWidth?: string;
}

const LeftArrowIcon: React.FC<ILeftArrowIconProps> = (
  props: ILeftArrowIconProps,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "10"}
      height={props.height || "16"}
      viewBox="0 0 10 16"
      fill="none"
    >
      <path
        d="M8.5 14.2998L1.5 7.9998L8.5 1.6998"
        stroke={props.stroke || "#4C4C4C"}
        strokeWidth={props.strokeWidth || "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(LeftArrowIcon);
