import { memo } from "react";
import { CalendarActionEventsEnum } from "enums";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Space } from "antd";

export interface ICalendarToolbar {
  date: Date;
  label: string;
  onNavigate: (action: CalendarActionEventsEnum) => void;
}

const CalendarToolbar: React.FC<ICalendarToolbar> = (
  props: ICalendarToolbar,
) => {
  return (
    <div className="alectify-calendar-toolbar">
      <span className="rbc-toolbar-label">
        <Space>
          <LeftOutlined
            onClick={() => props.onNavigate(CalendarActionEventsEnum.PREVIOUS)}
          />
          {props.label}
          <RightOutlined
            onClick={() => props.onNavigate(CalendarActionEventsEnum.NEXT)}
          />
        </Space>
      </span>
      {/* 
        will be enabled in future
      */}
      {/* <AlectifyButton text="Missed Deadlines" type="primary" disabled /> */}
      {/* <div className="alectify-calendar-toolbar-legends">
        <Space direction="horizontal" size={30}>
          <span>
            <EventPendingIcon />
            &nbsp; {AlectifyText.OPEN_FUTURE_DUE_DATE}
          </span>
          <span>
            <EventDelayedIcon /> &nbsp; {AlectifyText.OPEN_OVER_DUE}
          </span>
          <span>
            <EventCompletedIcon />
            &nbsp; {AlectifyText.CLOSED_ON_TIME}
          </span>
          <span>
            <EventCompletedPastDueDateIcon />
            &nbsp; {AlectifyText.CLOSED_DUE_DATE_PASSED}
          </span>
          <span>
            <EventSkippedIcon />
            &nbsp; {AlectifyText.SKIPPED}
          </span>
        </Space>
      </div> */}
    </div>
  );
};

export default memo(CalendarToolbar);
