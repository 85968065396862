import {
  capitalizeFirstLetter,
  displayDateTime,
  getFullUserName,
  isExternalUser,
} from "utils/helpers";
import { Avatar, Badge, Dropdown, Space, Tooltip, message } from "antd";
import {
  MessageOutlined,
  PaperClipOutlined,
  EllipsisOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  CommentReferenceEnum,
  PME_ACTION_REQUIRED_ENUM,
  PM_STATUS,
} from "enums";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { IPmAssignees } from "redux/interfaces";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import { isEmpty, truncate } from "lodash";
import { ISubProject } from "redux/components/sub-project";
import { IPMExternalHookProps } from "../PmExternalTable.interface";
import {
  ApproverTag,
  AssetPackageTag,
  AssetTag,
  AssigneeTag,
  GenericTag,
  NonRecurringTag,
  RecurringTag,
} from "components/shared/tags";
import AlectifyText from "static/texts.json";
import TextToLink from "components/shared/text-to-link";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import AvatarGroup from "components/shared/avatar-group";
import PackageDetailAssets from "components/shared/package-detail-assets";
import AssetDetail from "components/shared/asset-detail";
import useMyWorkOrdersColumns from "pages/my-work-orders/effects/useMyWorkOrdersColumns";
import Comments from "components/shared/comments";
import { actionItems } from "../PmExternalTable.rules";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import PmDetail from "components/shared/pm-detail";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { getPMEActionRequired, isDelayed } from "../PmExternal.helpers";
import OnTimeTag from "components/shared/tags/OnTimeTag";
import DelayedTag from "components/shared/tags/DelayedTag";
import { useNavigate } from "react-router-dom";
import ProcedureDetail from "components/shared/procedure-detail";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import { IProcedureCheckList } from "services/procedures/procedures.interface";
import { CalendarDateIcon, UserIcon, WarningIcon } from "components/icons";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { undoTaskStatus } from "services/tasks/tasks.service";
import { MESSAGES } from "constants/messages";
import AlectifyButton from "components/shared/button";
import { FrequencyTypeEnum } from "components/shared/pm-create-edit-form/PMCreateEditForm.interace";
import ReopenedIcon from "components/icons/ReopenedIcon";

export const ACTION_REQUIRED_COMPONENTS = (
  action: PME_ACTION_REQUIRED_ENUM | null,
) => {
  switch (action) {
    case PME_ACTION_REQUIRED_ENUM.ASSIGNEE:
      return <AssigneeTag text={action} />;
    case PME_ACTION_REQUIRED_ENUM.APPROVERS:
      return <ApproverTag text={action} />;
    default:
      return null;
  }
};

const usePmExternalColumnHook = (props: IPMExternalHookProps) => {
  const navigate = useNavigate();
  const myItemsColumns = useMyWorkOrdersColumns({
    myWorkOrder: props?.myWorkOrder,
  });
  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const getTaskStatusIcon = (
    procedureSteps: IProcedureCheckList[],
  ): JSX.Element => {
    if (procedureSteps.length === 0) {
      return <StampCheckIcon />;
    }
    const totalSteps = procedureSteps.length;
    const checkedSteps = procedureSteps.reduce(
      (count, step) => (step.isChecked ? count + 1 : count),
      0,
    );
    const isAllChecked = checkedSteps === totalSteps;
    return isAllChecked ? (
      <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
    ) : (
      <StampCheckIcon />
    );
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.CREATED,
      key: "created",
      dataIndex: "created_at",
      visible: !props.myItemsColumns,
      sorter: true,
      sortingColumn: "created_at",
      ellipsis: true,
      width: 110,
      render: (target: any, record: any) => (
        <Space direction="vertical" size={15}>
          <div className="creator-container">
            <CalendarDateIcon />
            <span className="creator-name-date">
              {`${displayDateTime(
                record?.created_at || record?.createdAt,
                true,
                false,
              )}`}
            </span>
          </div>

          <div className="creator-container">
            {isEmpty(
              record?.createdBy?.image_url || record?.created_by?.image_url,
            ) ? (
              <UserIcon />
            ) : (
              <Avatar
                src={
                  record?.createdBy?.image_url || record?.created_by?.image_url
                }
                size="small"
              />
            )}
            <span className="creator-name-date">
              {`${
                record?.created_by && !isEmpty(record?.created_by)
                  ? getFullUserName(record?.created_by[0])
                  : record?.createdBy && !isEmpty(record?.createdBy)
                  ? getFullUserName(record?.createdBy)
                  : "-"
              }`}
            </span>
          </div>
        </Space>
      ),
    },
    {
      title: AlectifyText.WORKID_AND_TITLE,
      dataIndex: "workTitle",
      width: 150,
      visible: true,
      render: (value: string, pmExternal: IPmExternal) => {
        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 680,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              props.fetchPmExternal(props.optionsPersisted as any);
            },
            showFooter: true,
            destroyOnClose: true,
            readOnly: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={pmExternal.procedure as any}
                taskId={pmExternal.id}
                isDisabled={
                  pmExternal.status === PM_STATUS.COMPLETED ||
                  pmExternal.status === PM_STATUS.SKIPPED
                }
              />
            ),
          });
        };
        return (
          <Space direction="vertical" size={15}>
            <Space direction="horizontal" size={10}>
              <TextToLink
                className="text-to-link-options"
                text={truncate(value)}
                onClick={() => {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: 480,
                    title: value,
                    name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                    closable: true,
                    closeIcon: <CloseOutlined />,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                      }),
                    children: (
                      <PmDetail
                        pmExternalRecord={pmExternal}
                        pmId={pmExternal.id}
                        pmType={PM_TYPES.PM_EXTERNAL}
                      />
                    ),
                  });
                }}
              />
              {pmExternal.isReopened && (
                <Tooltip title={AlectifyText.REOPENED}>
                  <span>
                    <ReopenedIcon />
                  </span>
                </Tooltip>
              )}
            </Space>
            {(!isEmpty(pmExternal.procedure) && (
              <Tooltip
                title={`${AlectifyText.PROCEDURE_CHECKLIST} ${countCheckedSteps(
                  pmExternal.procedure as any,
                )}`}
              >
                <span
                  className="cursor-pointer"
                  onClick={openProcedureCheckListDrawer}
                >
                  {truncate(pmExternal.workId)}{" "}
                  {!isEmpty(pmExternal.procedure) &&
                    getTaskStatusIcon(pmExternal.procedure.procedureSteps)}
                </span>
              </Tooltip>
            )) || <span>{truncate(pmExternal.workId)} </span>}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.PARENT_OR_SUB_ASSET,
      key: "asset_type",
      dataIndex: "asset_type",
      width: 120,
      visible: true,
      //@Todo: search will enabled when apis are ready.
      /*  filterMultiple: false,
      filters: Object.values(EquipmentTypeEnum).map((value) => ({
        text: snakeCaseToTitle(value),
        value,
      })), */
      render: (_, record: IPmExternal) => {
        const areaName: string = record.area?.name;
        const subProject: ISubProject = record.subProject;
        const isGeneric = record?.isGeneric;
        return (
          <Space direction="vertical" size={15}>
            <TextToLink
              className="text-to-link-options"
              text={
                truncate(record.asset?.name, {
                  length: 20,
                  omission: "...",
                }) ||
                truncate(areaName, {
                  length: 20,
                  omission: "...",
                }) ||
                "-"
              }
              underline={!isGeneric}
              onClick={() => {
                if (!isGeneric) {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: record.asset?.name ? 420 : 900,
                    title: record.asset?.name || areaName || "",
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    closable: true,
                    closeIcon: <CloseOutlined />,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      }),
                    children: !isEmpty(record.asset) ? (
                      <AssetDetail
                        pmExternalRecord={record}
                        subProjectId={subProject.id}
                        assetId={record.asset?.id}
                        navigate={navigate}
                        isDetailButton
                      />
                    ) : (
                      <PackageDetailAssets
                        pmExternalRecord={record}
                        subProjectId={subProject?.id || ""}
                        assetPackageId={record.area?.id || ""}
                        navigate={navigate}
                        isDetailsbutton
                      />
                    ),
                  });
                }
              }}
            />
            {record.asset?.name ? (
              <AssetTag />
            ) : record.area?.name ? (
              <AssetPackageTag />
            ) : (
              <GenericTag />
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.FREQUENCY,
      dataIndex: "isRecurring",
      key: "isRecurring",
      width: 90,
      visible: true,
      render: (isRecurring, record) => (
        <div className="pminternalcolumn-cell-tag">
          <Space direction="vertical">
            <span>
              {record?.frequency && isRecurring
                ? record.frequencyType === FrequencyTypeEnum.MONTHLY
                  ? `${record?.frequency} Month(s)`
                  : "-"
                : (record.frequencyType &&
                    capitalizeFirstLetter(record.frequencyType)) ||
                  "-"}
            </span>
            {isRecurring ? <RecurringTag /> : <NonRecurringTag />}
          </Space>
        </div>
      ),
    },
    {
      title: AlectifyText.STATUS,
      dataIndex: "dueDate",
      sorter: true,
      key: "dueDate",
      width: 90,
      visible: true,
      render: (value: Date, pmExternal: IPmExternal) => {
        return (
          <Space direction="vertical" size={15}>
            {`Due: ${displayDateTime(value)}`}
            {PM_STATUS_COMPONENTS[pmExternal.status]}
            {/* {`Completed at: ${displayDateTime(pmExternal.completedAt)}`} */}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.COMPLETION_DATE,
      dataIndex: "completedAt",
      key: "completedAt",
      width: 100,
      visible: props.filter.includes(PM_STATUS.COMPLETED),
      render: (value: Date, pmExternal: IPmExternal) => {
        return (
          <Space direction="vertical" size={15}>
            {`On: ${displayDateTime(value)}`}
            {isDelayed(value, pmExternal.dueDate) ? (
              <DelayedTag />
            ) : (
              <OnTimeTag />
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.ACTION_REQUIRED,
      dataIndex: "assignees",
      key: "assignees",
      width: 90,
      filters: projectTeamMembers?.data.map((user) => ({
        text: getFullUserName(user),
        value: user.id,
      })),
      visible:
        props.filter.includes(PM_STATUS.PENDING) ||
        props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW),
      render: (assignees: IPmAssignees[], record: IPmExternal) => {
        const actionRquired = getPMEActionRequired(record);
        const ACTION_REQUIRED = ACTION_REQUIRED_COMPONENTS(actionRquired);
        if (assignees?.length || !isEmpty(record.createdBy)) {
          return (
            <Space direction="vertical" size={5}>
              <AvatarGroup
                maxCount={2}
                users={
                  (actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                    ? assignees.map(({ user }) => user)
                    : [record.createdBy]) as any
                }
              />
              {ACTION_REQUIRED}
            </Space>
          );
        }
        return <></>;
      },
    },
    {
      width: 50,
      dataIndex: "name",
      title: AlectifyText.MESSAGES,
      visible: true,
      searchable: true,
      render: (_, record) => {
        const subProject = record.subProject || null;

        const openAttachmentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            title: AlectifyText.ATTACHMENTS,
            name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
              });
              props.fetchPmExternal(props.optionsPersisted as any);
            },
            children: <PreventiveDocumentsAttachment details={record} />,
          });
        };

        const openCommentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 420,
            title: AlectifyText.MESSAGING_CENTER,
            name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
              });
              props.fetchPmExternal(props.optionsPersisted as any);
            },
            children: (
              <Comments
                details={record && record}
                referenceId={record.id}
                subProjectId={subProject?.id}
                reference_type={CommentReferenceEnum.PM_EXTERNAL}
              />
            ),
            className: "alectify-drawer-task-comments",
          });
        };

        return (
          <Space
            direction="vertical"
            className="alectify-task-table-collaboration-column"
          >
            <Tooltip placement="topLeft" title={AlectifyText.MESSAGING_CENTER}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <MessageOutlined
                    className="icon-font-size-18"
                    onClick={openCommentsDrawer}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openCommentsDrawer}
                  >
                    {record?.comments}
                  </span>
                </Space>
              </Badge>
            </Tooltip>

            <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <PaperClipOutlined
                    className="icon-font-size-18"
                    onClick={openAttachmentsDrawer}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openAttachmentsDrawer}
                  >
                    {record?.documents}
                  </span>
                </Space>
              </Badge>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      width: 50,
      title: AlectifyText.ACTIONS,
      align: "center",
      dataIndex: "",
      visible:
        (props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW) &&
          props.filter.includes(PM_STATUS.PENDING)) ||
        !isExternalUser(currentUser),
      render: (_, pmExternal: IPmExternal) => {
        const openAttachmentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            title: pmExternal?.asset?.name || pmExternal?.area?.name,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => DrawerServiceInstance.close(AlectifyDrawer),
            children: <PreventiveDocumentsAttachment details={pmExternal} />,
          });
        };
        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 680,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              props.fetchPmExternal(props.optionsPersisted as any);
            },
            showFooter: true,
            readOnly: true,
            destroyOnClose: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={pmExternal.procedure as any}
                taskId={pmExternal.id}
                isDisabled={false}
              />
            ),
          });
        };

        const handleUndoConfirm = async () => {
          try {
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.UNDO_WARNING_MODAL,
            });
            const resp = await undoTaskStatus(pmExternal.id);
            if (resp?.status) {
              message.success(
                `${pmExternal?.workTitle} has changed the state from ${pmExternal?.status} to ${resp.data?.status}`,
              );
              props.fetchPmExternal(props.optionsPersisted as any);
            }
          } catch (error) {
            message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
          }
        };
        const openWarningmodal = () => {
          ModalServiceInstance.open(AlectifyModal, {
            name: MODAL_NAMES.UNDO_WARNING_MODAL,
            title: AlectifyText.CONFIRMATION,
            footer: null,
            onCancel: () => {
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.UNDO_WARNING_MODAL,
              });
            },
            children: (
              <ConfirmationModal
                icon={WarningIcon}
                message={`You are about to UNDO the State from ${pmExternal?.status} to ${
                  pmExternal?.completedAt
                    ? AlectifyText.WAITING_FOR_REVIEW
                    : AlectifyText.PENDING
                }`}
                note={`Are you sure?`}
                onConfirm={handleUndoConfirm}
                onCancel={() =>
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  })
                }
              />
            ),
          });
        };

        const dropdownItems = actionItems({
          currentUser,
          showMyWorkOrders,
          pmExternal: pmExternal,
          projectId: props.masterProjectId || "",
          subProjectId: props.subProjectId || "",
          navigate,
          myItemsColumns: props.myItemsColumns,
          onEdit: props.onEdit,
          updateStatus: props.updateStatus,
          openSubmitForReviewModal: props.openSubmitForReviewModal,
          onUpload: openAttachmentsDrawer,
          openProcedureCheckListDrawer: openProcedureCheckListDrawer,
          removePMExternal: props.removePMExternal,
        });

        const renderDropdown: any =
          dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

        return (
          <>
            {[PM_STATUS.PENDING, PM_STATUS.WAITING_FOR_REVIEW].includes(
              pmExternal?.status,
            ) ? (
              <Dropdown
                menu={{
                  items: renderDropdown,
                }}
                trigger={["click"]}
              >
                <EllipsisOutlined
                  rotate={90}
                  className="alectify-action-icon"
                />
              </Dropdown>
            ) : (
              <AlectifyButton
                text={AlectifyText.REOPEN}
                type="primary"
                onClick={openWarningmodal}
              />
            )}
          </>
        );
      },
    },
  ];
  return !props.myItemsColumns
    ? columns.filter((cols) => cols.visible)
    : [...myItemsColumns, ...columns.filter((cols) => cols.visible)];
};

export default usePmExternalColumnHook;
