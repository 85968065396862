import { Form, Spin, message } from "antd";
import DrStepNavigation from "./DrStepNavigation";
import {
  IDrProjectCreationProps,
  IDrProjectCreationState,
} from "./DrProjectCreation.interface";
import { useRef, useState } from "react";
import CreateEditSubProjectFooter from "components/shared/create-edit-sub-project/CreateEditSubProjectFooter";
import DrProjectBasicInfo from "./DrProjectBasicInfo";
import {
  ICreateDRProjectPayload,
  IShiftSchedule,
} from "services/data-round/data-round.interface";
import {
  createDrProject,
  updateDrProject,
} from "services/data-round/data-round.service";
import ManageGroups from "components/shared/manage-groups/ManageGroups";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyDrawer from "components/drawer";
import { isEmpty } from "lodash";

const DrProjectCreation: React.FC<IDrProjectCreationProps> = (
  props: IDrProjectCreationProps,
) => {
  const [state, setState] = useState<IDrProjectCreationState>({
    projectId: "",
    selectedValues: [1],
    currentActiveTab: 1,
    loading: false,
    project: null,
  });
  const shiftScheduleRef = useRef<IShiftSchedule[] | undefined>();
  const startTimeRef = useRef<string | undefined>("");

  const onProjectCreation = async () => {
    setState((prev: IDrProjectCreationState) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      await props.FormInstance.validateFields();
      const values = props.FormInstance.getFieldsValue();
      const payload: ICreateDRProjectPayload = {
        name: values.name,
        shift_schedules: shiftScheduleRef.current || [],
        master_project: props.masterProjectId,
        shifts_per_day: Number(values.shifts_per_day),
        start_time_first_shift: startTimeRef.current,
      };
      if (props.isEdit || !isEmpty(state.project)) {
        const response = await updateDrProject(
          props.projectId || state.project?.id || "",
          payload,
        );
        setState((prev: IDrProjectCreationState) => {
          return {
            ...prev,
            loading: false,
          };
        });
        if (response.success) {
          setState((prevState) => ({
            ...prevState,
            currentActiveTab: prevState.currentActiveTab + 1,
          }));
        }
      } else {
        const response = await createDrProject(payload);
        setState((prev: IDrProjectCreationState) => {
          return {
            ...prev,
            loading: false,
          };
        });
        if (response.success) {
          setState((prevState) => ({
            ...prevState,
            currentActiveTab: prevState.currentActiveTab + 1,
            projectId: response.data.id,
            project: response.data,
          }));
        }
      }
    } catch (ex) {
      setState((prev: IDrProjectCreationState) => {
        return {
          ...prev,
          loading: false,
        };
      });
      console.log(ex);
    }
  };

  const onClose = () => {
    props.FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_NEW_DR_PROJECT,
    });
    props.getDataRoundProject();
  };
  const onSubmit = async () => {
    /* if (props.isEdit) {
      if (state.project?.is_draft) {
        onClose();
        return;
      }
    } */
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await updateDrProject(
        props.projectId || state.project?.id || "",
        {
          ...(state.project as any),
          is_draft: false,
        },
      );
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      if (response.success) {
        onClose();
        message.success(
          `Project has been ${
            props.isEdit ? "Updated" : "Created"
          } successfully`,
        );
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  // Function to navigate to the next step
  const goNext = () => {
    try {
      if (state.currentActiveTab === 1) {
        onProjectCreation();
        return;
      }
      setState((prevState) => ({
        ...prevState,
        currentActiveTab: prevState.currentActiveTab + 1,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  // Function to navigate to the previous step
  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      currentActiveTab: prevState.currentActiveTab - 1,
    }));
  };
  const stages = [
    <DrProjectBasicInfo
      FormInstance={props.FormInstance}
      shiftScheduleRef={shiftScheduleRef}
      startTimeRef={startTimeRef}
      projectId={props.projectId || ""}
      isEditing={props.isEdit || false}
      project={state.project}
    />,
    <ManageGroups projectId={state.projectId || props.projectId || ""} />,
  ];
  return (
    <div className="create-edit-sub-project-container">
      <DrStepNavigation currentActiveTab={state.currentActiveTab} />
      <Spin spinning={state.loading}>
        {/* <Row className="create-sub-project-body" gutter={16}> */}
        {/* <Col span={5}>
              <CreateSubProjectSteps
                currentActiveTab={state.currentActiveTab}
                selectedValues={state.selectedValues}
                handleStepActivation={handleStepActivation}
              />
            </Col> */}

        <div className="stages-container mt-20">
          {stages[state.currentActiveTab - 1]}
        </div>
        <div className="sub-footer-container">
          <Form.Item>
            <CreateEditSubProjectFooter
              isEdit={false}
              currentActiveTab={state.currentActiveTab}
              onCancel={state.currentActiveTab === 1 ? onClose : goBack}
              onSubmit={state.currentActiveTab === 2 ? onSubmit : goNext}
            />
          </Form.Item>
        </div>
      </Spin>
    </div>
  );
};

DrProjectCreation.defaultProps = {
  isEdit: false,
  projectId: "",
  masterProjectId: "",
};
export default DrProjectCreation;
