import { isEmpty } from "lodash";
import { IReduxActionResponse } from "redux/interfaces";
import { INotificationsInitialState } from "../notifications.interface";
import { INotificationsResponse } from "services/notifications/notifications.interface";
import { notificationInitialState } from "..";

export const setNotifications = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<INotificationsResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: false,
    data: payload?.data,
    meta: payload?.meta,
    message: payload?.message,
  };
};

export const appendNotifications = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<INotificationsResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: false,
    data: [...state.data, ...(payload?.data ?? [])],
    meta: payload?.meta,
    message: payload?.message,
  };
};

export const setNotificationsLoader = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<boolean>,
) => {
  return {
    ...state,
    fetching: action.payload,
  };
};

export const setUnreadNotificationsCount = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<number>,
): INotificationsInitialState => {
  return {
    ...state,
    unreadCounts: action.payload ?? 0,
  };
};

export const markNotificationsAsRead = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<string[]>,
): INotificationsInitialState => {
  let notifications = state.data;
  if (!isEmpty(action.payload)) {
    notifications = state.data.map((notification) => {
      if (action.payload?.includes(notification.id)) {
        return { ...notification, isRead: true };
      }
      return notification;
    });
  } else {
    notifications = notifications.map((notification) => ({
      ...notification,
      isRead: true,
    }));
  }
  return {
    ...state,
    data: notifications,
  };
};

export const toggleNotificationDropdown = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<boolean>,
): INotificationsInitialState => {
  return {
    ...state,
    isDropdownOpen: action.payload || false,
  };
};

export const resetNotificatios = (
  state: INotificationsInitialState,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...notificationInitialState,
  };
};
