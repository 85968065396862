import { Space, Tag } from "antd";
import AssetsIcon from "components/icons/AssetsIcon";
import AlectifyText from "static/texts.json";
import { CHIP_COLORS } from "../phone-number/constants";

const AssetTag: React.FC<{ count?: number }> = ({ count }) => {
  return (
    <Tag color={CHIP_COLORS.ASSET} className="asset-tag round-tag">
      <Space size={2}>
        <AssetsIcon />
        {`${AlectifyText.SUB_ASSET_NAME}${count ? `: ${count}` : ""}`}
      </Space>
    </Tag>
  );
};

export default AssetTag;
