//libs
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import AlectifyTable from "components/shared/table";

//internal
import { ITasksTableProps } from "./TasksTable.interface";

import { fetchTasks } from "redux/components/tasks/sources";

import { PM_STATUS } from "enums";
import { PAGINATION } from "constants/index";
import { getSubProjectDetails } from "redux/components/sub-project/sources";
// import "./TasksTable.scss";
import { IRootState } from "redux/rootReducer";
import useTasksColumn from "./effects/useTasksColumns";
import { ITask, actions } from "redux/components/tasks";
import { actions as pmExternalActions } from "redux/components/pm-external";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyText from "static/texts.json";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { useForm } from "antd/es/form/Form";
import PMCreateEditForm from "../pm-create-edit-form";
import { isEmpty } from "lodash";
import AlectifyButton from "../button";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import AlectifyModal from "../modal";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import PmExternalTaskCompleteForm from "../pm-external-table/PmExternalTaskCompleteForm";
import { setUploadDocuments } from "redux/components/common/sources";
import {
  deletePMExternal,
  updatePrevMaintenanceStatus,
} from "services/pm-external/pm-external.service";
import { getPmsCounts } from "redux/components/pm-internal/sources";
import ConfirmationModal from "../confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { MESSAGES } from "constants/messages";
import { notification } from "antd";
import { message } from "antd/lib";
import { PM_WARNING_MESSAGES } from "constants/warning-messages.constant";
import { isFeatureEnabled, updateComponentByNotfication } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import {
  getPmExternal,
  setWorkOrderCardSwitcher,
} from "redux/components/pm-external/sources";
import ReviewConfirmationModal from "../confirmation/ReviewConfirm";
import { cancelPMApiRequests } from "utils/client";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { useLocation } from "react-router-dom";
import WorkOrderSpareParts from "../work-order-spare-parts";

const TasksTable: React.FC<ITasksTableProps> = (props: ITasksTableProps) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<string[]>([
    PM_STATUS.PENDING,
    PM_STATUS.WAITING_FOR_REVIEW,
  ]);
  const location = useLocation();

  const [optionsPersisted, setPersistedOptions] = useState<IPagination | null>(
    null,
  );
  const { common, tasks, pmExternal, auth } = useSelector(
    (state: IRootState) => state,
  );

  const activeMasterProject = common.activeMasterProject?.id;
  const [FormInstance] = useForm();
  const [submitReviewForm] = useForm();

  const getTasks = (options?: IPagination, requestId?: string | null) => {
    if (
      options?.status === PM_STATUS.COMPLETED ||
      options?.status?.toString() === PM_STATUS.COMPLETED
    ) {
      options.status = [PM_STATUS.COMPLETED] as any;
      setFilter(options?.status as any);
    } else if (
      options?.status === PM_STATUS.SKIPPED ||
      options?.status?.toString() === PM_STATUS.SKIPPED
    ) {
      options.status = [PM_STATUS.SKIPPED] as any;
      setFilter(options?.status as any);
    } else {
      if (options) {
        options.status = [
          PM_STATUS.PENDING,
          PM_STATUS.WAITING_FOR_REVIEW,
          PM_STATUS.INPROGRESS,
        ] as any;
        setFilter(options?.status as any);
      }
    }

    const assigneesArray =
      typeof options?.assignees === "string"
        ? options?.assignees.split(",")
        : [];
    delete options?.assignees;
    let approvers: any[] = [];
    if (common.showMyWorkOrders) {
      approvers = [auth.user.id];
    }

    let orderField, orderBy;
    if (typeof options?.orderBy === "string") {
      const [field, direction] = options.orderBy.split(" ");
      orderField = field;
      orderBy = direction;
    }
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      status: filter,
      assignees: assigneesArray,
      approvers,
      ...options,
      ...(props.defaultParams ?? {}),
      orderField,
      orderBy,
    };

    if (props.type === PM_TYPES.TASK || location.search.includes("tasks")) {
      cancelPMApiRequests(
        `${SERVICE_UNIQUE_ID.UNIQUE_FETCH_TASKS}${requestId && requestId}`,
      );
      dispatch(
        fetchTasks(
          activeMasterProject || props.masterProjectId,
          props.subProjectId,
          params,
          requestId,
        ),
      );
    } else if (
      props.type === PM_TYPES.PM_EXTERNAL ||
      location.search.includes("pm-external")
    ) {
      cancelPMApiRequests(SERVICE_UNIQUE_ID.UNIQUE_FETCH_PM_EXTERNAL);
      dispatch(
        getPmExternal(
          common.activeMasterProject?.id || props.masterProjectId,
          props.subProjectId,
          params,
        ),
      );
    }
    if (props.triggerCountsApi) {
      dispatch(
        getPmsCounts(
          activeMasterProject || props.masterProjectId,
          props.subProjectId,
          props.defaultParams ?? {},
        ),
      );
    }
    if (props?.masterProjectId !== "all") {
      dispatch(
        getSubProjectDetails(props?.masterProjectId, props?.subProjectId),
      );
    }
    if (!isEmpty(options)) setPersistedOptions(options);
  };

  const openCreationDrawer = (
    _: any,
    isEditing: boolean = false,
    editType?: string,
    task?: ITask,
    isCopying: boolean = false,
  ) => {
    const config = {
      width: isFeatureEnabled(FEATURE_FLAGS.IMPORT_PROCEDURE) ? 1436 : 630, //@todo for procedure it would be 1436px
      title: isCopying
        ? `Copying ${task?.workTitle}`
        : isEditing
        ? `Update ${task?.workTitle}`
        : props.type === PM_TYPES.PM_EXTERNAL
        ? AlectifyText.CREATE_NEW_MAINTENANCE
        : AlectifyText.CREATE_NEW_TASK,
      showFooter: true,
      name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
      closable: true,
      closeIcon: <CloseOutlined />,
      onClose: () => {
        FormInstance.resetFields();
        getTasks(optionsPersisted as any, "unique_key"); //@TODO:  need to change the requestId logic in future.
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
        });
      },
      onSubmit: FormInstance.submit,
      destroyOnClose: true,
      children: (
        <PMCreateEditForm
          subProjectId={
            props.subProjectId === "all"
              ? task?.subProject.id
              : props.subProjectId || ""
          }
          masterProjectId={
            props.masterProjectId === "all"
              ? task?.project.id
              : props.masterProjectId || task?.project?.id
          }
          pmId={task?.id || ""}
          FormInstance={FormInstance}
          editing={isEditing}
          copying={isCopying}
          editType={editType || ""}
          onEdit={openCreationDrawer as any}
          callbackEffects={() =>
            getTasks(optionsPersisted as any, "unique_key")
          } //@TODO:  need to change the requestId logic in future.
          isTask={props.type === PM_TYPES.TASK}
          isPmExternal={props.type === PM_TYPES.PM_EXTERNAL}
          isMyWorkOrder={props.subProjectId === "all"}
          myWorkOrder={props.myWorkdOrder}
        />
      ),
    };
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: isFeatureEnabled(FEATURE_FLAGS.IMPORT_PROCEDURE) ? 1436 : 630, //@todo for procedure it would be 1436px
      title: isCopying
        ? `Copying ${task?.workTitle}`
        : isEditing
        ? `Update ${task?.workTitle}`
        : props.type === PM_TYPES.PM_EXTERNAL
        ? AlectifyText.CREATE_NEW_MAINTENANCE
        : AlectifyText.CREATE_NEW_TASK,
      showFooter: true,
      name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
      closable: true,
      closeIcon: <CloseOutlined />,
      onClose: () => {
        FormInstance.resetFields();
        getTasks(optionsPersisted as any, "unique_key"); //@TODO:  need to change the requestId logic in future.
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
        });
      },
      onSubmit: FormInstance.submit,
      destroyOnClose: true,
      children: (
        <PMCreateEditForm
          config={config}
          subProjectId={
            props.subProjectId === "all"
              ? task?.subProject.id
              : props.subProjectId || ""
          }
          masterProjectId={
            props.masterProjectId === "all"
              ? task?.project.id
              : props.masterProjectId || task?.project?.id
          }
          pmId={task?.id || ""}
          FormInstance={FormInstance}
          editing={isEditing}
          copying={isCopying}
          editType={editType || ""}
          onEdit={openCreationDrawer as any}
          callbackEffects={() =>
            getTasks(optionsPersisted as any, "unique_key")
          } //@TODO:  need to change the requestId logic in future.
          isTask={props.type === PM_TYPES.TASK}
          isPmExternal={props.type === PM_TYPES.PM_EXTERNAL}
          isMyWorkOrder={props.subProjectId === "all"}
          myWorkOrder={props.myWorkdOrder}
        />
      ),
    });
  };

  const updateStatus = async (
    pmExternal: IPmExternal | ITask,
    status: string,
    date?: Date,
    estimatedHours?: string,
    estimatedCost?: string,
    commentId?: string,
  ) => {
    if (props.type === PM_TYPES.TASK) {
      dispatch(actions.showTasksLoader(true));
    } else {
      dispatch(pmExternalActions.setPMExterLoader(true));
    }
    try {
      const response = await updatePrevMaintenanceStatus(
        pmExternal?.id,
        status,
        {
          date: date ? date : new Date(),
          estimatedHours,
          estimatedCost,
          commentId: commentId || undefined,
        },
      );
      if (response?.status) {
        getTasks(optionsPersisted as any);
        if (status === PM_STATUS.SKIPPED) {
          openSuccessMessageModal(AlectifyText.ORDER_SKIPPED);
        }
        if (status === PM_STATUS.COMPLETED) {
          openSuccessMessageModal(AlectifyText.ORDER_CLOSED);
        }
        if (props.type === PM_TYPES.TASK) {
          dispatch(actions.showTasksLoader(false));
        } else {
          dispatch(pmExternalActions.setPMExterLoader(false));
        }
      }
    } catch (ex) {
      if (props.type === PM_TYPES.TASK) {
        dispatch(actions.showTasksLoader(false));
      } else {
        dispatch(pmExternalActions.setPMExterLoader(false));
      }
      console.log(ex);
    }
  };

  const onUpdateStatus = (
    pmExternal: ITask | IPmExternal,
    status: string,
    date?: Date,
    estimatedHours?: string,
    estimatedCost?: string,
    commentId?: string,
  ) => {
    const message =
      PM_WARNING_MESSAGES[status] || MESSAGES.PM_EXTERNAL.COMMON_WARNING;

    return new Promise((resolve, reject) => {
      const update = async () => {
        if (props.type === PM_TYPES.TASK) {
          dispatch(actions.showTasksLoader(true));
        } else {
          dispatch(pmExternalActions.setPMExterLoader(true));
        }
        try {
          await updateStatus(
            pmExternal,
            status,
            date,
            estimatedHours,
            estimatedCost,
            commentId,
          );
          if (props.type === PM_TYPES.TASK) {
            dispatch(actions.showTasksLoader(false));
          } else {
            dispatch(pmExternalActions.setPMExterLoader(false));
          }
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
          });
          submitReviewForm.resetFields();
          dispatch(setUploadDocuments([]));
          resolve(true);
        } catch (error) {
          if (props.type === PM_TYPES.TASK) {
            dispatch(actions.showTasksLoader(false));
          } else {
            dispatch(pmExternalActions.setPMExterLoader(false));
          }
          reject(error);
        }
      };

      if (status === "WAITING FOR REVIEW") {
        update();
      } else {
        openWarningModal(
          message,
          update,
          pmExternal,
          () => resolve(false),
          status,
          status === "SKIPPED" ? MESSAGES.PM_EXTERNAL.SKIP_NOTE : "",
        );
      }
    });
  };

  const openSubmitForReviewModal = (task: ITask) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
      title: "Submit for Review",
      width: 700,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
        });
        submitReviewForm.resetFields();
        dispatch(setUploadDocuments([]));
      },
      onOk: submitReviewForm.submit,
      okText: "Submit",
      closeIcon: false,
      children: (
        <PmExternalTaskCompleteForm
          form={submitReviewForm}
          subProjectId={
            props.subProjectId === "all"
              ? task.subProject.id
              : props.subProjectId || ""
          }
          pmExternal={task}
          masterProjectId={
            props.masterProjectId === "all"
              ? task.project.id
              : props.masterProjectId || ""
          }
          fetchPmExternal={fetchTasks}
          updateStatus={onUpdateStatus}
          procedureSteps={task.procedure?.procedureSteps}
        />
      ),
    });
  };

  const openSuccessMessageModal = (text: string) => {
    message.success(text);
    // ModalServiceInstance.open(AlectifyModal, {
    //   name: MODAL_NAMES.SUCCESSFUL_MESSAGE_MODAL,
    //   onCancel: () => {
    //     ModalServiceInstance.close(AlectifyModal, {
    //       name: MODAL_NAMES.SUCCESSFUL_MESSAGE_MODAL,
    //     });
    //   },
    //   footer: false,
    //   closeIcon: false,
    //   children: <SuccessfulMessageModal text={text} />,
    // });
  };

  const removeTask = async (pmId: string) => {
    if (props.type === PM_TYPES.TASK) {
      dispatch(actions.showTasksLoader(true));
    } else {
      dispatch(pmExternalActions.setPMExterLoader(true));
    }
    try {
      await deletePMExternal(pmId);
      message.success("Deleted");
      getTasks(optionsPersisted as any);
      if (props.type === PM_TYPES.TASK) {
        dispatch(actions.showTasksLoader(false));
      } else {
        dispatch(pmExternalActions.setPMExterLoader(false));
      }
    } catch (ex: any) {
      if (props.type === PM_TYPES.TASK) {
        dispatch(actions.showTasksLoader(false));
      } else {
        dispatch(pmExternalActions.setPMExterLoader(false));
      }
      notification.error({
        message:
          ex?.response?.data?.message ||
          MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG,
      });
    }
  };

  // ============== Warning modal =============>>>

  const openWarningModal = (
    message: string,
    successCb: () => void,
    pmExternal?: ITask | IPmExternal,
    cancelCb?: () => void,
    status?: string,
    note?: string,
  ) => {
    if (status === "COMPLETED") {
      const onCancel = () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.REVIEW_COMPLETED,
        });
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.REVIEW_COMPLETED,
        title: AlectifyText.REVIEW_COMPLETE,
        width: 700,
        okText: AlectifyText.REVIEW_COMPLETE,
        onOk: () => {
          successCb();
          onCancel();
        },
        onCancel,
        children: (
          <ReviewConfirmationModal
            pmExternal={pmExternal}
            icon={WarningIcon}
            message={message}
            onConfirm={() => {
              successCb && successCb();
              onCancel();
            }}
            onCancel={() => {
              cancelCb && cancelCb();
              onCancel();
            }}
          />
        ),
      });
    } else {
      const onCancel = () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
        });
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
        title: AlectifyText.CONFIRMATION,
        footer: null,
        onCancel,
        children: (
          <ConfirmationModal
            pmExternal={pmExternal}
            icon={WarningIcon}
            message={message}
            cancelText={"Cancel"}
            okText={status === "SKIPPED" ? "Skip" : "Delete"}
            note={note}
            onConfirm={() => {
              successCb && successCb();
              onCancel();
            }}
            onCancel={() => {
              cancelCb && cancelCb();
              onCancel();
            }}
          />
        ),
      });
    }
  };

  const openRemoveWaringModal = (pmId: string) => {
    openWarningModal(MESSAGES.PM_EXTERNAL.REMOVER_WARNING, () =>
      removeTask(pmId),
    );
  };

  const openSpareParts = (record: ITask | IPmExternal) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1000,
      name: MODAL_NAMES.WORK_ODERS_SPARE_PARTS_MODAL,
      title: AlectifyText.SPARE_PARTS_USED,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: MODAL_NAMES.WORK_ODERS_SPARE_PARTS_MODAL,
        });
      },
      closeIcon: true,
      footer: null,
      children: (
        <WorkOrderSpareParts
          record={record}
          showDrawSparePartsButton={
            record.status !== PM_STATUS.COMPLETED &&
            record.status !== PM_STATUS.SKIPPED
          }
        />
      ),
    });
  };

  const columns = useTasksColumn({
    ...props,
    fetchTasks: getTasks,
    filter,
    optionsPersisted,
    onEdit: openCreationDrawer,
    updateStatus: onUpdateStatus,
    openSubmitForReviewModal,
    removeTask: openRemoveWaringModal,
    openSpareParts: openSpareParts,
  });

  useEffect(() => {
    getTasks({
      ...optionsPersisted,
      // masterProjectId: activeMasterProject,
    } as any);
  }, [
    common.showMyWorkOrders,
    common.activeMasterProject?.id,
    common?.activeSubProject?.id,
    props.type,
  ]);

  updateComponentByNotfication(props.type as string, () => {
    getTasks({
      ...optionsPersisted,
      // masterProjectId: activeMasterProject,
    } as any);
  });

  return (
    <AlectifyTable
      showSearch
      noIcontainsSearch
      columns={columns}
      scroll={props.scroll}
      dataSource={props.type === PM_TYPES.TASK ? tasks.data : pmExternal.data}
      loading={
        props.type === PM_TYPES.TASK ? tasks.fetching : pmExternal.fetching
      }
      total={
        props.type === PM_TYPES.TASK
          ? tasks.meta.totalItems
          : pmExternal.meta.totalItems
      }
      className={
        pmExternal.activeWorkOrderCardSwitcher
          ? ""
          : "alectify-table-card-columns"
      }
      onDataFetch={getTasks}
      columnFilterAsString
      customSearchKey="search"
      filters={{
        key: "status",
        options: [
          {
            label: `Open (${
              props.type === PM_TYPES.TASK
                ? tasks.pendingCounts
                : pmExternal.pendingCounts || 0
            })`,
            value: PM_STATUS.PENDING,
          },
          {
            label: `${AlectifyText.CLOSED} (${
              props.type === PM_TYPES.TASK
                ? tasks.completedCounts
                : pmExternal.completedCounts || 0
            })`,
            value: PM_STATUS.COMPLETED,
          },
          {
            label: `Skipped (${
              props.type === PM_TYPES.TASK
                ? tasks.skippedCounts
                : pmExternal.skippedCounts || 0
            })`,
            value: PM_STATUS.SKIPPED,
          },
        ],
      }}
      extraButton={
        props.allowCreation ? (
          <AlectifyButton
            text={
              props.type === PM_TYPES.TASK
                ? AlectifyText.CREATE_TASK
                : AlectifyText.CREATE_MAINTENANCE
            }
            type="primary"
            icon={<PlusOutlined />}
            onClick={openCreationDrawer}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

TasksTable.defaultProps = {
  allowCreation: true,
  defaultParams: {},
  triggerCountsApi: true,
  scroll: { x: 1320, y: "calc(100vh - 350px)" },
};

export default memo(TasksTable);
